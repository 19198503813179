import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  // Products
  async getProductById(productId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getProductById')({ productId })).data;
  },
  async getAllProducts() {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getAllProductsWithCategories')({ })).data;
  },

  // Product Reviews
  async upsertProductReview(productId: any, rating: any, content: any, oldUserReview: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-upsertProductReview')({ productId, rating, content, oldUserReview, });
  },

  // User Liked Items
  async addUserLikedItem(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-addUserLikedProduct')({ productId });
  },
  async removeUserLikedItem(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-removeUserLikedProduct')({ productId });
  },

  // USER BROWSED PRODUCT HISTORY
  async addUserBrowsedProduct(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-addUserBrowsedProduct')({ productId });
  },
}