import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage, uniqueId, } from '@/services/utils';

export default {
  async getUserPets() {
    return (await firebase.app().functions('asia-east2').httpsCallable('pet-getUserPets')({ })).data;
    //return (await firebase.app().functions('asia-east2').httpsCallable('pet-getAllUserPets')({ })).data;
  },
  async updateUserPet(payload: any) {
    const { userPetId, updatedObj, petLostPosterDataURL, cardDataURL } = payload;
    if (petLostPosterDataURL) {
      updatedObj["lost_pet_poster_link"] = await uploadImage(petLostPosterDataURL, `${userPetId}_PetLostPoster`, 'user_pet', true);
    }
    if (cardDataURL) {
      updatedObj["card_photo_link"] = await uploadImage(cardDataURL, `${userPetId}_IDCard`, 'user_pet', true);
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('pet-updateUserPet')({ userPetId, updatedObj })).data[0];
  },
  async upsertUserPet(userPet: any, photoDataURL: any) {
    const { id, croppedPhoto, rembgPhoto, } = userPet;
    const newUserPetId = id ? "" : uniqueId();

    if (photoDataURL) {
      userPet.photo = photoDataURL.startsWith("http") ? photoDataURL : await uploadImage(photoDataURL, id || newUserPetId, 'user_pet', true);
    }
    /*
    if (croppedPhoto) {
      userPet.croppedPhoto = croppedPhoto.startsWith("http") ? croppedPhoto : await uploadImage(croppedPhoto, id || newUserPetId, 'user_pet_cropped', true);
    }
    if (rembgPhoto) {
      userPet.rembgPhoto = rembgPhoto.startsWith("http") ? rembgPhoto : await uploadImage(rembgPhoto, id || newUserPetId, 'user_pet_rembg', true);
    }
    */
    return (await firebase.app().functions('asia-east2').httpsCallable('pet-upsertUserPet')({ ...userPet, newUserPetId, })).data[0];
  },
  async deleteUserPet(userPetId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('pet-deleteUserPet')({ userPetId });
  }
}