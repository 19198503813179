// PRODUCTS
export const getProductById = (state) => (id) => {
  // mainly used in Product Details Page
 return state.products.find(product => product.id == id) || {};
}
export const getVisibleCoralProducts = (state) => {
  const prizeProductIds = [];
  /* Enable this if not want prize products visible in shop
  const coralProducts = state.products.filter(p => {
    prizeProductIds.push(...(p.prizes.map(pz => pz.prizeProductId)));
    return p.type == 'Coral';
  });
  */
  return state.products.filter(p => (!prizeProductIds.includes(p.id) && p.type == 'Coral'));
}
export const getValidGiftProducts = (state) => {
  return state.products.filter(p => (p.type == "Gift" && state.userDiscounts.find(d => d.productId == p.id)));
}
export const getProductsByType = (state) => (type) => {
  return state.products.filter(p => p.type == type);
}
export const getProductCategoriesByType = (state) => (type) => {
  return state.productCategories.filter(c => c.type == type);
}
export const getProductCategoryById = (state) => (id) => {
  return state.productCategories.find(c => c.id == id) || {};
}
export const userLikedItems = (state) => {
  return state.products.filter(p => p.likedByUser);
}
export const getRelatedProducts = (state) => (id) => {
  const product = state.products.find(product => product.id == id);
  if (product) {
    const { type, categoryId } = product;
    return state.products.filter(p => (p.id != id && p.type == type && p.categoryId == categoryId));
  }
  return [];
}
export const featuredProducts = (state) => {
  return state.products.filter(product => product.isFeatured == "Y");
}
export const latestProducts = (state) => {
  return state.products.slice(-10); // return latest 10 products (last 10 rows)
}
export const allProductTags = (state) => (category = 'all') => {
  const tmpObj = {};
  for (const product of state.products) {
    if (product.tags && (category == 'all' || product.categoryId == category)) {
      for (const tag of product.tags.split(" , ")) {
        tmpObj[tag] = (tmpObj[tag] || 0) + 1;
      }
    }
  }
  return Object.keys(tmpObj).map(tag => {
    return { name: tag, numOfProducts: tmpObj[tag] };
  });
}
export const userRecentBrowsedProducts = (state) => {
  const { browsedProductIds } = state.user;
  return state.products.filter(p => (browsedProductIds.includes(p.id)));
}