import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-text-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"color":"var(--ion-color-dark)","white-space":"pre-line"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_star_rating = _resolveComponent("star-rating")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('coralReview')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_product_card, {
          product: _ctx.product,
          hideDetails: "true"
        }, null, 8, ["product"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_star_rating, {
            rating: _ctx.product.overallRating,
            inline: true,
            "star-size": 36,
            "show-rating": false,
            "read-only": true
          }, null, 8, ["rating"]),
          _createElementVNode("h2", null, _toDisplayString(_ctx.t('productRating')) + "：" + _toDisplayString(_ctx.product.overallRating.toFixed(1)), 1)
        ]),
        (_ctx.product.reviewsPerScore)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['5', '4', '3', '2', '1'], (rating) => {
                return _createVNode(_component_ion_item, {
                  lines: "none",
                  key: rating
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_note, {
                      slot: "start",
                      style: {"padding":"0","margin-inline-end":"16px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(rating), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_progress_bar, {
                      color: "warning",
                      style: {"margin":"0"},
                      slot: "end",
                      value: (_ctx.product.reviewsPerScore[rating] || 0) / _ctx.product.reviews.length
                    }, null, 8, ["value"])
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.reviews, (review, index) => {
          return (_openBlock(), _createBlock(_component_ion_card, {
            key: review.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_header, { style: {"padding-bottom":"8px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_subtitle, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" #" + _toDisplayString(index+1) + " ", 1),
                      _createVNode(_component_ion_chip, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_avatar, null, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: review.userProfilePic || 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg'
                              }, null, 8, _hoisted_3)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(review.username), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ion_card_content, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createVNode(_component_star_rating, {
                      rating: review.rating,
                      inline: true,
                      "star-size": 14,
                      "show-rating": false,
                      "read-only": true
                    }, null, 8, ["rating"]),
                    _createElementVNode("span", null, " " + _toDisplayString(_ctx.getRelativeDate(review.createdAt)), 1)
                  ]),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(review.content), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}