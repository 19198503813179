import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage, uniqueId, } from '@/services/utils';

export default {
  // DIY product design
  async upsertProductDesign(payload: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-upsertProductDesign')(payload)).data[0];
  },

  // User Stickers
  async removeBgFromImg(imgDataURL: any) {
    const photoLink = await uploadImage(imgDataURL, uniqueId(), 'tmp_user_sticker_pet', true);
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-removeBgFromImg')({ photoLink })).data;
  },

  async convertImgToWebp(imgBase64: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-convertImgToWebp')({ imgBase64, includeTrayIcon: true })).data;
  },

  async getStickerTemplates() {
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-getStickerTemplates')({ })).data;
  },

  async getUserStickers() {
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-getUserStickers')({ })).data;
  },
  async getStickersByIds(stickerIds: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-getStickersByIds')({ stickerIds })).data;
  },
  
  async updateUserSticker(payload: any) {
    const { userStickerId, updatedObj, } = payload;
    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-updateUserSticker')({ userStickerId, updatedObj })).data[0];
  },
  async upsertUserSticker(userSticker: any) {
    const { id, petPhoto, croppedPetPhoto: cPetPhoto, } = userSticker;
    const newUserStickerId = id ? "" : (userSticker.newUserStickerId || uniqueId());
    const stickerId = id || newUserStickerId;

    if (petPhoto) userSticker.petPhoto = petPhoto.startsWith("http") ? petPhoto : await uploadImage(petPhoto, stickerId, 'user_sticker_pet_photo', true);
    if (cPetPhoto) userSticker.croppedPetPhoto = cPetPhoto.startsWith("http") ? cPetPhoto : await uploadImage(cPetPhoto, stickerId, 'user_sticker_cropped_pet_photo', true);

    return (await firebase.app().functions('asia-east2').httpsCallable('sticker-upsertUserSticker')({ ...userSticker, newUserStickerId, })).data[0];
  },
  async deleteUserSticker(userStickerId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('sticker-deleteUserSticker')({ userStickerId });
  },
}