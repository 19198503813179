import { storefrontOutline, home, calendar, notifications, personOutline, basketOutline, newspaperOutline, settingsOutline, chatbubbleOutline,
        cardOutline, logoGoogle, logoApple, logoPaypal, logoAlipay, logoWechat, businessOutline, business, colorWandOutline,
        receiptOutline, } from 'ionicons/icons';

export default {
  newStickerLocalStorageKey: 'newSticker1',
  
  versionCode: '3.5.0',
  websiteUrl: 'https://mlol.pet',
  
  primaryColor: '#FFC430',
  primaryColorCoral: '#00a3fe',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo.png'),
  imgIcons: {
    tools: require('@/assets/icon_tools.png'),
    toolsWhite: require('@/assets/icon_tools_white.png'),
    chat: require('@/assets/icon_chat.png'),
    article: require('@/assets/icon_article.png'),
    location: require('@/assets/icon_location.png'),
  },

  googleMapsApiKey: "AIzaSyBAfbeaXmrlCnW4dICmbieqlX-771Wz_G0",

  Firebase: {
    apiKey: "AIzaSyA9xf-ZRDqRPHz4bwpgBBwhea35yVDV13Y",
    authDomain: "mlolpet.firebaseapp.com",
    projectId: "mlolpet",
    storageBucket: "mlolpet.appspot.com",
    messagingSenderId: "1026367466693",
    appId: "1:1026367466693:web:9754c467a1fb58146214ac",
    measurementId: "G-DBNBL5C7R0"
  },

  OneSignal: {
    appId: 'a13f71aa-8e16-4417-a8dc-0d1de802f278',
  },
  
  Stripe: {
    //publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
    publishableKey: 'pk_live_51HVue8F5eqkgmghRn1B9EOEvN9FLUN1kshEHg7xRYqmP8OT2YaBraba2PVCqLqH6GY2EuaQH3CpcuKFM1uS8QdFu00W7JYQlu1',
  },

  cardInputStyles: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  },

  HomePage: {
    banner: {
      slidingSpeed: 300, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 1,
    }
  },

  paymentMethods: [
    //{ key: "creditCard", value: "card", icon: cardOutline },
    //{ key: "PayMe", value: "payme", icon: require('@/assets/logo_payme.png') },
    { key: "offlinePayment", value: "bank", icon: businessOutline },
    //{ key: "Google Pay", value: "googlePay", icon: logoGoogle },
    //{ key: "Apple Pay", value: "applePay", icon: logoApple },
    /*{ key: "PayPal", value: "paypal", icon: logoPaypal },
    { key: "AlipayHK", value: "alipayhk", icon: logoAlipay },
    { key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },
    { key: "Bank Transfer", value: "bank", icon: businessOutline },*/
  ],
  hideBottomTabsPage: ["/", "/toy", "/info-overview", "/verify-certificate", "/chatbot"],
  hideBottomTabsPageNames: [ 'StickerDetailPage', 'ProductDetailPage', 'CheckoutPage', 'ThankYouOrderPage', "CertDetailPage", "LuckyWheelPage"],
  publicPages: ["/compare", "/terms-and-conditions"],
  authPages: ["/login"],
  memberPages: ["/stickers", "/tools", "/browsed-infos", "/liked-infos", "/browsed-places", "/liked-places", "/liked-products", "/chatbot"],
}