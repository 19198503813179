import { createStore, createLogger } from 'vuex';

import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

import * as petGetters from './getters/pet';
import * as placeGetters from './getters/place';
import * as infoGetters from './getters/info';
import * as stickerGetters from './getters/sticker';
import * as productGetters from './getters/product';
import * as cartGetters from './getters/cart';

export default createStore({
  state,
  getters: { ...getters, ...petGetters, ...placeGetters, ...infoGetters, ...stickerGetters, ...productGetters, ...cartGetters },
  actions,
  mutations,
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})