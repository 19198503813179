<template>
  <form @submit.prevent="submitOrder(products)">
    <!--
      1. Your Order
    -->
    <ion-list>
      <ion-list-header color="secondary">
        {{ t('CheckoutPage.yourOrder') }}&nbsp;<ion-icon :icon="cartOutline"></ion-icon>
      </ion-list-header>

      <ion-list v-if="!checkingProducts">
        <ion-item v-for="product in cartItems" :key="product.id" :disabled="product.requiredQty == 0">
          <ion-thumbnail slot="start">
            <img style="object-fit: contain" :src="addResizeUrlParams(product.previewPhotoLink || product.photoLink, 300)">
          </ion-thumbnail>
          <ion-label>
            <h3>
              {{ product.requiredQty }} x {{ getLocalizedStr(product, 'title', 'titleEn') }}<span v-if="product.coralId"> ({{ product.coralId }})</span>
              <span v-if="product.size">&nbsp;{{ product.size }}</span>
            </h3>
            <p>{{ t('lineTotal') }}HK${{ product.sellingPrice * product.requiredQty }}</p>
          </ion-label>
        </ion-item>
      </ion-list>

      <!--
        折扣碼
      -->
      <ion-row v-if="!order.bidId && getCartTotal() > 0 && !existGiftProducts() && !existGameProducts()">
        <ion-col size-xs="12" size-md="8" size-lg="6">
          <ion-item lines="none" fill="outline" @click="openUseCouponModal()" :detail="!order.appliedDiscountCode" button>
            <!-- Icon -->
            <ion-icon slot="start" :icon="pricetagOutline"></ion-icon>

            <!-- Label -->
            <ion-label class="ion-text-wrap">
              <p style="color: #000" v-show="!order.appliedDiscountCode">{{ t('useCoupon') }}</p>
              <p style="color: #000" v-show="order.appliedDiscountCode">
                {{ order.appliedDiscountDescription || order.appliedDiscountCode }}
              </p>
            </ion-label>

            <!-- Clear Discount Button -->
            <ion-buttons slot="end" v-show="order.appliedDiscountCode">
              <ion-button size="small" fill="clear" @click.stop="resetDiscountCode()" style="margin-left: 8px">
                <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-col>
      </ion-row>

      <!--
        會員積分
        Confirm: loading -> show updated price
      -->
      <ion-row v-if="!order.bidId && getCartTotal() > 0 && !existGiftProducts()">
        <ion-col size-xs="12" size-md="8" size-lg="6">
          <ion-item lines="none" fill="outline" @click="order.isPointModalOpened = true" :detail="!order.appliedPoints" button>
            <!-- Icon -->
            <ion-icon slot="start" :icon="pricetagOutline"></ion-icon>

            <!-- Label -->
            <ion-label class="ion-text-wrap">
              <p style="color: #000">
                {{ t('usePoints') }}<span v-show="order.appliedPoints">: {{ order.appliedPoints }}</span>
              </p>
            </ion-label>

            <!-- Clear Point Button -->
            <ion-buttons slot="end" v-show="order.appliedPoints">
              <ion-button size="small" fill="clear" @click.stop="resetPointDiscount()" style="margin-left: 8px">
                <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-col>
      </ion-row>


      <!-- Subtotal & Total -->
      <ion-item>
        <ion-label>
          <!-- Subtotal -->
          <p>{{ t('CheckoutPage.subTotal') }}HK${{ getCartTotal().toLocaleString() }}</p>

          <!-- Point discount total -->
          <p v-show="order.pointDiscountTotal > 0">{{ t('pointDiscountTotal') }}- HK${{ order.pointDiscountTotal.toFixed(1).toLocaleString() }}</p>

          <!-- Coupon discount total -->
          <p v-show="getDiscountTotal() > 0">{{ t('CheckoutPage.discountTotal') }}- HK${{ getDiscountTotal().toFixed(1).toLocaleString() }}</p>

          <!-- Shipping total / Free shipping -->
          <p v-if="order.deliveryMethod == '自取'">{{ t('CheckoutPage.shippingTotal') }}HK$0</p>
          <p v-else-if="getCartTotal() >= settings.freeShippingMinOrderTotal">
            {{ t('CheckoutPage.shippingTotal') }}<ion-badge color="success" style="vertical-align: bottom">
              {{ t('CheckoutPage.freeShipping') }}
            </ion-badge>
          </p>
          <p v-else-if="settings.fixedShippingFee">{{ t('CheckoutPage.shippingTotal') }}HK${{ getShippingTotal().toLocaleString() }}</p>
          <p v-else>{{ t('CheckoutPage.shippingTotal') }}{{ t('CheckoutPage.freightCollect') }}</p>

          <!-- Order total -->
          <h3><b>{{ t('CheckoutPage.orderTotal') }}HK${{ getOrderTotal().toFixed(1).toLocaleString() }}</b></h3>
        </ion-label>
      </ion-item>
    </ion-list>

    <!--
      2. Delivery Information
      -->
    <ion-list>
      <ion-list-header color="secondary">
        {{ t('CheckoutPage.deliveryInfo') }}&nbsp;<ion-icon :icon="navigateOutline"></ion-icon>
      </ion-list-header>
      <!--
        Delivery Method
      -->
      <ion-segment v-model="order.deliveryMethod" @ionChange="onDeliveryMethodChanged()">
        <ion-segment-button value="自取" v-show="pickupPoints.length > 0">
          <ion-label>{{ t('CheckoutPage.pickup') }}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="送貨上門">
          <ion-label>{{ t('CheckoutPage.delivery') }}</ion-label>
        </ion-segment-button>
      </ion-segment>

      <div v-if="order.deliveryMethod == '送貨上門'">
        <!-- Free Shipping Notice -->
        <ion-item v-show="getFreeShippingProgress() < 1">
          <ion-label>
            <p>
              <b>{{ t('addMoreOrderAmount') }}HK$ {{ (settings.freeShippingMinOrderTotal - getCartTotal()).toLocaleString() }} {{ t('toGetFreeShippingDiscount') }}</b>
            </p>
          </ion-label>
        </ion-item>

        <!-- Region -->
        <ion-item>
          <ion-label>{{ t('deliveryRegion') }}*</ion-label>
          <ion-select interface="popover" v-model="order.deliveryRegion" @ionChange="order.deliveryDistrict = ''" required>
            <ion-select-option v-for="region in deliveryRegions" :key="region" :value="region">
              {{ t(region) }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <!-- District -->
        <ion-item :disabled="order.deliveryRegion == ''">
          <ion-label>{{ t('deliveryDistrict') }}*</ion-label>
          <ion-select interface="popover" v-model="order.deliveryDistrict" required>
            <ion-select-option v-for="district in getFilteredDistricts()" :key="district.id" :value="district.nameChi">
              {{ getLocalizedStr(district, "nameChi", "nameEn") }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <!-- Address -->
        <ion-item>
          <ion-textarea :label="t('deliveryAddress')" labelPlacement="stacked" :placeholder="t('CheckoutPage.enterDeliveryAddress')"
                        :rows="2" v-model="order.deliveryAddress" required></ion-textarea>
        </ion-item>
      </div>
      
      <div v-else-if="order.deliveryMethod == '自取'">
        <ion-item>
          <ion-select mode="ios" :placeholder="t('CheckoutPage.selectPickupPoint')" interface="action-sheet" v-model="order.pickupPoint"
                      :label="t('CheckoutPage.pickupPoint')" label-placement="floating" aria-required="required">
            <ion-select-option v-for="point in pickupPoints" :key="point.id" :value="point.id">
              {{ point.address }}{{ point.name ? ` (${point.name})` : ""}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
      <ion-item>
        <ion-label position="stacked">{{ t('customerName') }}*</ion-label>
        <ion-input :placeholder="t('CheckoutPage.enterCustomerName')" v-model="order.customerName" required></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ t('contactPhone') }}*</ion-label>
        <ion-input :placeholder="t('CheckoutPage.enterContactPhone')" inputmode="numeric" v-model="order.contactPhone" required></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ t('customerEmail') }}*</ion-label>
        <ion-input :placeholder="t('CheckoutPage.enterCustomerEmail')" type="email" v-model="order.customerEmail" required></ion-input>
      </ion-item>
    </ion-list>

    <!--
      3. Payment information
      -->
    <div v-show="getOrderTotal() > 0">
      <ion-list>
        <ion-list-header color="secondary">
          {{ t('CheckoutPage.paymentMethod') }}&nbsp;<ion-icon :icon="walletOutline"></ion-icon>
        </ion-list-header>

        <!-- Saved / New Payment Methods -->
        <ion-radio-group v-model="order.paymentMethod">
          <ion-item lines="full" v-for="m in stripePaymentMethods" :key="m.id">
            <ion-icon :icon="cardOutline" slot="start"></ion-icon>
            <ion-label>
              <h2>
                <b v-if="m.card.brand == 'visa'">Visa</b>
                <b v-else-if="m.card.brand == 'mastercard'">MasterCard</b>
                <b v-else>{{ m.card.brand }}</b>
              </h2>
              <p>**** {{ m.card.last4 }}</p>
            </ion-label>
            <ion-radio slot="end" :value="m.id" required></ion-radio>
          </ion-item>
        </ion-radio-group>

        <!-- New Credit / Debit Card -->
        <ion-item lines="full" detail button @click="openNewCreditCardModal()">
          <ion-icon :icon="cardOutline" slot="start"></ion-icon>
          <ion-label>{{ t('creditCard') }}</ion-label>
        </ion-item>

        <!-- Other Payment Methods -->
        <ion-radio-group v-model="order.paymentMethod">
          <ion-item lines="full" v-for="method in filteredPaymentMethods()" :key="method.value">
            <ion-thumbnail slot="start" v-if="method.value == 'payme'" style="--size: 32px">
              <img src="@/assets/logo_payme.png" style="height: 32px" />
            </ion-thumbnail>
            <ion-icon :icon="method.icon" slot="start" v-else></ion-icon>
            <ion-label>{{ t(method.key) }}</ion-label>
            <ion-radio slot="end" :value="method.value" required></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>

    <!-- Form Submit Button -->
    <div class="ion-text-center" style="margin-bottom: 100px">
      <ion-button color="tertiary" type="submit" expand="block" :disabled="order.submittingOrder || (getOrderTotal() > 0 && !order.paymentMethod)">
        {{ t('CheckoutPage.confirmPayment') }}
      </ion-button>
      <ion-note><small>{{ t('CheckoutPage.agreeTC') }} <a href="#" @click.prevent="openTCModal()">{{ t('CheckoutPage.orderTC') }}</a></small></ion-note>
    </div>
  </form>

  <ion-modal class="tiny-modal" :is-open="order.isPointModalOpened" @didDismiss="order.isPointModalOpened = false">
    <page-header :showCloseModal="true" :title="t('usePoints')"></page-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <span><small>{{ t('usablePoints') }}：{{ user.availablePoints }}</small></span>
          <ion-col size="12">
            <ion-input type="number" min="0" :max="user.availablePoints" step="20"
                      :placeholder="t('points')" fill="outline" inputmode="numeric" v-model="order.inputPoints"
                      :helper-text="getPointInputHelperText()"></ion-input>
          </ion-col>
        </ion-row>
        <ion-button expand="block" @click="applyInputPoints()" :disabled="!getPointInputHelperText()">
          {{ t('apply') }}
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-modal>
</template>

<script>
// Vue reactivity
import { computed, defineComponent, reactive, ref, watch, onMounted, } from 'vue';

// icons
import { arrowBack, arrowBackOutline, close, add, remove, listOutline, arrowForward, expand,
        trashOutline, closeOutline, cartOutline, navigateOutline, walletOutline, pricetagOutline,
        cardOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonFooter, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonThumbnail, IonBadge,
        IonSegment, IonSegmentButton, IonCard, IonCardContent, IonSpinner, IonProgressBar, IonChip, IonModal,
        IonInput, IonTextarea, IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonNote,
        alertController, loadingController, modalController, isPlatform, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import TermsAndConditionsModal from '@/components/modals/TermsAndConditionsModal.vue';
import ThankYouOrderModal from '@/components/order/ThankYouOrderModal.vue';
import UseCouponModal from '@/components/order/UseCouponModal.vue';
import NewCreditCardModal from '@/components/order/NewCreditCardModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';
import config from '@/config';
import { Capacitor } from "@capacitor/core";

// services
import UserService from '@/services/UserService';
import ProductService from '@/services/ProductService';
import OrderService from '@/services/OrderService';
import { auth } from '@/auth';

export default {
  props: ["products", "stickerId", "orderId", "forceOnlinePayment", "gameSessionId"],
  components: { IonHeader, IonFooter, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonThumbnail, IonBadge,
                IonSegment, IonSegmentButton, IonCard, IonCardContent, IonSpinner, IonProgressBar, IonChip, IonModal,
                IonInput, IonTextarea, IonSelect, IonSelectOption, IonRadio, IonRadioGroup, IonNote,
                LoadingSkeleton, },
  setup(props) {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { getLocalizedStr, formatDate, sleep, openModal, openImageModal,
            presentPrompt, presentAlert, ORDER_STATUSES, PRODUCT_STATUSES,
            addResizeUrlParams, closeModal, } = utils();
    const { paymentMethods } = config;

    const user = computed(() => store.state.user);
    const userOrders = computed(() => store.state.userOrders);
    const settings = computed(() => store.state.settings);
    const districts = computed(() => store.state.districts);
    const pickupPoints = computed(() => store.state.pickupPoints);
    //const deliveryRegions = ["港島", "九龍", "新界", "離島"];
    const deliveryRegions = ["港島", "九龍", "新界"];

    const checkingProducts = ref(true);
    const cartItems = ref([]);
    const stripePaymentMethods = ref([]);

    // Checkout Modal
    const order = reactive({
      id: "",
      pickupPoint: pickupPoints.value[0]?.id,
      deliveryMethod: settings.value.defaultDeliveryMethod || "自取",
      deliveryRegion: user.value.deliveryRegion || "",
      deliveryDistrict: user.value.deliveryDistrict || "",
      deliveryAddress: user.value.deliveryAddress || "",
      customerName: user.value.customerName || user.value.name || "",
      contactPhone: user.value.contactPhone || user.value.phone || "",
      customerEmail: user.value.customerEmail || user.value.email || "",
      totalPrice: null,
      paymentMethod: "",
      stripeCustomerId: user.value.stripeCustomerId || "",
      remark: "",

      stickerId: props.stickerId || "",

      // discounts
      discountCode: "",
      appliedDiscountCode: "",
      appliedDiscountType: "",
      appliedDiscountValue: 0,
      appliedDiscountDescription: "",
      checkingDiscountCode: false,

      // points
      appliedPoints: 0,
      pointDiscountTotal: 0,
      checkingPoints: false,
      isPointModalOpened: false,
      inputPoints: null,
      
      submittingOrder: false,

      bidId: "",
    });
    let prevOrder = null; // for updating existing order

    const getCartTotal = () => (cartItems.value.reduce((a, b) => (b.requiredQty > 0 ? +a + (b.sellingPrice * b.requiredQty) : +a), 0));
    const getDiscountTotal = () => {
      const cartTotal = getCartTotal() - Number(order.pointDiscountTotal);
      if (order.appliedDiscountCode == "") return 0;
      if (order.appliedDiscountType == "fixed_amount") return Math.min(cartTotal, order.appliedDiscountValue);
      return cartTotal - cartTotal * (1-order.appliedDiscountValue); // percentage discount
    };
    const getShippingTotal = () => ((order.deliveryMethod == '自取' || getCartTotal() >= settings.value.freeShippingMinOrderTotal) ? 0 : +settings.value.fixedShippingFee);
    const getOrderTotal = () => {
      const orderTotal = getShippingTotal() + getCartTotal() - Number(getDiscountTotal()) - Number(order.pointDiscountTotal)
      return Math.max(0, orderTotal); // prevent negative order amount
    };

    const presentUnavailableProductsAlert = async (unavailableProducts) => {
      // Present alerts about unavailable items
      const msg = `${t('belowProductsOutOfStock')}<br /><br />`
                  + unavailableProducts.map((p, i) => `${i+1}. ${getLocalizedStr(p, 'title', 'titleEn')}${p.coralId ? ` (${p.coralId})` : ''}`);
      presentAlert(msg, false, () => {
        // Leave if all products are not available
        if (unavailableProducts.length == cartItems.value.length) {
          closeModal();
        }
      });

      // Remove cart items
      store.dispatch('removeProductsFromCart', { products: unavailableProducts });
    }

    const checkLatestProductStatus = async () => {
      const loading = await loadingController.create({ duration: 20000 });
      await loading.present();
      const res = (await ProductService.getAllProducts()).products;

      // Check latest product availability
      const unavailableProducts = [];
      for (const product of cartItems.value) {
        const latestProduct = res.find(p => p.id == product.id);
        if (!latestProduct || latestProduct.status != PRODUCT_STATUSES.available) {
          if (!(latestProduct.status == PRODUCT_STATUSES.hold && latestProduct.reservedBy == user.value.id)) {
            // Skip if user is the one who reserved the item
            product.requiredQty = 0;
            unavailableProducts.push(product);
          }
        }
        product.status = latestProduct?.status;
      }
      store.commit('upsertProducts', res);
      loading.dismiss();

      if (unavailableProducts.length > 0) {
        presentUnavailableProductsAlert(unavailableProducts);
      }
      
      checkingProducts.value = false;
    }

    const fetchRelatedOrder = async (orderId) => {
      const loading = await loadingController.create({});
      await loading.present();
      OrderService.getOrderById(orderId).then(res => {
        if (res) {
          const { bidId, userId, items, status, pickupPoint, customerName, contactPhone, customerEmail,
                  deliveryMethod, deliveryRegion, deliveryDistrict, deliveryAddress } = res;
          if (auth.currentUser == null) {
            presentAlert(t('loginYourAccount'), false, () => {
              router.replace({ path: '/login', query: { redirectPath:`/checkout/${orderId}`  } });
            });
          }
          else if (userId != auth.currentUser.uid) {
            presentAlert(t('incorrectCheckoutUser'), false, () => {
              router.replace('/products');
            });
          }
          else if ([ORDER_STATUSES.pendingCheckout, ORDER_STATUSES.pendingPayment].includes(status)) {
            order.id = orderId;
            order.bidId = bidId;
            order.pickupPoint = pickupPoint;
            order.customerName = customerName;
            order.contactPhone = contactPhone;
            order.customerEmail = customerEmail;
            order.deliveryMethod = deliveryMethod;
            order.deliveryRegion = deliveryRegion;
            order.deliveryDistrict = deliveryDistrict;
            order.deliveryAddress = deliveryAddress;
            cartItems.value = items.map(item => {
              const { id, previewPhotoLink, productId, productSize, quantity, unitPrice, productTitle, productTitleEn, type } = item;
              return {
                id, productId,
                requiredQty: quantity,
                previewPhotoLink,
                title: productTitle,
                titleEn: productTitleEn,
                size: productSize,
                sellingPrice: unitPrice,
                lineTotal: unitPrice * quantity,
                type,
              };
            });
            console.log(cartItems.value);
            prevOrder = res;
          } else {
            presentAlert(t('doubledOrder'), false, () => {
              router.replace(`/orders/${orderId}`);
            });
          }
        } else {
          presentAlert(t('invalidOrderId'), false, () => {
            router.replace('/products');
          });
        }
        loading.dismiss();
      });
    }

    // Discount
    const resetDiscountCode = () => {
      order.discountCode = "";
      order.appliedDiscountCode = "";
      order.appliedDiscountType = "";
      order.appliedDiscountValue = 0;
      order.appliedDiscountDescription = "";
    }
    const useDiscount = (discount) => {
      order.appliedDiscountCode = discount.code;
      order.appliedDiscountType = discount.type;
      order.appliedDiscountValue = discount.type == "fixed_amount" ? discount.fixedAmountValue : discount.percentageValue;
      order.appliedDiscountDescription = discount.description;
    }
    const checkApplyFirstAppOrderDiscount = async () => {
      let discountApplied = false;
      const { autoApplyDiscountCode } = settings.value;
      if (autoApplyDiscountCode) {
        const res = await OrderService.getDiscount(autoApplyDiscountCode);
        if (res) {
          useDiscount(res); // discount code exists
          discountApplied = true;
        }
        else resetDiscountCode(); // invalid discount code
      } 
      /*
      if (!discountApplied && Capacitor.isNativePlatform()) {
        const countOrders = userOrders.value.filter(o => ![ORDER_STATUSES.pendingCheckout, ORDER_STATUSES.cancelled].includes(o.status));
        if (countOrders.length == 0) {
          const res = await OrderService.getDiscount("BETTERONAPP");
          if (res) useDiscount(res); // discount code exists
          else resetDiscountCode(); // invalid discount code
        }
      }
      */
    }

    onMounted(() => {
      const { products, orderId, gameSessionId } = props;
      if (products) {
        cartItems.value = products;
        if (!gameSessionId) checkLatestProductStatus();
      }
      else if (orderId) {
        checkingProducts.value = false; // no need check products
        setTimeout(() => {
          fetchRelatedOrder(orderId); // get order items & products
        }, 500);
      }
      if (user.value.id) {
        checkApplyFirstAppOrderDiscount();

        if (user.value.stripeCustomerId) {
          OrderService.retrievePaymentMethods(user.value.stripeCustomerId).then(res => {
            stripePaymentMethods.value = res;
          })
        }
      }
    })

    watch(userOrders, () => {
      checkApplyFirstAppOrderDiscount();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, close, add, remove, listOutline, arrowForward, expand,
      trashOutline, closeOutline, cartOutline, navigateOutline, walletOutline, pricetagOutline,
      cardOutline,

      // variables
      user, settings, deliveryRegions,
      getFilteredDistricts: () => {
        const filteredDistricts = districts.value.filter(d => d.region == order.deliveryRegion);
        return locale.value == 'zh' ? filteredDistricts : filteredDistricts.sort((d1, d2) => d1.nameEn < d2.nameEn ? -1 : 1);
      },

      order, pickupPoints,
      checkingProducts,

      // methods
      addResizeUrlParams,
      t, getLocalizedStr,
      closeModal, formatDate,
      paymentMethods,
      cartItems, getCartTotal, getDiscountTotal, getShippingTotal, getOrderTotal,
      getFreeShippingProgress: () => (getCartTotal() / settings.value.freeShippingMinOrderTotal),

      openImageModal,
      openTCModal: async () => await openModal(TermsAndConditionsModal, {}),

      resetDiscountCode,
      
      onDeliveryMethodChanged: (e) => {
        if (order.deliveryMethod == '自取') {
          if (!order.pickupPoint) order.pickupPoint = pickupPoints.value[0]?.id;
        }
      },
      
      submitOrder: async (products) => {
        const dismissLoading = () => {
          loadingController.dismiss();
          order.submittingOrder = false;
        }
        const handleOrder = async () => {
          alertController.dismiss(); // dismiss prompt
          const loading = await loadingController.create({});
          await loading.present();

          if (order.deliveryMethod == '自取') {
            order.deliveryAddress = pickupPoints.value.find((p) => p.id == order.pickupPoint)?.address; 
          }
          order.submittingOrder = true;
          order.subtotal = getCartTotal();
          order.discountTotal = getDiscountTotal();
          order.shippingTotal = getShippingTotal();
          order.totalPrice = getOrderTotal();
          if (order.totalPrice <= 0) {
            order.paymentMethod = ""; // no need payment
          }

          const orderItems = cartItems.value.filter(p => p.requiredQty > 0);
          const res = prevOrder ? (await OrderService.updateOrder(order, orderItems, prevOrder))
                                : (await OrderService.createNewOrder(order, orderItems, props.gameSessionId));
          if (!res.success) {
            // TODO: handle not enough points cases
            
            dismissLoading();
            if (res.errType == 'noAvailablePrizes') {
              await presentAlert(t('noAvailablePrizes'), false, () => {
                closeModal(res);
              }); // prizes sold out
            }
            else if (res.errType == 'serverBusy') {
              presentAlert(t('serverBusy')); // Server Busy
            }
            else if (res.errType == 'serverError') {
              presentAlert(t('serverError')); // Server Error
            }
            else if (res.errType == 'invalidDiscountCode') { // Invalid discount code (out of stock)
              resetDiscountCode();
              presentAlert(t('CheckoutPage.invalidDiscountCode'));
            }
            else if (res.errType == 'invalidGiftProducts') { // Invalid gift order items (user no coupons)
              const { invalidGiftProducts } = res;
              presentUnavailableProductsAlert(invalidGiftProducts.map(up => {
                const product = products.find(p => p.id == up.id) || {};
                product.requiredQty = 0;
                return { ...product, ...up, }
              }));
              store.dispatch('getUserData', {});
            }
            else {
              // Some products are unavailable
              const { unavailableProducts } = res;
              if (unavailableProducts && unavailableProducts.length > 0) {
                // Update product status
                store.commit('upsertProducts', unavailableProducts);

                // Present alert
                presentUnavailableProductsAlert(unavailableProducts.map(up => {
                  const product = products.find(p => p.id == up.id) || {};
                  product.requiredQty = 0;
                  return { ...product, ...up, }
                }));
              } else {
                presentAlert(t('serverError')); // Server Error
              }

            }
            return false;
          }

          // Sync user last input order data (user table)
          const { customerName, contactPhone, customerEmail, deliveryRegion, deliveryDistrict, deliveryAddress } = order;
          const updatedUserObj = { customerName, contactPhone, customerEmail, deliveryRegion, deliveryDistrict, deliveryAddress };
          UserService.updateLoggedInUser(updatedUserObj);
          store.commit('updateUser', updatedUserObj);
          
          // add the new order to store
          store.commit('upsertUserOrders', [{ ...res.order }]);

          // Close modals (if any) & dismiss loading
          try {
            await closeModal(res); // checkout modal
            await closeModal(); // DIY product modal
          } catch (e) {
            console.error(e);
          }
          dismissLoading();

          // reset selected products
          if (products) {
            for (const product of products) product.requiredQty = 0;
          }

          // Refresh user list of orders & purchased items (including available points)
          store.dispatch('getUserData', {});

          // Redirect to thank you order page / modal
          if (props.stickerId) {
            openModal(ThankYouOrderModal, { orderId: res.order.id }); // For better UX (designing sticker)
          }
          else if (!props.gameSessionId) {
            console.log(res.order.id);
            router.replace(`/thank-you/${res.order.id || order.id}?parentPath=/products`); // Purchasing normal products (corals)
          }
        }
        if (props.gameSessionId) handleOrder(); // not prompt confirm for game sessions (faster checkout)
        else presentPrompt( t('CheckoutPage.confirmOrder'), "", handleOrder);
      },

      // Stripe Payment Methods
      stripePaymentMethods,
      filteredPaymentMethods: () => {
        const { enabledPaymentMethods, onlinePaymentMethods } = settings.value;
        if (props.forceOnlinePayment && onlinePaymentMethods) {
          return paymentMethods.filter(m => onlinePaymentMethods.split(' , ').includes(m.value));
        }
        return enabledPaymentMethods ? paymentMethods.filter(m => enabledPaymentMethods.split(' , ').includes(m.value)) : paymentMethods;
      },
      openNewCreditCardModal: async () => {
        const modal = await modalController.create({ component: NewCreditCardModal });
        modal.onDidDismiss().then(async ({ data }) => {
          if (data && data.paymentMethod) {
            const { paymentMethod, stripeCustomerId } = data;
            stripePaymentMethods.value.push(paymentMethod);
            order.paymentMethod = paymentMethod.id;

            if (stripeCustomerId) { // Stripe customer created, link to user
              order.stripeCustomerId = stripeCustomerId;
              store.commit('updateUser', { stripeCustomerId });
            }
          }
        });
        return modal.present();
      },

      /**
       * Discounts / Coupons / Points
       */
      openUseCouponModal: async () => {
        const orderItems = cartItems.value.filter(p => p.requiredQty > 0);
        const productIds = orderItems.map(p => p.id);
        const productCategoryIds = [...new Set(orderItems.map(p => p.categoryId))];
        const modal = await modalController.create({
          component: UseCouponModal,
          componentProps: { productIds, productCategoryIds, }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.discount) {
            useDiscount(data.discount);
          }
        });
        return modal.present();
      },
      resetPointDiscount: () => {
        order.appliedPoints = 0;
        order.pointDiscountTotal = 0;
      },
      getPointInputHelperText: () => {
        const POINTS_PER_DOLLAR = settings.value.pointsPerDollar;
        let appliedPoints = Math.min(order.inputPoints, user.value.availablePoints);
        appliedPoints -= (appliedPoints % POINTS_PER_DOLLAR); // remove remainder
        const calPointDiscountTotal = appliedPoints / POINTS_PER_DOLLAR;
        return calPointDiscountTotal > 0 ? `${t('pointDiscountTotal')}- HK$${calPointDiscountTotal}` : '';
      },
      applyInputPoints: async () => {
        if (order.inputPoints > 0) {
          const loading = await loadingController.create({ duration: 20000 });
          await loading.present();
          const orderAmount = getCartTotal() - Number(getDiscountTotal()); // handle case of updating points to be used
          //const orderAmount = getOrderTotal() + order.pointDiscountTotal;
          const { success, appliedPoints, availablePoints, pointDiscountTotal } = await OrderService.checkUserAvailablePoints(order.inputPoints, orderAmount);
          order.appliedPoints = appliedPoints;
          order.pointDiscountTotal = pointDiscountTotal;
          store.commit('updateUser', { availablePoints });
          loading.dismiss();
          order.isPointModalOpened = false;
          order.inputPoints = null;
        }
      },

      existGiftProducts: () => (cartItems.value.find(item => item.type == 'Gift') != null),
      existGameProducts: () => (cartItems.value.find(item => item.type == 'Game') != null),
    }
  }
}
</script>

<style scoped>
  ion-list-header {
    border-radius: 0;
    font-size: 16px;
  }
</style>