<template>
  <page-header :showCloseModal="true" :title="t('checkout')">
  </page-header>

  <ion-content>
    <ion-grid fixed>
      <checkout-form :products="products" :stickerId="stickerId" :forceOnlinePayment="forceOnlinePayment"
                      :gameSessionId="gameSessionId"></checkout-form>
    </ion-grid>
  </ion-content>
</template>

<script>
// Vue reactivity
import { defineComponent, } from 'vue';

// icons
import { arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, } from '@ionic/vue';
import CheckoutForm from '@/components/order/CheckoutForm.vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CheckoutModal',
  props: ["products", "stickerId", "forceOnlinePayment", "gameSessionId"],
  components: { IonHeader, IonGrid, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent,
                CheckoutForm, },
  setup(props) {
    const { t, locale } = useI18n();
    const { closeModal, } = utils();

    return {
      // icons
      arrowBack,

      // methods
      t, closeModal,
    }
  }
})
</script>

<style scoped>
</style>