import placeMutations from './mutations/place';
import petMutations from './mutations/pet';
import infoMutations from './mutations/info';
import stickerMutations from './mutations/sticker';
import cartMutations from './mutations/cart';
import productMutations from './mutations/product';
import auctionMutations from './mutations/auction';
import postMutations from './mutations/post';
import { distanceBetweenCoordinates } from './utils';

import { parsePlace } from './mutations/place';
import { parseUserPet } from './mutations/pet';
import { parseSticker } from './mutations/sticker';

import config from '@/config';

const petPlaceHolderLogo = require('@/assets/no_image.jpeg');

const handleUserData = (state, user) => {
  const userId = user.id;
  state.user = user;
  state.loggedIn = (userId ? true : false);
  //user.darkTheme = (user.darkTheme == 'Y');
  //document.body.classList.toggle('dark', user.darkTheme);
  if (userId) {
    state.places.forEach(place => {
      place.likedByUser = (user.relatedUserLikedPlaces || "").replace(user.id, "").includes(place.id);
    });
    state.infos.forEach(info => {
      info.likedByUser = (user.relatedUserLikedInfos || "").replace(user.id, "").includes(info.id)
    });
    const { purchasedProductIds, likedProductIds } = user;
    state.products.forEach(product => {
      if (purchasedProductIds) product.purchasedByUser = purchasedProductIds.includes(product.id);
      if (likedProductIds) product.likedByUser = likedProductIds.includes(product.id);
    });
  }
}

export default {
  ...infoMutations,
  ...placeMutations,
  ...petMutations,
  ...stickerMutations,
  ...cartMutations,
  ...productMutations,
  ...auctionMutations,
  ...postMutations,

  // Tab Bar (Theme & Buttons)
  setTabBarType(state, tabBarType) {
    state.tabBarType = tabBarType;
    const primaryColor = tabBarType == 'coral' ? config.primaryColorCoral : config.primaryColor; 
    document.documentElement.style.setProperty("--ion-color-primary", primaryColor);
  },
  setForceHideBottomTabs(state, isHide) {
    state.forceHideBottomTabs = isHide;
  },

  // Orders
  upsertUserOrders(state, orders) {
    orders.forEach(order => {
      const idx = state.userOrders.findIndex(o => o.id == order.id);
      if (idx !== -1) {
        state.userOrders[idx] = {
          ...state.userOrders[idx],
          ...order,
        }
      } else {
        state.userOrders.unshift(order); // latest order
      }
    });
  },

  // User
  setLoadingUserData(state, isLoading) {
    state.loadingAppUserData = isLoading;
  },
  receiveAppUserData(state, res) {
    const { user, userPets, userStickers, userOrders, cartItems, userDiscounts, userCertificates, } = res;
    if (user) handleUserData(state, user);
    if (userStickers) {
      state.loadingUserStickers = false;
      state.userStickers = userStickers.map(sticker => parseSticker(sticker));
    }
    if (userPets) state.userPets = userPets.map(up => parseUserPet(up));
    if (userOrders) state.userOrders = userOrders;
    if (cartItems) state.cartItems = cartItems;
    if (userDiscounts) state.userDiscounts = userDiscounts;
    if (userCertificates) state.userCertificates = userCertificates;

    state.loadingAppUserData = false;
  },
  resetUserData(state) {
    state.loadingAppUserData = false;
    state.user = {};
    state.loggedIn = false;
    state.userPets = [];
    state.userStickers = [];
    state.allNotifications = [];
    state.cartItems = [];
  },

  // General
  receiveAppPublicData(state, res) {
    const { banners, settings, places, placeTypes, infos, infoTypes, infoSources, pets,
            stickerTemplates, materialCategories, materials, bandPosts,
            pickupPoints, districts, } = res;
    state.loadingAppPublicData = false;
    if (banners) state.banners = banners;
    if (settings) {
      settings.defaultTextColorCodes = settings.defaultTextColorCodes ? settings.defaultTextColorCodes.split("\n") : [];
      state.settings = settings;
    }

    // BAND
    if (bandPosts) state.bandPosts = bandPosts

    // Pets
    if (pets) {
      state.pets = pets.map(p => {
        p.photoLink = p.photoLink || petPlaceHolderLogo;
        return p;
      });
    }

    // Places
    if (placeTypes) state.placeTypes = placeTypes;
    if (places) state.places = places.map(place => parsePlace(place, state.user));

    // Info
    if (infos) {
      const user = state.user;
      state.infos = infos.map(info => {
        if (user && user.id) info.likedByUser = (user.relatedUserLikedInfos || "").replace(user.id, "").includes(info.id)
        return info;
      });
    }
    if (infoTypes) {
      state.infoTypes = [...new Set(infoTypes.map(r => r.type))];
      state.infoKeywords = infoTypes;
    }
    if (infoSources) state.infoSources = infoSources;

    // DIY
    if (stickerTemplates) {
      state.stickerTemplates = stickerTemplates.filter(t => {
        t.width = t.width ? Number(t.width) : "";
        t.height = t.height ? Number(t.height) : "";
        t.originalWidth = t.originalWidth ? Number(t.originalWidth) : "";
        t.originalHeight = t.originalHeight ? Number(t.originalHeight) : "";
        t.petImgWidth = t.petImgWidth ? Number(t.petImgWidth) : "";
        t.petImgHeight = t.petImgHeight ? Number(t.petImgHeight) : "";
        t.relativePetImgX = t.relativePetImgX ? Number(t.relativePetImgX) : "";
        t.relativePetImgY = t.relativePetImgY ? Number(t.relativePetImgY) : "";
        return t.isVisible == 'Y';
      });
    }
    if (materialCategories) state.materialCategories = materialCategories;
    if (materials) {
      const MAX_WIDTH = 200;
      state.materials = materials.map(m => {
        m.width = m.width ? Number(m.width) : "";
        m.height = m.height ? Number(m.height) : "";
        if (m.width && m.width > MAX_WIDTH) {
          m.height = m.height * (MAX_WIDTH / m.width);
          m.width = MAX_WIDTH;
        }
        m.lineHeight = m.lineHeight ? Number(m.lineHeight) : "";
        return m;
      });
    }
    if (pickupPoints) state.pickupPoints = pickupPoints;
    if (districts) state.districts = districts;
  },
  receiveDevicePlatforms(state, platforms) {
    state.devicePlatforms = platforms;
    state.isMobileApp = platforms.includes('ios') || platforms.includes('android') &&
                        !( platforms.includes('desktop') || platforms.includes('mobileweb') ) ;
  },
  setHomeOpenPetFormModal(state, willOpen) {
    state.homeOpenPetFormModal = willOpen;
  },
  setHomeOpenStickerPreQuestionModal(state, willOpen) {
    state.homeOpenStickerPreQuestionModal = willOpen;
  },

  // USER
  receiveUser(state, user) {
    handleUserData(state, user);
  },
  updateUser(state, updatedObj) {
    for (const key in updatedObj) state.user[key] = updatedObj[key];
  },
  setUserLatLong(state, data) {
    const { latitude, longitude } = data;
    state.userLatLong = { latitude, longitude };
    state.places.forEach(place => {
      if (place.latitude && place.longitude) {
        const distanceKm = distanceBetweenCoordinates(latitude, longitude, place.latitude, place.longitude);
        place.distance = `${distanceKm} 公里`;
        place.distanceVal = distanceKm;
      }
    });
  },

  // Notifications
  receiveNotifications(state, data) {
    state.allNotifications = data;
    state.loadingNotifications = false;
  },
}