import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"padding":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return ($props.useGrid)
    ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.numOfItems || 10, (index) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  "size-xs": "6",
                  "size-md": "4",
                  "size-xl": "3",
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      style: {"width":"100%","height":"200px"}
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        style: {"width":"50%","margin":"0 auto"}
                      }),
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        style: {"width":"50%","margin":"0 auto","margin-top":"3px"}
                      })
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.numOfItems || 10, (index) => {
            return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_thumbnail, {
                  slot: $props.imgSlot || 'start'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_skeleton_text)
                  ]),
                  _: 1
                }, 8, ["slot"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", null, [
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        style: {"width":"80%"}
                      })
                    ]),
                    _createElementVNode("p", null, [
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        style: {"width":"60%"}
                      })
                    ]),
                    _createElementVNode("p", null, [
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        style: {"width":"30%"}
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }))
}