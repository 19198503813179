const placeHolderLogo = require('@/assets/no_image.jpeg');

export default {
  // Receive all products
  receiveAllProducts(state, res) {
    const { products, productCategories } = res;
    const user = state.user;
    state.productCategories = productCategories;
    state.products = products.map(p => {
      if (user && user.id) {
        p.purchasedByUser = user.purchasedProductIds.includes(p.id);
        p.likedByUser = user.likedProductIds.includes(p.id);
      }
      p.photoLink = p.photoLink || placeHolderLogo;

      // For DIY Products
      const { photoWidth, photoHeight, stickerWidth, stickerHeight, stickerRelativeX, stickerRelativeY,
              designRelativeX, designRelativeY, designWidth, designHeight, } = p.photos[0] || {};
      p.photoWidth = photoWidth ? Number(photoWidth) : "";
      p.photoHeight = photoHeight ? Number(photoHeight) : "";
      p.stickerRelativeX = stickerRelativeX ? Number(stickerRelativeX) : "";
      p.stickerRelativeY = stickerRelativeY ? Number(stickerRelativeY) : "";
      p.stickerWidth = stickerWidth ? Number(stickerWidth) : "";
      p.stickerHeight = stickerHeight ? Number(stickerHeight) : "";
      p.designRelativeX = designRelativeX ? Number(designRelativeX) : "";
      p.designRelativeY = designRelativeY ? Number(designRelativeY) : "";
      p.designWidth = designWidth ? Number(designWidth) : "";
      p.designHeight = designHeight ? Number(designHeight) : "";
      p.defaultColorCodes = p.canChangeBgColor ? (p.defaultColorCodes ? p.defaultColorCodes.split("\n") : (state.settings.defaultProductColorCodes || "").split("\n"))
                                                : [];
      return p;
    });
    state.loadingProducts = false;
  },

  // Update / insert products
  upsertProducts(state, data) {
    data.forEach(p => {
      const idx = state.products.findIndex(product => product.id == p.id);
      p.photoLink = p.photoLink || placeHolderLogo;
      if (idx !== -1) {
        state.products[idx] = {
          ...state.products[idx],
          ...p,
        }
      } else {
        state.products.push(p);
      }
    });
  },

  // Add user browsed product & product views
  addUserBrowsedProduct(state, productId) {
    if (!state.user.browsedProductIds.includes(productId)) {
      state.user.browsedProductIds.push(productId);
      const product = state.products.find(p => p.id == productId);
      if (product) product.numOfViews++;
    }
  },

  // Add user liked product & product likes
  updateUserLikedItem(state, { productId, action }) {
    const product = state.products.find(p => p.id == productId);
    if (product) {
      if (action == 'add') {
        product.numOfLikes++;
        product.likedByUser = true;
      } else {
        product.numOfLikes--;
        product.likedByUser = false;
      }
    }
  },
}