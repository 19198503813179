// icons
import { trash, close, } from 'ionicons/icons';

import { ref } from 'vue';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { actionSheetController, loadingController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

export function usePhotoGallery() {
  const { t } = useI18n();
  const photos = ref<Photo[]>([]);

  const takePhoto = async (width = 2000, quality = 80) => {
    const loading = await loadingController.create({ duration: 8000 });
    await loading.present();
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt,
        //source: CameraSource.Photos,
        width,
        quality,
        promptLabelHeader: t('photo'),
        promptLabelCancel: t('cancel'),
        promptLabelPhoto: t('fromPhotos'),
        promptLabelPicture: t('takePicture'),
      });
      const fileName = new Date().getTime() + '.jpeg';
      let mimeType = 'image/jpeg'; // default
      switch (cameraPhoto.base64String.charAt(0)) {
        case '/':
          mimeType = 'image/jpeg';
          break;
        case 'i':
          mimeType = 'image/png';
          break;
        case 'R':
          mimeType = 'image/gif';
          break;
        case 'U':
          mimeType = 'image/webp';
          break;
      }
      const image = {
        filepath: fileName,
        base64Data: `data:${mimeType};base64,${cameraPhoto.base64String}`,
        mimeType,
      }
      photos.value = [image, ...photos.value];
      return image;
    } catch (e) {
      console.error(e);
    } finally {
      loading.dismiss();
    }
  };

  const deletePhoto = async (photo: Photo) => {
    photos.value = photos.value.filter(p => p.filepath !== photo.filepath);
  };

  const showActionSheet = async (photo: Photo) => {
    const actionSheet = await actionSheetController.create({
      header: 'Photos',
      buttons: [{
        text: t('delete'),
        role: 'destructive',
        icon: trash,
        handler: () => {
          deletePhoto(photo);
        }
      },
      {
        text: t('cancel'),
        icon: close,
        role: 'cancel',
      }]
    });
    await actionSheet.present();
  }
  
  const setPhotos = (newPhotos: any) => {
    photos.value = newPhotos;
  }

  return {
    photos,
    takePhoto,
    showActionSheet,
    deletePhoto,
    setPhotos,
  };
}

export interface Photo {
  filepath?: string;
  base64Data?: string;
  mimeType?: string;
}
