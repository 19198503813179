// CART
export const numOfCartItems = (state) => (state.cartItems.length);

export const getCartItem = (state) => (productId, variantId) => {
  return variantId ? state.cartItems.find(item => item.productId == productId && item.variantId == variantId) :
                      state.cartItems.find(item => item.productId == productId);
}

export const detailedCartItems = (state) => {
  const res = state.cartItems.map((ci => {
    const relatedProduct = state.products.find(p => p.id == ci.productId) || {};
    Object.assign(ci, relatedProduct);
    return ci;
  }));
  return res;
}