/**
 * Utilities specifically for DIY design canvas
 */
export function utilsDesign() {
  const FONT_FAMILY_OPTIONS = [
    "中文娃娃體1",
    "中文娃娃體2",
    "Calibri",
    "Arial",
  ];
  const photoFilters = {
    pixelate: 'pixelate',
    posterize: 'posterize',
    //cartoonize: 'cartoonize',
  };
  const OUTPUT_STICKER_WIDTH = 512;
  const GUIDELINE_OFFSET = 1;

  const getProxyImgLink = (imgLink: any) => (imgLink && imgLink.startsWith("http") ? `https://cms.signals.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink);
  const loadHTMLImg = (imgLink: any, callback: any) => {
    const IMG_RETRY_MS = 3000; // retry downloading images every X ms on failure
    const img = new window.Image();
    img.onload = callback(img);
    img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(imgLink), IMG_RETRY_MS); }
    img.crossOrigin = 'Anonymous';
    img.src = getProxyImgLink(imgLink);
  }

  const getShapeDataURL = (stageRef: any, shape: any) => {
    if (stageRef) {
      const targetNode = stageRef.getStage().findOne(`#${shape.id}`);
      return targetNode ? targetNode.toDataURL({ mimeType: 'image/png', pixelRatio: 0.5 }) : "";
    }
    return "";
  };

  // Save Canvas Data
  const resetCustomShapeFilterState = (shapes: any) => {
    for (const shape of shapes) {
      if (shape.id.startsWith("custom_")) {
        shape.addedFilters = false;
      }
    }
  }
  const releaseHTMLCanvases = (canvases: any) => {
    for (const canvas of canvases) {
      if (typeof canvas === "object" && canvas !== null) {
        /*
        canvas.width = 0;
        canvas.height = 0;
        canvas.remove();
        canvas = null;
        */
        canvas.width = 1;
        canvas.height = 1;
        const ctx = canvas.getContext('2d');
        ctx && ctx.clearRect(0, 0, 1, 1);
      }
    }
  }
  const clearKonvaDOMCanvases = (stage: any) => {
    const releasingCanvases = [
      stage.bufferCanvas._canvas, stage.bufferHitCanvas._canvas
    ];
    for (const layer of stage.find("Layer")) {
      releasingCanvases.push(layer.canvas._canvas);
      releasingCanvases.push(layer.hitCanvas._canvas);
      for (const imageNode of layer.find("Image")) {
        const canvasCache = imageNode._cache.get("canvas");
        if (canvasCache) {
          const { filter, hit, scene } = canvasCache;
          releasingCanvases.push(filter._canvas, hit._canvas, scene._canvas);
        }
      }
    }
    releaseHTMLCanvases(releasingCanvases);
  }

  const getTrimmedCanvasDataURL = (canvas, cleanUpSrcCanvas = false, margin = 0) => {
    function rowBlank(imageData, width, y) {
      for (let x = 0; x < width; ++x) {
          if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
      }
      return true;
    }
    function columnBlank(imageData, width, x, top, bottom) {
        for (let y = top; y < bottom; ++y) {
            if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
        }
        return true;
    }
    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let top = 0, bottom = imageData.height, left = 0, right = imageData.width;

    while (top < bottom && rowBlank(imageData, width, top)) ++top;
    while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
    while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
    while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;

    // Add a margin to each side
    top = Math.max(top - margin, 0);
    left = Math.max(left - margin, 0);
    bottom = Math.min(bottom + margin, imageData.height);
    right = Math.min(right + margin, imageData.width);

    let res;
    const trimmedWidth = right-left, trimmedHeight = bottom-top;
    if (trimmedWidth > 0 && trimmedHeight > 0) {
      const trimmed = ctx.getImageData(left, top, trimmedWidth, trimmedHeight);
      const copy = document.createElement("canvas");
      const copyCtx = copy.getContext("2d");
      copy.width = trimmed.width;
      copy.height = trimmed.height;
      copyCtx.putImageData(trimmed, 0, 0);

      res = copy.toDataURL('image/webp');
      releaseHTMLCanvases([copy]); // clean-up
    } else {
      res = canvas.toDataURL('image/webp');
    }
    if (cleanUpSrcCanvas) releaseHTMLCanvases([canvas]); // clean-up
    return res;
  }

  const getDBCanvasData = (canvas: any) => {
    const targetKeys = ['materialId', 'type', 'color', 'id', 'x', 'y', 'width', 'height', 'rotation', 'scaleX', 'scaleY',
                        'text', 'color', 'fontFamily', 'strokeWidth', 'strokeColor', 'fontStyle',
                        'useRembgPhoto', 'brightness', 'blurRadius'];
    const { shapes, backgroundMaterialId, backgroundImageScale, backgroundImageX, backgroundImageY,
            designLayerX, designLayerY, designLayerScale, } = canvas;
    return JSON.stringify({
      backgroundImageScale, backgroundImageX, backgroundImageY, backgroundMaterialId,
      designLayerX, designLayerY, designLayerScale,
      shapes: shapes.map((s: any) => {
        const resObj = {};
        for (const key of targetKeys) {
          if (key in s) {
            resObj[key] = ['x','y','scaleX','scaleY','rotation','brightness','blurRadius'].includes(key) ? (Math.round(s[key] * 100) / 100) : s[key];
          }
        }
        return resObj;
      })
    });
  }

  // Pinch (Gesture)
  const getCenter = (p1: any, p2: any) => ({ x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 });
  const getDistance = (p1: any, p2: any) => (Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)));
  
  const setPanelModalBreakpoint = (breakpoint: any, selector: any = "#panel-modal") => {
    const panelModal: any = document.querySelector(selector);
    panelModal.setCurrentBreakpoint(breakpoint);
  }
  const isPinching = (e: any) => {
    const [touch1, touch2] = (e && e.evt ? (e.evt.touches || []) : []);
    return touch1 && touch2;
  }

  const getTotalBox = (boxes) => {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    boxes.forEach((box: any) => {
      minX = Math.min(minX, box.x);
      minY = Math.min(minY, box.y);
      maxX = Math.max(maxX, box.x + box.width);
      maxY = Math.max(maxY, box.y + box.height);
    });
    return { x: minX, y: minY, width: maxX - minX, height: maxY - minY, };
  }

  const addFiltersToShape = (stageRef: any, shape: any, filters: any, pixelRatio: any) => {
    if (!shape.addedFilters && stageRef) {
      const targetNode = stageRef.getStage().findOne(`#${shape.id}`);
      if (targetNode) {
        targetNode.filters(filters);
        targetNode.cache({ pixelRatio });
        shape.addedFilters = true;
      }
    }
  }

  // Transformer
  const checkAttachTransformer = (transformer: any, selectedShape: any, forceAttach = false) => {
    if (transformer) {
      let selectedNode = null;
      const transformerNode = transformer.getNode();

      if (transformerNode) {
        if (selectedShape.id) {
          const stage = transformerNode.getStage();
          selectedNode = stage.findOne(`#${selectedShape.id}`);

          if (selectedNode === transformerNode.node() && !forceAttach) return; // do nothing if selected node is already attached
        }
        transformerNode.nodes(selectedNode ? [selectedNode] : []); // attach to another node / remove transformer
      }
    }
  }
  const clearTransformerSelection = (transformer: any) => {
    const transformerNode = transformer.getNode();
    transformerNode.nodes([]); // clear transformer selection
  }

  // can we snap our objects?
  const getLineGuideStops = (skipShapes: any, stage: any, shapeLayer: any) => {
    // we can snap to stage borders and the center of the stage
    const vertical = [0, stage.width() / 2, stage.width()];
    const horizontal = [0, stage.height() / 2, stage.height()];

    // and we snap over edges and center of each object on the canvas
    shapeLayer.find(node => {
      if (node.getType() === 'Shape' && node.id()) {
        if (skipShapes.some(shape => node.id() === shape.id())) return;
        const box = node.getClientRect();
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
      }
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  const getObjectSnappingEdges = (node: any) => {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  }

  const getGuides = (lineGuideStops: any, itemBounds: any) => {
    const resultV = [], resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides = [];

    // find closest snap
    const minVs = resultV.sort((a, b) => a.diff - b.diff).slice(0, 3);
    const minHs = resultH.sort((a, b) => a.diff - b.diff).slice(0, 3);
    if (minVs) {
      for (const minV of minVs) {
        guides.push({
          points: [0, -6000, 0, 6000],
          x: minV.lineGuide,
          y: 0,
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        });
      }
    }
    if (minHs) {
      for (const minH of minHs) {
        guides.push({
          points: [-6000, 0, 6000, 0],
          x: 0,
          y: minH.lineGuide,
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        });
      }
    }
    return guides;
  }

  const getSnappedAbsPos = (guides: any, absPos: any) => {
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
      }
    });
    return absPos;
  }

  const refreshLineGuides = (targetShape: any, skipShapes: any, stageRef: any, canvas: any) => {
    const stage = stageRef.value.getStage();
    canvas.lineGuides = [];
    const lineGuideStops = getLineGuideStops(skipShapes, stage, stage.findOne('.designLayer')); // find possible snapping lines
    const itemBounds = getObjectSnappingEdges(targetShape); // find snapping points of current object
    canvas.lineGuides = getGuides(lineGuideStops, itemBounds); // now find where can we snap current object
  }
  
  return {
    // variables
    OUTPUT_STICKER_WIDTH,
    FONT_FAMILY_OPTIONS,
    photoFilters,

    // methods
    resetCustomShapeFilterState,
    clearKonvaDOMCanvases, releaseHTMLCanvases, getTrimmedCanvasDataURL,
    getDBCanvasData, getShapeDataURL,

    getCenter,
    getDistance,
    setPanelModalBreakpoint,
    isPinching,
    getTotalBox,
    addFiltersToShape,

    // Line Guides
    getLineGuideStops, getObjectSnappingEdges,
    getGuides, getSnappedAbsPos,

    // Transformer
    checkAttachTransformer, clearTransformerSelection,
    refreshLineGuides,
    onTrDragMove: (e: any, stageRef: any, canvas: any, transformer: any) => {
      // Show latest line guides
      refreshLineGuides(e.target.nodes()[0], e.target.nodes(), stageRef, canvas);

      // Snap shape if match line guides
      const tr = transformer.value.getNode();
      const boxes = tr.nodes().map((node: any) => node.getClientRect());
      const box = getTotalBox(boxes);
      tr.nodes().forEach((shape: any) => {
        const absPos = shape.getAbsolutePosition();

        // we total box goes outside of viewport, we need to move absolute position of shape
        /*
        const newAbsPos = { ...absPos };
        const halfBoxWidth = box.width / 2;
        const halfBoxHeight = box.height / 2;

        // where are shapes inside bounding box of all shapes?
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;
  
        if (box.x < -halfBoxWidth) newAbsPos.x = -offsetX - halfBoxWidth;
        if (box.y < -halfBoxHeight) newAbsPos.y = -offsetY - halfBoxHeight;

        if (box.x + halfBoxWidth > stage.width()) {
          newAbsPos.x = stage.width() - box.width - offsetX + halfBoxWidth;
        }
        if (box.y + halfBoxHeight > stage.height()) {
          newAbsPos.y = stage.height() - box.height - offsetY + halfBoxHeight;
        }
        */
        shape.setAbsolutePosition(getSnappedAbsPos(canvas.lineGuides, absPos));
      });
    },

    // Loading
    initMaterials: async (materials: any) => {
      const promises = [];
      for (const m of materials) {
        if (!('loadedImg' in m) && m.type == 'image') {
          promises.push(
            new Promise((resolve, reject) => {
              m.loadedImg = null;
              loadHTMLImg(m.photoLink, (img: any) => {
                m.loadedImg = img;
                resolve(true);
              });
            })
          )
        }
        // check & load the related colors as well
        if (m.colors && m.colors.length > 0) {
          for (const c of m.colors) {
            if (!('loadedImg' in c)) {
              promises.push(
                new Promise((resolve, reject) => {
                  c.loadedImg = null;
                  loadHTMLImg(c.photoLink, (img: any) => {
                    c.loadedImg = img;
                    resolve(true);
                  });
                })
              )
            }
          }
        }
      }
      return await Promise.all(promises);
    },

    // DIY Cup products
    concatCupPreviewPhotos: async (leftCanvas: any, centerCanvas: any, rightCanvas: any) => {
      const canvasEl = document.createElement("canvas");
      const ctx = canvasEl.getContext("2d");
      ctx.fillStyle = "#fff"; // white background
      canvasEl.width = leftCanvas.width + centerCanvas.width + rightCanvas.width;
      canvasEl.height = Math.max(leftCanvas.height, centerCanvas.height, rightCanvas.height);
      ctx.drawImage(leftCanvas, 0, 0, leftCanvas.width, leftCanvas.height); // left cup
      ctx.drawImage(centerCanvas, leftCanvas.width, 0, centerCanvas.width, centerCanvas.height); // center cup next to left
      ctx.drawImage(rightCanvas, canvasEl.width - rightCanvas.width, 0, rightCanvas.width, rightCanvas.height); // right cup next to center
      const res = canvasEl.toDataURL('image/jpeg'); // combined cup photos (side-by-side)
      releaseHTMLCanvases([canvasEl]); // clean-up

      return res;
    },

    getCupDesignPhotoBase64: async (stickerImg: HTMLImageElement, exportAspectRatio: any) => {
      const canvasEl = document.createElement("canvas");
      const ctx = canvasEl.getContext("2d");
      const { width: iw, height: ih } = stickerImg;
      const stickerImgRatio = ih / iw;

      canvasEl.width = 2000;
      canvasEl.height = canvasEl.width / exportAspectRatio; // rectangular canvas for cups

      const emptyAreaWidth = 100;
      const stickerWidth = canvasEl.width / 3 - emptyAreaWidth;
      const stickerHeight = stickerWidth * stickerImgRatio;
      const stickerRelativeX = emptyAreaWidth / 2;
      const stickerRelativeY = (canvasEl.height - stickerHeight) / 2;

      ctx.drawImage(stickerImg, 0, 0, iw, ih, stickerRelativeX, stickerRelativeY, stickerWidth, stickerHeight); // left
      ctx.drawImage(stickerImg, 0, 0, iw, ih, stickerRelativeX + (canvasEl.width / 3) * 2, stickerRelativeY, stickerWidth, stickerHeight); // right

      const res = canvasEl.toDataURL('image/webp'); // combined cup photos (side-by-side)
      releaseHTMLCanvases([canvasEl]); // clean-up
      
      return res;
    },

    // Material Categories
    navigateMaterialCategories: (direction, modalSelector = '#panel-modal') => {
      //const selectedSegmentBtn = document.querySelector(`${modalSelector} .sections ion-segment-button[aria-selected="true"]`);
      const selectedSegmentBtn = document.querySelector(`${modalSelector} .sections ion-segment-button.segment-button-checked`);
      const targetElement: any = direction == 'prev' ? selectedSegmentBtn.previousElementSibling : selectedSegmentBtn.nextElementSibling;
      console.log(targetElement);
      if (targetElement) {
        targetElement.click();
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    },

    // Sticker Export (512 x 512)
    convertCanvasToImg: (canvas: any, outputWidth = 512, outputHeight = 512) => {
      const canvasEl = document.createElement("canvas");
      const ctx = canvasEl.getContext("2d");
      
      canvasEl.width = outputWidth;
      canvasEl.height = outputHeight;

      ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);
      const res = canvasEl.toDataURL('image/webp'); // combined cup photos (side-by-side)
      releaseHTMLCanvases([canvasEl]); // clean-up

      return res;
    }
  }
}