import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23703c21"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createBlock(_component_ion_chip, {
    class: "comment-user-chip",
    outline: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_avatar, null, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: $props.comment.userProfilePic || 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg'
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.comment.username) + "・" + _toDisplayString($setup.getRelativeDate($props.comment.createdAt)), 1)
        ]),
        _: 1
      }),
      ($props.comment.type == 'notification')
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            size: "small",
            color: "tertiary",
            icon: $setup.notificationsOutline
          }, null, 8, ["icon"]))
        : (['bid', 'result'].includes($props.comment.type))
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 1,
              size: "small",
              color: "tertiary",
              icon: $setup.hammerOutline
            }, null, 8, ["icon"]))
          : (($props.comment.userRoles || '').includes('admin'))
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 2,
                size: "small",
                color: "tertiary",
                icon: $setup.ribbonOutline
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}