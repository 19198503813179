<template>
  <!-- Landing Page Button Slides -->
  <swiper
    :modules="modules"
    :slidesPerView="landingBanners.length == 1 ? 1 : 2"
    :autoplay="{ delay: settings.landBannerAutoplayDelay || 8000 }"
    :speed="300"
    :loop="true"
    :pagination="true"
    :autoHeight="false"
    :spaceBetween="10"
    v-if="landingBanners"
  >
    <swiper-slide class="ion-activatable ripple-parent"
                  v-for="banner in landingBanners" :key="banner" @click="openBannerLink(banner.link, banner.tabBarType)">
      <ion-badge mode="ios" class="top-left-badge" v-show="banner.topLeftText">
        {{ banner.topLeftText }}
      </ion-badge>
      
      <img style="width: 100%" class="landing-banner-img" :src="banner.imageLink" />

      <ion-ripple-effect style="border-radius: 25px"></ion-ripple-effect>
    </swiper-slide>

  </swiper>  

  <!-- Banner Slides -->
  <swiper
    @swiper="setSwiperInstance"
    id="card-slides"
    class="banner-slides"
    :modules="modules"
    :slidesPerView="1"
    :breakpoints="bannerOps.breakpoints"
    :autoplay="{
      delay: settings.cardBannerAutoplayDelay || 4000
    }"
    :speed="bannerOps.speed"
    :loop="true"
    :pagination="false"
    :autoHeight="false"
    :centeredSlides="true"
    v-else-if="banners"
  >
    <swiper-slide v-for="banner in banners" :key="banner" :style="{
                    background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${banner.imageLink})`,
                    'background-position': banner.link == 'pet-id-card' ? 'top' : 'center'
                  }">
      <div class="pet-id-card-slide" @click="openImageModal(banner.imageLink, banner.petName || '', true)" v-if="banner.link == 'pet-id-card'">
        <ion-badge mode="ios" class="top-left-badge" color="medium" v-show="settings.bannerShowPetJoinTime == 'Y'">
          {{ getRelativeDate(banner.createdAt) }}
        </ion-badge>
        <ion-badge mode="ios" class="top-right-badge" color="danger">
          <ion-icon :icon="sparkles"></ion-icon> 
          {{ t('welcome') }}{{ banner.petName || '' }}！
        </ion-badge>
        <div class="ion-text-center">
          <!--<ion-img class="pet-id-card-img" :src="banner.imageLink" @ionImgDidLoad="banner.imgLoaded = true"></ion-img>-->
          <img style="border-radius: 22px" :src="banner.imageLink" @load="banner.imgLoaded = true" />
        </div>
        <ion-spinner class="banner-img-spinner" v-show="!banner.imgLoaded"></ion-spinner>

        <ion-button style="margin-top: 5px" fill="solid" color="secondary" @click.stop="checkOpenPetFormModal()">
          <ion-icon slot="start" :icon="idCardOutline"></ion-icon>
          <b>{{ settings.homeIdCardBtnText }}</b>
        </ion-button>
      </div>

      <div class="sticker-card-slide" @click="openImageModal(banner.imageLink, '', true)" v-else-if="banner.link == 'user-sticker'">
        <ion-badge mode="ios" class="top-left-badge" color="medium" v-show="settings.bannerShowStickerTime == 'Y'">
          {{ getRelativeDate(banner.createdAt) }}
        </ion-badge>
        <div class="ion-text-center">
          <img style="border-radius: 22px" :src="banner.imageLink" @load="banner.imgLoaded = true" />
        </div>
        <ion-spinner class="banner-img-spinner" v-show="!banner.imgLoaded"></ion-spinner>

        <ion-button style="margin-top: 5px" fill="solid" color="secondary" @click.stop="checkOpenStickerPreQuestionModal()">
          <ion-icon slot="start" :icon="logoWhatsapp"></ion-icon>
          <b>{{ settings.homeStickerBtnText }}</b>
        </ion-button>
      </div>
      
      <div @click="openBannerLink(banner.link)" v-else>
        <ion-img class="banner-img" :src="banner.imageLink"></ion-img>
        <ion-img :src="banner.imageLink" @ionImgDidLoad="banner.imgLoaded = true"></ion-img>
        <ion-spinner class="banner-img-spinner" v-show="!banner.imgLoaded"></ion-spinner>
      </div>

    </swiper-slide>
  </swiper>

  <!-- Place Slides -->
  <!--<div v-else-if="places && places.length > 0">-->
  <div v-else-if="places && places.length > 0">
    <h2 class="section-title" v-if="title">{{ title }}</h2>

    <swiper
      :spaceBetween="10"
      :slidesPerView="3.1"
      :breakpoints="ops.breakpoints"
      :cssMode="true"
    >
      <swiper-slide v-for="place in places" :key="place.id">
        <ion-card class="ion-text-center place-card" :router-link="`/places/${place.id}`" button>

          <ion-badge class="top-left-badge" color="medium">{{ place["地區"] }}</ion-badge>

          <div class="place-logo-container">
            <!--<ion-img :src="place.mainImgLink" class="place-logo" @ionImgDidLoad="place.imgLoaded = true"></ion-img>-->
            <img :src="place.mainImgLink" class="place-logo" @load="place.imgLoaded = true" />
            <ion-spinner class="img-loading-spinner" v-show="!place.imgLoaded"></ion-spinner>
          </div>

          <ion-badge color="light" class="bookmark-icon" v-if="userLoggedIn && place.likedByUser" @click.stop="updateUserLikedPlace(place, 'remove')">
            <ion-icon :icon="heart" color="danger"></ion-icon>
          </ion-badge>
          <ion-badge color="light" class="bookmark-icon" v-if="userLoggedIn && !place.likedByUser" @click.stop="updateUserLikedPlace(place, 'add')">
            <ion-icon :icon="heartOutline"></ion-icon>
          </ion-badge>

          <ion-card-content style="padding: 10px 0 0 0">
            <h3 class="ion-text-nowrap" style="margin-bottom: 5px">
              <b>{{ place["名稱"] }}</b>
            </h3>
            <p class="ion-text-nowrap">
              <span v-if="title == '最短距離' || title == '24小時營業'">{{ place.distance }}</span>
              <span v-else-if="title == '最耐歷史'">{{ place.yearFounded }}年</span>
              <span v-else-if="['最高評分', '最低評分'].includes(title)">
                <!--<star-rating :rating="place.avgSMPRating"></star-rating><br />-->
                <ion-icon color="warning" :icon="star"></ion-icon> 
                {{ place.avgSMPRating }} / 5 ({{ place.avgSMPRatingUserTotal }})
              </span>
              <span v-else-if="title == '最多人 Like / Follow'">{{ numberWithCommas(place.totalSMPLikes) }}人</span>
              <span v-else-if="title == '最多人評論'">{{ numberWithCommas(place.avgSMPRatingUserTotal) }}個評論</span>
            </p>
            <ion-badge :color="getTypeColor(place['種類'])" v-show="typeFilter.toLowerCase() == 'all'">{{ place['種類'] }}</ion-badge>
          </ion-card-content>
        </ion-card>
      </swiper-slide>
    </swiper>

    <div class="section-divider" v-if="!hideDivider"></div>
  </div>

  <!-- User Sticker Slides -->
  <div v-else-if="userStickers">
    <swiper
      :slidesPerView="2.1"
      :breakpoints="ops.breakpoints"
      :freeMode="{ enabled: true }"
      :cssMode="true"
    >
      <swiper-slide>
        <ion-card style="display: flex; height: 165px; width: 100%;" class="ion-text-center ion-align-items-center ion-justify-content-center" @click="checkOpenStickerPreQuestionModal()">
          <ion-button color="success" class="ion-text-wrap" style="height: 60px; margin: 0 5px">
            <ion-icon slot="start" :icon="add"></ion-icon>
            {{ t('addSticker') }}
          </ion-button>
        </ion-card>
      </swiper-slide>

      <swiper-slide v-for="us in userStickers" :key="us.id">
        <ion-card style="width: 100%" class="ion-text-center" :router-link="`/stickers/${us.id}`" button>
          <img :src="us.outputPhotoBase64 || us.outputPhoto" style="width: 140px" />
          <p style="margin: 5px auto">{{ us.name }}</p>
        </ion-card>
      </swiper-slide>
    </swiper>
  </div>


  <!-- User Pet Slides -->
  <div v-else-if="userPets">
    <swiper
      :slidesPerView="1.1"
      :breakpoints="{ 768: { slidesPerView: 2.1 }, 1024: { slidesPerView: 3.1 } }"
    >
      <swiper-slide v-for="up in userPets" :key="up.id">
        <ion-card class="pet-card ion-text-center" @click="openPetFormModal(up)" button>

          <ion-badge class="top-right-badge" :color="up.gender == 'male' ? 'tertiary' : 'danger'" v-show="up.gender">
            <ion-icon class="icon-md" :icon="up.gender =='male' ? male : female"></ion-icon>
          </ion-badge>

          <ion-row>
            <ion-col class="flex-col" size="6">
              <img class="valign" :src="up.cardProfilePhoto" />
            </ion-col>
            <ion-col size="6">
              <h2 style="margin-bottom: 5px">
                <b>{{ up.name }}</b>
              </h2>
              <p>
                {{ t('Pet.dateOfBirth') }}: {{ formatDateString(up.dateOfBirth) }}<br />
                {{ t('Pet.origin') }}: {{ up.origin }}
              </p>

              <ion-buttons style="justify-content: center">
                <ion-button slot="icon-only" @click.stop="openPetFormModal(up)">
                  <ion-icon :icon="idCardOutline"></ion-icon>
                  <!--身份證-->
                </ion-button>
                <ion-button slot="icon-only" @click.stop="openPetFormModal(up, '尋寵啟事')">
                  <ion-icon :icon="newspaperOutline"></ion-icon>
                  <!--尋寵啟事-->
                </ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-card>
      </swiper-slide>

      <swiper-slide>
        <ion-card style="display: flex; height: 150px;" class="pet-card ion-text-center ion-align-items-center ion-justify-content-center" @click="checkOpenPetFormModal()">
          <ion-button>
            <ion-icon slot="start" :icon="add"></ion-icon>
            {{ t('addPet') }}
          </ion-button>
        </ion-card>
      </swiper-slide>
    </swiper>
  </div>

  <div v-else-if="userOrders">
    <swiper
      :slidesPerView="2.1"
      :breakpoints="ops.breakpoints"
      :freeMode="{ enabled: true }"
      :cssMode="true"
    >
      <swiper-slide v-for="order in userOrders" :key="order.id">
        <ion-card style="width: 100%" class="ion-text-center" :router-link="{
          name: 'OrderDetailPage',
          params: { id: order.id }
        }" button>
          <p><b>#{{ order.code }}</b></p>
          <p>{{ order.orderDate }}</p>
          <p>HK${{ order.totalPrice }}</p>
          <p>
            <ion-badge :color="getOrderStatusColor(order.status)">
              {{ order.status }}
            </ion-badge>
          </p>
        </ion-card>
      </swiper-slide>
    </swiper>
  </div>

  <div v-else-if="products" style="margin-bottom: 100px">
    <swiper
      :slidesPerView="2.1"
      :breakpoints="ops.breakpoints"
      :freeMode="{ enabled: true }"
      :cssMode="true"
    >
      <swiper-slide v-for="product in products" :key="product.id">
        <product-card :product="product" :userLoggedIn="userLoggedIn" :showActionButtons="true"></product-card>
      </swiper-slide>
    </swiper>
  </div>

  <div v-else-if="prizeProducts" style="margin-bottom: 20px">
    <swiper
      :slidesPerView="3.1"
      :breakpoints="ops.breakpoints"
      :freeMode="{ enabled: true }"
      :cssMode="true"
    >
      <swiper-slide v-for="pp in prizeProducts" :key="pp.id">
        <product-card :product="pp.prizeProduct" :userLoggedIn="userLoggedIn" :showActionButtons="false" :hidePrice="true"
                      :winningPercentage="pp.winningPercentage"></product-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { computed, ref, } from 'vue';

// icons
import { star, male, female, add, heart, heartOutline, idCardOutline, logoWhatsapp, newspaperOutline, sparkles, } from 'ionicons/icons';

// components
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Navigation, Pagination, FreeMode, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { IonicSlides, IonCard, IonCardContent, IonBadge, IonIcon, IonSpinner,
        IonRow, IonCol, IonButtons, IonButton, IonImg, IonChip, IonLabel, IonRippleEffect,
        loadingController, modalController, } from '@ionic/vue';
import StarRating from "@/components/StarRating.vue";
import PetFormModal from '@/components/modals/PetFormModal.vue';
import ImageModal from '@/components/modals/ImageModal.vue';
import StickerPreQuestionModal from '@/components/modals/StickerPreQuestionModal.vue';

// composables
import config from '@/config';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// services
import StickerService from '@/services/StickerService';

export default {
  props: [
    "prizeProducts",
    "products",
    "landingBanners",
    "banners",
    "userPets",
    "userStickers",
    "userOrders",
    "places",
    "title",
    "typeFilter",
    "hideDivider",
    "homeIdCardBtnText",
    "maxStickerCount",
  ],
  components: { Swiper, SwiperSlide, IonCard, IonCardContent, IonBadge, IonIcon, IonSpinner,
                IonRow, IonCol, IonButtons, IonButton, IonImg, IonChip, IonLabel, IonRippleEffect,
                StarRating, },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const { getTypeColor, numberWithCommas, openModal, formatDateString, sleep, openInAppBrowser,
            presentAlert, getRelativeDate, getOrderStatusColor, uniqueId, addResizeUrlParams, openLoginModal, } = utils();

    const userLoggedIn = computed(() => store.state.loggedIn);
    const settings = computed(() => store.state.settings);

    const cardSlides = ref();
    const setSwiperInstance = (swiper) => cardSlides.value = swiper;

    const bannerOps = {
      speed: config.HomePage.banner.slidingSpeed,
      autoplay: {
        delay: config.HomePage.banner.autoPlayDelay,
      },
      breakpoints: {
        // when window width is >= 640px
        1024: {
          slidesPerView: config.HomePage.banner.desktopSlidesPerView,
        },
      },
    };

    const ops = {
      slidesPerView: 2.5,

      breakpoints: {
        // when window width is >= 640px
        768: {
          slidesPerView: 3.5,
        },
        1024: {
          slidesPerView: 4.5,
        }
      }
    };

    const openImageModal = async (imageLink, caption, isPetCardImg = false) => {
      cardSlides.value.autoplay.stop();
      const modal = await modalController.create({
        component: ImageModal,
        componentProps: { imageLink, caption, isPetCardImg },
      });
      modal.onDidDismiss().then(() => {
        cardSlides.value.autoplay.start();
      })
      return modal.present();
    }
    const openPetFormModal = async (editingUserPet = null, targetCanvasType = null) => {
      return await openModal(PetFormModal, { editingUserPet, targetCanvasType });
    }
    const checkLoggedIn = () => {
      if (userLoggedIn.value) return true;
      openLoginModal();
      return false;
    }
    const openStickerPreQuestionModal = async () => (await openModal(StickerPreQuestionModal, { }));
    const goToStickerDetailPage = async () => {
      if (checkLoggedIn()) {
        const loading = await loadingController.create({});
        await loading.present();
        const newUserStickerId = uniqueId(), name = t('untitledSticker');
        StickerService.upsertUserSticker({ newUserStickerId, name, }); // Insert Sticker Record to DB
        store.commit('upsertUserSticker', { name, id: newUserStickerId });
        router.replace(`/stickers/${newUserStickerId}`);
        loading.dismiss();
      }
    }
    
    const updateUserLikedPlace = async (place, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateUserLikedPlace', { place, id: place.id, action });
      await sleep(1); // 1 second
      loading.dismiss();
    }

    const openBannerLink = (link, tabBarType = '') => {
      if (link) {
        if (link.startsWith("/")) {
          if (tabBarType) {
            store.commit('setTabBarType', tabBarType); // coral / pet (theme)
          }
          if (link == '/stickers') {
            goToStickerDetailPage();
          } else {
            router.push(link);
          }
        }
        else openInAppBrowser(link);
      }
    }
    const checkOpenStickerPreQuestionModal = async () => {
      if (userLoggedIn.value == true) {
        const { userStickers, maxStickerCount } = props;
        if (userStickers.length < maxStickerCount) {
          //openStickerPreQuestionModal();
          goToStickerDetailPage();
        } else {
          await presentAlert(t('reachedMaxStickerCount'));
        }
      } else {
        await presentAlert(t('loginFirst'), true, () => {
          router.push({ path: '/login' });
        });
        store.commit('setHomeOpenStickerPreQuestionModal', true);
      }
    }
    const checkOpenPetFormModal = async () => {
      if (userLoggedIn.value == true) {
        openPetFormModal();
      } else {
        await presentAlert(t('loginFirst'), true, () => {
          router.push({ path: '/login' });
        });
        store.commit('setHomeOpenPetFormModal', true);
      }
    }

    return {
      // icons
      star, male, female, add, heart, heartOutline,
      idCardOutline, logoWhatsapp, newspaperOutline, sparkles,

      // variables
      ops,
      bannerOps,
      userLoggedIn,
      settings,

      // methods
      t, getTypeColor, numberWithCommas, formatDateString, getRelativeDate,
      openPetFormModal, openStickerPreQuestionModal, goToStickerDetailPage,
      updateUserLikedPlace,
      openBannerLink, openImageModal,
      checkOpenPetFormModal, checkOpenStickerPreQuestionModal,
      setSwiperInstance,
      getOrderStatusColor,
      addResizeUrlParams,

      // swiper modules
      modules: [Navigation, Pagination, Autoplay, FreeMode, IonicSlides],
    }
  }
}
</script>

<style scoped>
  .banner-img-spinner {
    position: absolute;
    top: 50%;
    left: 45%;
  }

  .pet-id-card-slide ion-img, .pet-id-card-slide img {
    /*width: 100%;*/
    height: 160px !important;
    object-fit: contain;
  }
  .pet-id-card-slide ion-button {
    margin-top: 0;
  }

  .pet-card {
    width: 100%;
    /*height: 200px;*/
    border-radius: 10px;
  }
  .pet-card img {
    max-height: 150px;
  }

  .place-card {
    margin: 0;
    box-shadow: none;
  }
  .place-logo-container {
    height: 100px;
    display: flex;
    align-items: center; 
    justify-content: center;
  }
  .img-loading-spinner {
    position: absolute;
  }
  .place-logo {
    width: 100%;
    height: 100%;
    /*box-shadow: 0 3px 5px 0;
    object-fit: cover;*/
    border-radius: 10px;
    object-fit: contain;
  }
  .place-logo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .place-name {
    font-size: 12px;
    color: lightslategrey;
  }
  .bookmark-icon {
    position: absolute;
    top: 5px;
    right: 5px;
   }

  /* 正常 220px */
  .banner-slides {
    max-height: 200px;
  }
  .banner-img {
    width: 100%;
    height: 150px !important;
    object-fit: contain;
  }

  /* 當Mon闊度 >= 768px */
  @media only screen and (min-width: 768px) {
    .banner-img { height: 600px;}
    .banner-slides { max-height: 400px; }
  }

  .flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /**
   * Landing Page
   */
  .landing-banner-img {
    border-radius: 25px;
    /*width: 100%;
    object-fit: contain;
    max-height: 250px !important;*/
  }
  /*
  @media only screen and (min-width: 768px) {
    .landing-banner-img {
      max-width: 400px !important;
      max-height: 200px !important;
    }
  }
  */
</style>