import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4647262"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { style: {"padding-top":"0"} }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.bids, (bid) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: bid.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_avatar, { slot: "start" }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: bid.bidderProfilePic || 'https://ionicframework.com/docs/demos/api/avatar/avatar.svg'
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  style: _normalizeStyle({ 'color': bid.bidderId == $props.userId ? 'red' : 'black' })
                }, [
                  _createElementVNode("small", null, _toDisplayString(bid.bidderName || `Bidder #${bid.bidderId.slice(0,4)}`), 1)
                ], 4)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createElementVNode("small", null, _toDisplayString($setup.getRelativeDate(bid.createdAt)), 1)
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ion_label, { slot: "end" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, [
                  _createElementVNode("small", null, "HK$" + _toDisplayString(bid.bidAmount), 1)
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}