
// Vue reactivity
import { ref, defineComponent, computed, onMounted } from 'vue';

// icons
import { add, close, person, personCircle, send, ellipsisVertical,
        flag, ban, notificationsOutline, ribbonOutline, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonProgressBar, IonNote,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonChip,
        IonList, IonPopover, IonGrid,
        loadingController, popoverController, } from '@ionic/vue';
import PostActionMenu from '@/components/product/PostActionMenu.vue';
import CommentUserChip from '@/components/product/CommentUserChip.vue';
import CommentContent from '@/components/product/CommentContent.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import store from '@/store';

// services
import PostService from '@/services/PostService';

export default defineComponent({
  name: 'ProductReviewListModal',
  props: ["product", "onlineViewerCount", "onlineViewers"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
                IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonProgressBar, IonNote,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonChip,
                IonList, IonPopover, IonGrid,
                CommentUserChip, CommentContent, },
  setup(props) {
    const { t } = useI18n();
    const { closeModal, formatDate, getRelativeDate, presentPrompt, presentToast, isNativeApp, } = utils();
    const newCommentContent = ref("");
    const user = computed(() => store.state.user);

    const keyboardHeight = ref(0);

    onMounted(() => {
      if (!isNativeApp()) {
        window.visualViewport.addEventListener('resize', () => {
          const MIN_KEYBOARD_HEIGHT = 290;
          const isMobile = window.innerWidth < 768;
          const isKeyboardOpen = isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
          keyboardHeight.value = isKeyboardOpen ? 290 : 0;
        })
      }
    })
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, person, personCircle, send, ellipsisVertical,
      flag, ban, notificationsOutline, ribbonOutline, arrowBack,

      // variables
      user, newCommentContent,
      keyboardHeight,

      // methods
      t, closeModal, formatDate, getRelativeDate,
      openPopover: async (post: any, ev: any) => {
        const options: any = {
          component: PostActionMenu,
          event: ev,
          componentProps: { post, },
        };
        const popover = await popoverController.create(options);
        await popover.present();
      },
      addNewComment: async () => {
        presentPrompt( t('confirmComment'), "", async () => {
          const loading = await loadingController.create({});
          await loading.present();
          const { name, row } = user.value;
          await PostService.addNewComment(props.product.value.id, newCommentContent.value, name || `User #${row}`);
          newCommentContent.value = "";
          loading.dismiss();
        });
      },
    }
  }
});
