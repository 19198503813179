
// icons
import {  } from 'ionicons/icons';

// components
import { IonText, IonIcon, IonItem, IonLabel, IonBadge, } from '@ionic/vue';

import { useI18n } from 'vue-i18n';

export default {
  props: [
    "title",
    "icon",
    "routerLink",
    "count",
    "max",
  ],
  components: { IonText, IonIcon, IonItem, IonLabel, IonBadge, },
  setup() {
    const { t } = useI18n();

    return {
      // icons

      // variables

      // methods
      t,
    }
  }
}
