
// icons
import { cart } from 'ionicons/icons';

// components
import { IonButton, IonIcon, IonBadge, IonLabel, } from '@ionic/vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: { IonButton, IonIcon, IonBadge, IonLabel, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const numOfCartItems = computed(() => store.getters.numOfCartItems);

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      cart,

      // variables
      numOfCartItems
    }
  }
}
