export const parseUserPet = (userPet) => {
  const { useRembgPhoto, rembgPhoto, croppedPhoto, photoLink } = userPet;
  if ('useRembgPhoto' in userPet) {
    userPet.useRembgPhoto = (useRembgPhoto == true || useRembgPhoto == 'Y');
  }
  userPet.cardProfilePhoto = userPet.useRembgPhoto ? rembgPhoto : (croppedPhoto || photoLink);
  return userPet;
}

export default {
  upsertUserPet(state, updatedUserPet) {
    const idx = state.userPets.findIndex(ul => ul.id == updatedUserPet.id);
    if (idx !== -1) {
      state.userPets.splice(idx, 1, parseUserPet(updatedUserPet));
    } else {
      state.userPets.push(parseUserPet(updatedUserPet));
    }
  },
  updateUserPet(state, { userPetId, updatedObj }) {
    const userPet = state.userPets.find(p => p.id == userPetId);
    if (userPet) {
      for (const key in updatedObj) userPet[key] = updatedObj[key];
    }
  },
  deleteUserPet(state, targetUserPetId) {
    const idx = state.userPets.findIndex(ul => ul.id == targetUserPetId);
    if (idx !== -1) state.userPets.splice(idx, 1);
  }
}