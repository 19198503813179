import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11532749"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-zoom-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.caption
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_swiper, {
          modules: _ctx.modules,
          zoom: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_swiper_slide, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "wrapper valign",
                  style: _normalizeStyle({ background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${_ctx.imageLink})` })
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.isPetCardImg)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          style: {"border-radius":"40px"},
                          class: "main-img",
                          src: _ctx.imageLink
                        }, null, 8, _hoisted_2))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: "main-img",
                          src: _ctx.imageLink
                        }, null, 8, _hoisted_3))
                  ])
                ], 4)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modules"])
      ]),
      _: 1
    })
  ], 64))
}