
// Vue reactivity
import { ref, defineComponent, watchEffect, onMounted } from 'vue';

// icons
import { add, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonProgressBar, IonNote,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonChip,
        modalController, loadingController, } from '@ionic/vue';
import StarRating from 'vue-star-rating';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'ProductReviewListModal',
  props: ["product"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
                IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonProgressBar, IonNote,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonChip,
                StarRating, },
  setup(props) {
    const { t } = useI18n();
    const { closeModal, formatDate, getRelativeDate, } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, closeModal, formatDate, getRelativeDate,

      // icons
      add, close,

      // variables
    }
  }
});
