
import { computed } from 'vue';

// components
import { IonGrid, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel,
        IonButton, IonCard, IonCardContent, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: [
    "orderCode"
  ],
  components: { IonGrid, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel,
                IonButton, IonCard, IonCardContent, },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, presentToast } = utils();
    
    const store = useStore();
    const settings = computed(() => store.state.settings);

    const copyText = async (text: any) => {
      navigator.clipboard.writeText(text);
      presentToast(`已複製 ${text}。`);
    }

    const getWhatsAppLink = (orderCode: any) => {
      const msg = `我的訂單編號是${orderCode || " "}，現附上付款證明如下：`;
      return `https://api.whatsapp.com/send?phone=${settings.value.paymentProofReturnPhoneNumber}&text=${msg}`;
    }

    return {
      settings,

      // methods
      t, getLocalizedStr, copyText, getWhatsAppLink,
    }
  }
}
