import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';
import { Photo } from '@/composables/usePhotoGallery';

export default {
  // Cert
  async getCertificateByUUID(uuid: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getCertificateByUUID')({ uuid })).data;
  },

  // Cache Data
  async getHomePageData() {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getHomePageData')({})).data;
  },
  async getPublicData(nonHomePageDataOnly = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getPublicData')({ nonHomePageDataOnly })).data;
  },

  // Feedback
  async createNewFeedback(feedback: any, photos: Photo[], place: any = null) {
    const photoLinks = []; // image URL
    if (photos) {
      for (const photo of photos) {
        const link = await uploadImage(photo.base64Data, feedback.name);
        photoLinks.push(link);
      }
    }
    return await firebase.app().functions('asia-east2').httpsCallable('common-createNewFeedback')({ ...feedback, photoLinks, place });
  },
  
  // Notifications
  async getAllNotifications() {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getAllNotifications')()).data;
  },
}