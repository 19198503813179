
// Vue
import { computed } from 'vue';

// icons
import { flag, ban, trashOutline, } from 'ionicons/icons';

// components
import { IonButton, IonIcon, IonBadge, IonLabel, IonList, IonItem, alertController, loadingController, popoverController, } from '@ionic/vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

import PostService from '@/services/PostService';

export default {
  props: ["post"],
  components: { IonButton, IonIcon, IonBadge, IonLabel, IonList, IonItem, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { presentToast, presentPrompt } = utils();
    const user = computed(() => store.state.user);

    const onDismiss = async () => {
      try {
        await popoverController.dismiss();
      } catch (e) {
        return false;
      }
    }
    

    const reportPost = async (post: any) => {
      // alert confirm (report objectionable content)
      // acknowledgement (will handle within 24 hours)
      const alert = await alertController.create({
        header: t('PostPage.report'),
        message: t('PostPage.confirmReport'),
        inputs: [
          {
            name: 'description',
            type: 'textarea',
            placeholder: t('PostPage.reportDescription')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('PostPage.report'),
            handler: async (value) => {
              const loading = await loadingController.create({});
              await loading.present();
              await PostService.reportComment(post.id, value.description, post.content);
              loading.dismiss();
              presentToast(t('PostPage.reportedPost'), 1000);
              onDismiss();
            },
          }
        ]
      });
      return alert.present();
    }

    const banPostAuthor = async (post: any) => {
      presentPrompt("", t('PostPage.confirmBanUser').replace("user", post.username), async() => {
        const loading = await loadingController.create({});
        await loading.present();
        await PostService.banUser(post.userId, post.id);
        loading.dismiss();
        presentToast(t('PostPage.blockedUser').replace('user', post.username), 1000);
        store.dispatch('getUserData', {});
        onDismiss();
      });
    }

    const unbanPostAuthor = async (post: any) => {
      presentPrompt("", t('PostPage.confirmUnbanUser').replace("user", post.username), async() => {
        const loading = await loadingController.create({});
        await loading.present();
        await PostService.unbanUser(post.userId);
        loading.dismiss();
        presentToast(t('PostPage.unblockedUser').replace('user', post.username), 1000);
        store.dispatch('getUserData', {});
        onDismiss();
      })
    }
    const isPostAuthorBanned = (post: any) => (post.userId == user.value.id ? false : user.value.bannedUserIds.includes(post.userId));

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      flag, ban, trashOutline,

      // variables
      user,

      // methods
      t, isPostAuthorBanned,
      banPostAuthor, unbanPostAuthor,
      reportPost,
      deleteComment: async (comment: any) => {
        presentPrompt("", t('confirmDelete'), async() => {
          const loading = await loadingController.create({});
          await loading.present();
          await PostService.deleteComment(comment.id);
          loading.dismiss();
          presentToast(t('successDelete'), 500);
          onDismiss();
        });
      }
    }
  }
}
