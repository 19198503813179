import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-baad3d0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"400px"} }
const _hoisted_2 = { class: "spin" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.caption)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.caption), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  fill: "solid",
                  color: "secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('confirm')) + " ", 1),
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.checkmark
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512), [
              [_vShow, !_ctx.loadingCropper]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_spinner)
          ], 512), [
            [_vShow, _ctx.loadingCropper]
          ]),
          _createElementVNode("img", {
            id: "main-photo",
            src: _ctx.imageLink
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", {
          id: "cropped-photo-container",
          style: _normalizeStyle([{"margin":"0 auto","overflow":"hidden"}, { height: `${_ctx.previewHeight || 100}px`, width: `${_ctx.previewWidth || 60}px` }])
        }, null, 4)
      ]),
      _: 1
    })
  ], 64))
}