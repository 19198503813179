import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"color":"#fff","line-height":"1.3"} }
const _hoisted_2 = {
  key: 1,
  slot: "end",
  style: {"padding-top":"5px","padding-right":"10px"}
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (!$setup.isNativeApp() && $setup.settings.showAppDownloadToolbar == 1)
    ? (_openBlock(), _createBlock(_component_ion_toolbar, {
        key: 0,
        color: "secondary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            size: "large",
            color: "warning",
            icon: $setup.alertCircle,
            style: {"padding-left":"10px"}
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_title, { style: {"padding-right":"0","padding-left":"10px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString($setup.getLocalizedStr($setup.settings, 'viewInAppText', 'viewInAppTextEn')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          ($setup.isMobileWebApp())
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                color: "light",
                slot: "end",
                href: $setup.isAndroid() ? $setup.settings.playStoreLink : $setup.settings.appStoreLink,
                target: "_blank",
                style: {"text-transform":"none"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.getLocalizedStr($setup.settings, 'viewInAppBtnText', 'viewInAppBtnTextEn')), 1)
                ]),
                _: 1
              }, 8, ["href"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("a", {
                  href: $setup.settings.playStoreLink,
                  target: "_blank"
                }, [
                  _createElementVNode("img", {
                    style: {"height":"50px"},
                    alt: "Get it on Google Play",
                    src: require('@/assets/Download_On_Play_Store_Badge.png')
                  }, null, 8, _hoisted_4)
                ], 8, _hoisted_3),
                _createTextVNode("   "),
                _createElementVNode("a", {
                  href: $setup.settings.appStoreLink,
                  target: "_blank"
                }, [
                  _createElementVNode("img", {
                    style: {"height":"50px"},
                    alt: "Download on the App Store",
                    src: require('@/assets/Download_on_the_App_Store_Badge_US.svg')
                  }, null, 8, _hoisted_6)
                ], 8, _hoisted_5)
              ]))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}