
import config from '@/config';
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';

export default {
  props: [
    "withText",
  ],
  setup() {
    const store = useStore();
    const settings = computed(() => store.state.settings);
    
    const { logo, logoWithText } = config;
    return { logo, logoWithText, settings, }
  }
}
