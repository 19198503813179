
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect, computed, watch, onMounted } from 'vue';

// icons
import { arrowBack, close, camera, checkmark, ellipsisHorizontal, ellipsisVertical,
        trashOutline, shareSocialOutline, newspaperOutline, createOutline, downloadOutline,
        expand, crop, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonText, IonFabButton,
        IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonSegment, IonSegmentButton,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonNote, IonModal,
        IonList, IonListHeader, IonInput, IonTextarea, IonPopover, IonDatetime, IonCheckbox, IonToggle,
        alertController, modalController, loadingController, isPlatform, } from '@ionic/vue';
import PetFormModal from '@/components/modals/PetFormModal.vue';
import PetPosterModal from '@/components/modals/PetPosterModal.vue';
import PetBreedSelectModal from '@/components/modals/PetBreedSelectModal.vue';
import PosterLostPet from '@/components/canvas/PosterLostPet.vue';
import ImageCropperModal from '@/components/modals/ImageCropperModal.vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';
import { Filesystem, Directory } from '@capacitor/filesystem';
import Konva from "konva";

// services
import PetService from '@/services/PetService';
import hanzi2ctc from '@/assets/hanzi2ctc.json';
import StickerService from '@/services/StickerService';

export default defineComponent({
  name: 'UserPetFormModal',
  props: {
    editingUserPet: null,
    targetCanvasType: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonText, IonFabButton,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonSegment, IonSegmentButton,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonNote, IonModal,
                IonListHeader, IonList, IonInput, IonTextarea, IonPopover, IonDatetime, IonCheckbox, IonToggle,
                PosterLostPet, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();

    const selectedCanvasType = ref("寵物身份證");

    const posterLostPet = ref();

    const targetPetCategory = ref("狗"); // default is dog
    const petCategories = computed(() => store.getters.petCategories);
    const groupedPets = computed(() => store.getters.groupedPets);
    const allPets = computed(() => store.state.pets);
    const settings = computed(() => store.state.settings);
    
    const userPet = reactive({
      id: null,
      breed: "",
      name: "",
      nameEn: "",
      color: "",
      gender: "",
      dateOfBirth: "",
      isNeutered: false,
      showOnBanners: true,
      origin: "",
      notes: "",
      photoLink: "",
      createdAt: new Date(),
      cardIdNumber: "",
      cropperData: "",
      useRembgPhoto: false,
      croppedPhoto: "",
      rembgPhoto: "",
    });
    const isEditing = ref(false);
    const dateInputModal = ref(null);

    // methods or filters
    const { t, locale, } = useI18n();
    const { photos, takePhoto, deletePhoto, setPhotos } = usePhotoGallery();
    const { formatDateString, presentAlert, presentToast, openModal, openInAppBrowser, presentPrompt, presentConfirm,
            loadHTMLImg, getProxyImgLink, openImageModal, } = utils();

    // ID Card
    const stageRef = ref(null);
    const sceneWidth = 300;
    const sceneHeight = 192;
    const canvas = reactive({
      loading: true,
      loadingPetImg: false,

      logoImg: null,
      idCardImg: null,
      petImg: null,
      petImgWidth: 60,
      petImgHeight: 100,
    });
    let loadCanvasTimeout: any = null;
    const LOAD_TIMEOUT = 3000;

    const getCanvasDataURL = (mimeType = "image/png", pixelRatio = 2, extraOptions = {}) => {
      return stageRef.value ? stageRef.value.getStage().toDataURL({
        mimeType: mimeType,
        quality: 0.6,
        pixelRatio,
        x: 0,
        ...extraOptions,
      }) : "";
    }
    const setCanvasLoaded = () => {
      if (loadCanvasTimeout) clearTimeout(loadCanvasTimeout);
      canvas.loading = true; // bug fix prevent empty canvas
      loadCanvasTimeout = setTimeout(() => canvas.loading = false, LOAD_TIMEOUT);
    }
    const loadImg = (imgLink: any, targetVarField: any, noLoading = false) => {
      loadHTMLImg(imgLink, (img: any) => {
        canvas[targetVarField] = img;
        if (!noLoading) setCanvasLoaded();

        if (targetVarField == 'petImg') { // sync card image to cloud DB
          setTimeout(() => {
            if (userPet.id) {
              PetService.updateUserPet({
                userPetId: userPet.id,
                updatedObj: {},
                cardDataURL: getCanvasDataURL("image/jpeg"), // JPEG for smaller size
              });
            }
          }, LOAD_TIMEOUT * 2);
        }
      });
    }
    const loadPetImg = (imgLink: any) => {
      canvas.loadingPetImg = true;
      setTimeout(() => {
        if (stageRef.value) {
          // start animation
          const targetNode = stageRef.value.getStage().findOne(`#mlpSpinner`);
          if (targetNode) {
            const anim = new Konva.Animation((frame) => {
              targetNode.rotate((frame.timeDiff * 500) / 1000);
            }, targetNode.getLayer());
            anim.start();
          }
        }

        // load the image
        loadHTMLImg(imgLink, (img: any) => {
          canvas.petImg = img;
          setTimeout(() => {
            canvas.loadingPetImg = false;
          }, 1000);
        });
      }, 100);
    }

    // Popover
    const isPopoverOpenRef = ref(false);
    const popoverEvent = ref();
    const setPopoverOpen = (state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      isPopoverOpenRef.value = state;
    }

    // Inputs / CRUD

    const saveDateOfBirth = () => {
      setTimeout(() => {
        userPet.dateOfBirth = formatDateString(userPet.dateOfBirth);
        dateInputModal.value.$el.dismiss();
      }, 500);
    }

    const upsertUserPet = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      const photoDataURL = (photos.value.length > 0 ? photos.value[0].base64Data : "");
      const res = await PetService.upsertUserPet(userPet, photoDataURL);
      console.log(res);
      loading.dismiss();
      presentToast( t('Pet.successUpdateUserPet'), 3000, 'bottom');
      
      const { id, cardIdNumber } = res;
      if (userPet.cardIdNumber == "" && id && cardIdNumber) { // card number retrieved
        userPet.cardIdNumber = cardIdNumber;
        setTimeout(() => {
          PetService.updateUserPet({
            userPetId: id,
            updatedObj: {},
            cardDataURL: getCanvasDataURL("image/jpeg"), // JPEG for smaller size
          });
        }, 500)
      }

      store.commit('upsertUserPet', res);
      
      if (!isEditing.value) {
        await modalController.dismiss({ });
        openModal(PetFormModal, { editingUserPet: res });
      }
    }

    const closeModal = async (promptLeave = false) => {
      if (promptLeave) {
        await presentPrompt("", t("confirmLeave"), async () => {
          await modalController.dismiss({ });
        });
      } else {
        await modalController.dismiss({ });
      }
    };

    const deleteUserPet = async() => {
      const alert = await alertController.create({
        header: t('confirmDelete'),
        message: t('Pet.confirmDeleteUserPet'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: t('confirmDelete'),
            handler: async () => {
              const loading = await loadingController.create({});
              await loading.present();
              const res = await PetService.deleteUserPet(userPet.id);
              loading.dismiss();
              presentToast( t('Pet.successDeleteUserPet'), 3000, 'top' );
              store.commit('deleteUserPet', userPet.id);
              closeModal();
            },
          },
        ],
      });
      return alert.present();
    }

    // Modal functions
    const openImageCropperModal = async (newPhoto = false) => {
      const modal = await modalController.create({
        component: ImageCropperModal,
        componentProps: {
          cropperData: newPhoto ? '' : userPet.cropperData,
          imageLink: photos.value[0].base64Data,
          aspectRatio: canvas.petImgWidth / canvas.petImgHeight,
          caption: t('Pet.cropIDCardProfileImage'),
        },
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data) {
          const { croppedCanvasDataURL, cropperData } = data;
          if (cropperData != userPet.cropperData) { // image cropped / changed
            userPet.cropperData = cropperData;
            userPet.croppedPhoto = croppedCanvasDataURL;
            userPet.useRembgPhoto = false;
            userPet.rembgPhoto = "";
            loadPetImg(croppedCanvasDataURL);
          }
        } else if (newPhoto) {
          setPhotos([]); // discard newly taken photos
        }
      })
      return modal.present();
    }
    let breedSelectModalOpened = false;
    const openBreedSelectModal = async () => {
      if (!breedSelectModalOpened) {
        breedSelectModalOpened = true;
        const pets = targetPetCategory.value ? allPets.value.filter((p: any) => p.category == targetPetCategory.value) : allPets.value;
        const modal = await modalController.create({
          component: PetBreedSelectModal,
          componentProps: { pets, petCategory: targetPetCategory.value },
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.selectedBreed !== null) {
            userPet.breed = data.selectedBreed.trim();
          }
          breedSelectModalOpened = false;
        })
        return modal.present();
      }
    }
    const openPetPosterModal = async (posterType: any, title = '') => {
      const pet = { ...props.editingUserPet, ...userPet };
      return await openModal(PetPosterModal, { pet, posterType, title });
    };

    // Social Sharing
    const sharePetIdImg = async () => {
      const options = {
        subject: 'Mall Lol Pet 會員身份證', // fi. for email
        files: [getCanvasDataURL()], // an array of filenames either locally or remotely
        //url: 'https://mlol.pet/go',
      };
      if (!isPlatform('ios')) {
        options['message'] = '快來看看我的 Mall Lol Pet 會員身份證 (https://mlol.pet/go)';
      }
      await SocialSharing.shareWithOptions(options);
    }
    const savePetIdCardImage = async () => {
      const dataUrl = getCanvasDataURL();
      if (isPlatform('hybrid')) {
        const loading = await loadingController.create({});
        await loading.present();
        if (isPlatform('ios')) {
          await SocialSharing.saveToPhotoAlbum([dataUrl]);
        } else {
          const fileName = `${new Date().getTime()}.jpeg`;
          const image = await Filesystem.writeFile({
            path: fileName,
            data: dataUrl,
            directory: Directory.Documents
          });
          //FileOpener.open(image.uri, "image/jpeg");
        }
        presentToast(t('saveSuccess'));
        loading.dismiss();
      }
      else {
        const a = document.createElement("a"); //Create <a>
        a.href = dataUrl;
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
      }
    }

    // INIT
    if (props.editingUserPet) { // editing user pet
      isEditing.value = true;
      watchEffect(() => {
        const { targetCanvasType, editingUserPet, } = props;
        const { id, breed, name, nameEn, color, gender, dateOfBirth, isNeutered, origin, notes,
                showOnBanners, createdAt, cardIdNumber, photoLink,
                useRembgPhoto, cropperData, croppedPhoto, rembgPhoto, } = editingUserPet;

        if (targetCanvasType) {
          selectedCanvasType.value = targetCanvasType;
        }
        setTimeout(() => {
          userPet.id = id;
          userPet.breed = breed;
          userPet.name = name;
          userPet.nameEn = nameEn;
          userPet.color = color;
          userPet.gender = gender;
          userPet.dateOfBirth = formatDateString(dateOfBirth, "YYYY-MM-DD");
          userPet.isNeutered = isNeutered;
          userPet.showOnBanners = showOnBanners;
          userPet.origin = origin;
          userPet.notes = notes;
          userPet.photoLink = photoLink || "";
          userPet.createdAt = createdAt;
          userPet.cardIdNumber = cardIdNumber;
          userPet.cropperData = cropperData;
          userPet.useRembgPhoto = useRembgPhoto;
          userPet.croppedPhoto = croppedPhoto;
          userPet.rembgPhoto = rembgPhoto;
          
          // Load image for Pet ID card
          if (photoLink) {
            loadImg(useRembgPhoto ? rembgPhoto : (croppedPhoto || photoLink), 'petImg');
            setPhotos([{ base64Data: photoLink }]);
          }
        }, 500);
      })
    }

    watch(photos, async (currPhotos: any) => {
      if (currPhotos.length > 0) {
        if (currPhotos[0].base64Data.startsWith('data:image')) {
          openImageCropperModal(true);
        }
      } else {
        userPet.photoLink = "";
        canvas.petImg = null;
      }
    })

    const handleStageMouseDown = async (e: any) => {
      return;
    }
    //loadImg(require('@/assets/template_pet_id_card.jpg'), 'idCardImg');
    loadImg(require('@/assets/template_pet_id_card.png'), 'idCardImg');
    loadImg(require('@/assets/logo.png'), 'logoImg');

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, close, camera, checkmark, ellipsisHorizontal, ellipsisVertical,
      shareSocialOutline, newspaperOutline, trashOutline, createOutline, downloadOutline,
      expand, crop,

      // variables
      isEditing,
      dateInputModal,
      petCategories, groupedPets, allPets,
      userPet, targetPetCategory,
      photos,
      stageRef,
      sceneWidth, sceneHeight,
      canvas,
      isPopoverOpenRef, popoverEvent,
      locale, settings,
      
      selectedCanvasType,
      posterLostPet,

      // methods
      t, formatDateString, closeModal,
      upsertUserPet, deleteUserPet,

      openImageModal, openImageCropperModal,
      takePhoto, deletePhoto, setPhotos,
      handleStageMouseDown, sharePetIdImg,
      getProxyImgLink, savePetIdCardImage, getCanvasDataURL,
      saveDateOfBirth,

      openBreedSelectModal, openPetPosterModal,
      setPopoverOpen, 

      getChineseComCode: (text: any) => (text.split('').map(c => (hanzi2ctc[c] || '')).join(" ")),
      setUseRemovedBgPhoto: async (checked: boolean) => {
        userPet.useRembgPhoto = checked;
        if (checked && userPet.rembgPhoto == '') {
          const loading = await loadingController.create({ duration: 30000 });
          await loading.present();
          try {
            userPet.rembgPhoto = await StickerService.removeBgFromImg(canvas.petImg.src);
          } catch (e) {
            console.error(e);
          } finally {
            loading.dismiss();
            if (userPet.rembgPhoto == '') {
              const el: any = document.querySelector('#rembg-toggle');
              el.checked = false;
              userPet.useRembgPhoto = false;
            }
          }
        }
        loadPetImg(userPet.useRembgPhoto ? userPet.rembgPhoto : (userPet.croppedPhoto || userPet.photoLink));
      },
    }
  }
});
