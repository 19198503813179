
// Vue reactivity
import { defineComponent } from 'vue';

// icons
import { close, arrowBack } from 'ionicons/icons';

// components
import { IonContent, IonButtons, IonButton, IonIcon, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';
import ThankYouOrderDetails from '@/components/order/ThankYouOrderDetails.vue';

// composables
import { useI18n } from 'vue-i18n';
        
export default defineComponent({
  name: 'ThankYouOrderModal',
  props: ["orderId"],
  components: { IonContent, IonButtons, IonButton, IonIcon,
                LogoImg, ThankYouOrderDetails, },
  setup() {
    const { t, } = useI18n();
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      
      // variables
      
      // methods
      t,
    }
  }
});
