import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { fixed: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, { color: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t('orderInfo')), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.order.items, (item) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: item.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_thumbnail, {
                  slot: "start",
                  onClick: ($event: any) => ($setup.openImageModal(item.previewPhotoLink, $setup.getLocalizedStr(item, 'productTitle', 'productTitleEn')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      style: {"object-fit":"contain"},
                      src: item.previewPhotoLink
                    }, null, 8, _hoisted_1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", null, [
                      _createTextVNode(_toDisplayString(item.quantity) + " x " + _toDisplayString($setup.getLocalizedStr(item, 'productTitle', 'productTitleEn')) + " ", 1),
                      (item.productSize)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.productSize), 1))
                        : _createCommentVNode("", true)
                    ]),
                    (item.lineTotal != null)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "HK$" + _toDisplayString(item.lineTotal.toLocaleString()), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                (item.certificatePhotoLink)
                  ? (_openBlock(), _createBlock(_component_ion_buttons, {
                      key: 0,
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          color: "success",
                          class: "no-text-transform",
                          fill: "solid",
                          onClick: ($event: any) => ($setup.shareImage(item.certificatePhotoLink))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: $setup.shareSocialOutline
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString($setup.t('shareCertificate')), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  ($props.order.subtotal != null)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.t('CheckoutPage.subTotal')) + "HK$" + _toDisplayString($props.order.subtotal.toLocaleString()), 1))
                    : _createCommentVNode("", true),
                  ($props.order.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.t('CheckoutPage.discountTotal')) + "-HK$" + _toDisplayString($props.order.discountTotal.toLocaleString()) + " (" + _toDisplayString($props.order.appliedDiscountCode) + ")", 1))
                    : _createCommentVNode("", true),
                  ($props.order.totalPrice != null)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_6, [
                        _createElementVNode("b", null, _toDisplayString($setup.t('CheckoutPage.orderTotal')) + "HK$" + _toDisplayString($props.order.totalPrice.toLocaleString()), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, { color: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t('deliveryInfo')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString($setup.t('deliveryMethod')) + " " + _toDisplayString($props.order.deliveryMethod), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString($setup.t('deliveryAddress')) + _toDisplayString($props.order.deliveryAddress), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString($setup.t('customerName')) + _toDisplayString($props.order.customerName), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString($setup.t('contactPhone')) + _toDisplayString($props.order.contactPhone), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString($setup.t('customerEmail')) + _toDisplayString($props.order.customerEmail), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}