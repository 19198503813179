// PET PLACES
export const getPlaceById = (state) => (id) => {
  return state.places.find(place => place.id == id) || {};
}
export const getPlacesByIds = (state) => (ids) => {
  return state.places.filter(place => ids.includes(place.id));
}
export const getPlacesByType = (state) => (type) => {
  return state.places.filter(place => place["種類"] == type);
}
export const userLikedPlaces = (state) => {
  return state.places.filter(place => place.likedByUser);
}
export const userRecentBrowsedPlaces = (state) => {
  const { relatedUserBrowsedPlaces } = state.user;
  if (relatedUserBrowsedPlaces) {
    return relatedUserBrowsedPlaces.split(" , ").map(userPlaceId => {
      return state.places.find(place => userPlaceId.split(": ")[1] == place.id);
    }).filter(place => !!place);
  }
  return [];
}