import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abb3eb20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "section-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return ($props.routerLink)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        lines: "none",
        style: {"margin-top":"10px","color":"var(--ion-color-secondary)"},
        "router-link": $props.routerLink,
        button: "",
        detail: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: $props.icon
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString($props.title), 1)
            ]),
            _: 1
          }),
          ($props.count != null && $props.count >= 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                ($props.count / $props.max < 0.3)
                  ? (_openBlock(), _createBlock(_component_ion_badge, {
                      key: 0,
                      color: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.count) + " / " + _toDisplayString($props.max), 1)
                      ]),
                      _: 1
                    }))
                  : ($props.count / $props.max < 1)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 1,
                        color: "warning"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($props.count) + " / " + _toDisplayString($props.max), 1)
                        ]),
                        _: 1
                      }))
                    : ($props.count / $props.max >= 1)
                      ? (_openBlock(), _createBlock(_component_ion_badge, {
                          key: 2,
                          color: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($props.count) + " / " + _toDisplayString($props.max), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["router-link"]))
    : (_openBlock(), _createBlock(_component_ion_item, {
        key: 1,
        lines: "none",
        style: {"color":"var(--ion-color-secondary)"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: $props.icon
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}