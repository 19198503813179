<template>
  <page-header :showCloseModal="true" :title="showUserDiscountsOnly ? t('yourCoupons') : t('useCoupon')">
  </page-header>

  <ion-content>
    <ion-grid fixed>

      <div class="ion-padding" v-if="!showUserDiscountsOnly">
        <ion-input class="ion-margin-vertical" :label="t('enterCouponCode')" label-placement="floating"
                    fill="outline" v-model="discountCode" :clear-input="true"></ion-input>

        <ion-button expand="block" @click="checkCouponCode(discountCode)">
          {{ t('apply') }}
        </ion-button>
      </div>

      <ion-list v-if="userDiscounts.length > 0">
        <div class="ion-text-center ion-margin-bottom" v-if="!showUserDiscountsOnly">
          <h3>{{ t('yourCoupons')}}</h3>
        </div>

        <ion-card v-for="discount in userDiscounts" :key="discount.code">
          <ion-item lines="none">
            <ion-icon slot="start" :icon="discount.productId ? giftOutline : pricetagOutline"></ion-icon>
            <ion-label>
              <h2>
                <b v-if="!discount.productId">
                  {{ discount.type == 'percentage' ? `${discount.percentageValue*100}% OFF` : `HK$${discount.fixedAmountValue}` }}
                </b>
                <b v-else>
                  {{ t('free') }}
                </b>
              </h2>
              <p>{{ discount.description || discount.code }}</p>
              <small>{{ t('expiryDate') }}: {{ discount.expiryDate }}</small>
            </ion-label>

            <!-- Use Coupon Button -->
            <ion-buttons slot="end" v-if="!showUserDiscountsOnly && !discount.productId">
              <ion-button @click="checkCouponCode(discount.code)">
                {{ t('apply') }}
              </ion-button>
            </ion-buttons>

            <!-- View Gift Details Button -->
            <ion-buttons slot="end" v-if="showUserDiscountsOnly && discount.productId">
              <ion-button @click="goToProductDetailPage(discount.productId)">
                {{ t('details') }}
              </ion-button>
            </ion-buttons>

          </ion-item>
        </ion-card>
      </ion-list>
    </ion-grid>
  </ion-content>
</template>

<script>
// Vue reactivity
import { computed, defineComponent, ref, } from 'vue';

// icons
import { arrowBack, pricetagOutline, giftOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonInput, IonCard,
        loadingController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// services
import OrderService from '@/services/OrderService';

export default defineComponent({
  name: 'UseCouponModal',
  props: ["showUserDiscountsOnly", "productIds", "productCategoryIds"],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent,
                IonGrid, IonList, IonItem, IonLabel, IonInput, IonCard, },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();
    const { closeModal, presentAlert, } = utils();

    const discountCode = ref("");
    const userDiscounts = computed(() => store.state.userDiscounts);

    return {
      // icons
      arrowBack, pricetagOutline, giftOutline,

      // variables
      discountCode, userDiscounts,

      // methods
      t, closeModal,
      checkCouponCode: async (couponCode) => {
        const { productIds, productCategoryIds } = props;
        if (couponCode) {
          const loading = await loadingController.create({ duration: 60000 });
          await loading.present();
          const res = await OrderService.getDiscount(couponCode, productIds, productCategoryIds);
          loading.dismiss();
          console.log(res);
          if (res) {
            closeModal({ discount: res });
          } else {
            presentAlert(t('CheckoutPage.invalidDiscountCode'));
          }
        }
      },
      goToProductDetailPage: async (productId) => {
        await closeModal();
        router.push(`/products/${productId}`);
      }
    }
  }
})
</script>

<style scoped>
</style>