
// icons
import { downloadOutline, shareSocialOutline, } from 'ionicons/icons';

// components
import { IonGrid, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel,
        IonButtons, IonButton, IonIcon, IonCard, IonCardContent, IonText, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "order"
  ],
  components: { IonGrid, IonList, IonListHeader, IonItem, IonThumbnail, IonLabel,
                IonButtons, IonButton, IonIcon, IonCard, IonCardContent, IonText, },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, getOrderStatusColor, formatDateString, openImageModal, shareImage } = utils();

    return {
      // icons
      downloadOutline, shareSocialOutline,

      // methods
      t, getLocalizedStr, getOrderStatusColor, formatDateString, openImageModal, shareImage,
    }
  }
}
