import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a05f80e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.withText)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "logo-text-img",
        src: $setup.logoWithText
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "logo-img",
        src: $setup.settings.logoImgLink || $setup.logo
      }, null, 8, _hoisted_2))
}