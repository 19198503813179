const placeHolderLogo = require('@/assets/no_image.jpeg');

export default {
  // Comments
  upsertProductComment(state, { productId, comment }) {
    const product = state.products.find(p => p.id == productId);
    if (product) {
      product.comments = product.comments || [];
      const idx = product.comments.findIndex(c => c.id == comment.id);
      if (idx !== -1) {
        product.comments[idx] = {
          ...product.comments[idx],
          ...comment,
        }
      } else {
        product.comments.push(comment);
      }
    }
  },
}