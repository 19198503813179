
// Vue
import { computed, ref } from 'vue';

// icons
import { heart, heartOutline, cartOutline } from "ionicons/icons";

// components
import { IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
          IonChip, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonText, IonAvatar,
          loadingController, modalController, } from "@ionic/vue";
import ProductModal from '@/components/product/ProductModal.vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment from 'moment';

// services
import ProductService from '@/services/ProductService';

export default {
  props: [
    "product",
    "hidePrice",
    "hideMerchantName",
    "userLoggedIn",
    "showActionButtons",
    "isScrolling",
    "winningPercentage",
  ],
  components: {
    IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
    IonChip, IonLabel, IonIcon, IonButtons, IonButton, IonSpinner, IonText, IonAvatar,
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.state.user);
    const cartItems = computed(() => store.state.cartItems);
    const settings = computed(() => store.state.settings);
    const imgLoaded = ref(false);

    const router = useRouter();
    const { t, locale, } = useI18n();
    const { presentToast, PRODUCT_STATUSES, addResizeUrlParams, getLocalizedStr, openModal, } = utils();

    const addProductToCart = async (product: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('addProductToCart', { product });
      loading.dismiss();
      presentToast( t('successAddedToCart'), 2000, 'top' );
    }
    const updateUserLikedItem = async (product: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') await ProductService.addUserLikedItem(product.id);
      else await ProductService.removeUserLikedItem(product.id);
      product.likedByUser = (action == 'add');
      loading.dismiss();
      presentToast( t('successUpdateLikedItems'), 2000, 'top' );
    }

    const getTimeDiffText = (time: any) => {
      const timeLeft = moment.duration(moment(time).diff(moment()));
      const hours = Math.floor(timeLeft.asHours());
      const minutes = Math.floor(timeLeft.asMinutes() % 60);
      if (hours <= 0) {
        return locale.value == 'zh' ? `${minutes}分鐘` : `${minutes} min${minutes > 1 ? 's' : ''}`;
      }
      return locale.value == 'zh' ? `${hours}小時` : `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    const handleClick = (event) => {
      const { isScrolling, product, hidePrice } = props;
      if (isScrolling) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        /* Your click handling code here */
        if (product.type == 'Game') {
          router.push(`/lucky-wheel/${product.id}`)
        } else {
          if (hidePrice) openModal(ProductModal, { id: product.id, hidePrice });
          else router.push({ name: 'ProductDetailPage', params: { id: product.id }, state: { hidePrice } });
        }
      }
    };

    const handleTouchEnd = (event) => {
      // If scrolling, prevent click
      if (props.isScrolling) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    return {
      // icons
      cartOutline, heart, heartOutline,

      // variables
      user, cartItems,
      PRODUCT_STATUSES,
      imgLoaded,
      settings,

      // methods
      t, addProductToCart, updateUserLikedItem,
      addResizeUrlParams,

      // Auction
      getAuctionTimeStatus: (auction: any) => {
        const { startTime, endTime } = auction;
        if (new Date() < new Date(startTime)) return 'starting-soon';
        if (new Date() >= new Date(startTime) && new Date() <= new Date(endTime)) return 'started';
        return 'ended';
      },
      getAuctionTimeDiffText: (auction: any) => {
        const { startTime, endTime } = auction;
        if (new Date() < new Date(startTime)) {
          return locale.value == 'zh' ? `${getTimeDiffText(startTime)}後開始` : `In ${getTimeDiffText(startTime)}`;
        }
        if (new Date() >= new Date(startTime) && new Date() <= new Date(endTime)) {
          return locale.value == 'zh' ? `尚餘${getTimeDiffText(endTime)}` : `${getTimeDiffText(endTime)} left`;
        }
        return '';
      },
      handleClick, handleTouchEnd,
      getLocalizedStr,
    };
  },
};
