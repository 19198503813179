
// Vue
import { computed } from 'vue';

// components
import { IonList, IonItem, IonLabel, IonAvatar, } from "@ionic/vue";
import { utils } from '@/composables/utils';

export default {
  props: [
    "bids", "userId"
  ],
  components: {
    IonList, IonItem, IonLabel, IonAvatar,
  },
  setup() {
    const { getRelativeDate } = utils();
    return {
      getRelativeDate
    };
  }
};
