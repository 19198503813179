
// Vue reactivity
import { ref, defineComponent, watchEffect, onMounted } from 'vue';

// components
import ProductDetailPage from '@/pages/coral/product/ProductDetailPage.vue';

export default defineComponent({
  name: 'ProductModal',
  props: ["id", "hidePrice"],
  components: { ProductDetailPage, },
});
