
import { computed } from 'vue';

// icons
import { alertCircle } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
        IonBackButton, IonIcon, IonTitle, isPlatform, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  props: [],
  components: { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
                IonBackButton, IonIcon, IonTitle, LogoImg },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, isMobileWebApp, isAndroid, isNativeApp, } = utils();

    const store = useStore();
    const settings = computed(() => store.state.settings);

    return {
      // icons
      alertCircle,

      // variables
      settings,

      // methods
      t, getLocalizedStr,
      isNativeApp, isMobileWebApp, isAndroid,
    }
  }
}
