// PET INFOS
export const userLikedInfos = (state) => {
  return state.infos.filter(info => info.likedByUser);
}
export const userRecentBrowsedInfos = (state) => {
  const { relatedUserBrowsedInfos } = state.user;
  if (relatedUserBrowsedInfos) {
    return relatedUserBrowsedInfos.split(" , ").map(userInfoId => {
      return state.infos.find(info => userInfoId.split(": ")[1] == info.id);
    }).filter(info => !!info);
  }
  return [];
}
export const placeTypesWithoutAll = (state) => {
  return state.placeTypes.filter(type => type.title != '全部');
}