import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  async updateUserSubscribedAuction(auctionId: any, action: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('auction-updateUserSubscribedAuction')({ auctionId, action })).data;
  },

  async placeBid(productId: any, auctionId: any, bidAmount: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('auction-placeBid')({ productId, auctionId, bidAmount })).data;
  },
}