
// Vue reactivity
import { computed, inject, reactive, ref, watch } from "vue";

// icons
import { star, starHalfOutline, starOutline, pencil, heart, heartOutline,
        cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline, shareSocialOutline, shareSocial,
        removeCircleOutline, trash, closeCircleOutline, eyeOutline, eye,
        thumbsUp, thumbsUpOutline, notificationsOff, notificationsOutline, person, } from "ionicons/icons";

// components
import { IonPage, IonToolbar, IonContent, IonFooter,
        IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton, IonList,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
        IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
        IonRefresher, IonRefresherContent, IonSkeletonText, IonText,
        loadingController, modalController,
        onIonViewDidEnter, onIonViewWillLeave, } from "@ionic/vue";
import ProductReviewModal from '@/components/product/ProductReviewModal.vue';
import ProductReviewListModal from '@/components/product/ProductReviewListModal.vue';
import CommentListModal from '@/components/product/CommentListModal.vue';
import CheckoutModal from '@/components/order/CheckoutModal.vue';
import PlaceBidModal from '@/components/product/PlaceBidModal.vue';
import TermsAndConditionsModal from '@/components/modals/TermsAndConditionsModal.vue';
import ImageSlides from '@/components/slides/ImageSlides.vue';
import SectionHeader from "@/components/SectionHeader.vue";
import Slides from "@/components/slides/Slides.vue";
import CartButton from "@/components/CartButton.vue";
import AuctionBidList from "@/components/product/AuctionBidList.vue";
import StarRating from 'vue-star-rating';
import CommentUserChip from '@/components/product/CommentUserChip.vue';
import CommentContent from '@/components/product/CommentContent.vue';

// API services
import ProductService from '@/services/ProductService';
import AuctionService from '@/services/AuctionService';

// Composables
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import clip from "text-clipper";

import { SupabaseClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: ["id", "showCloseModal"],
  name: "ProductDetailPage",
  components: {
    IonPage, IonToolbar, IonContent, IonFooter,
    IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton, IonList,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
    IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
    IonRefresher, IonRefresherContent, IonSkeletonText, IonText,
    ImageSlides, SectionHeader, Slides, CartButton, AuctionBidList,
    StarRating, CommentUserChip, CommentContent,
  },
  setup(props) {
    // Supabase
    const supabase: SupabaseClient = inject('supabase');

    // methods or filters
    const store = useStore();
    const { t, locale } = useI18n();
    const { formatDate, formatDateTime, presentToast, openSocialShare, openModal, openImageModal, getLocalizedStr,
            getTimeDiffText, convertKeysToCamelCase, presentAlert, openLoginModal, PRODUCT_STATUSES,
            isMobileWebApp, isNativeApp, addResizeUrlParams, getRelativeDate, } = utils();
    const route = useRoute();
    const parentPath = route.params.parentPath; // may be from liked items page
    const currProductId = props.id || route.params.id;
    const hidePrice = props.showCloseModal || history.state.hidePrice; // route paramters (mainly ID)

    // Online users (real-time subscription)
    const onlineViewerCount = ref(0);
    const onlineViewers = ref([]);

    // 1. declare state variables (ref to make them reactive) / methods
    const user = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingProducts);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const settings = computed(() => store.state.settings);
    const product = computed(() => store.getters.getProductById(currProductId));
    const relatedProducts = computed(() => store.getters.getRelatedProducts(currProductId)); // related products
    const relatedCartItem = computed(() => store.getters.getCartItem(currProductId)); // cart item
    const currentTime = ref(new Date());
    let countdownInterval = null;
    let placeBidModal = null;

    const selectedProductVariant = ref<any>({});
    const cartItemQty = ref(1);

    // Read more (descriptions)
    const maxDescriptionLen = computed(() => store.state.settings.maxDescriptionLen || 300); // threshold for showing read more button
    const showClippedText = reactive({
      productIntro: true,
    });
    const isHtmlOverTextLenLimit = (htmlString: any) => (htmlString || "").replace(/(<([^>]+)>)/ig, "").length > maxDescriptionLen.value;
    const clipHtmlText = (htmlString: any) => clip(htmlString, maxDescriptionLen.value, { html: true });

    // Auth guard
    const checkLoggedIn = () => {
      if (userLoggedIn.value) return true;
      openLoginModal();
      return false;
    }
      
    const addProductToCart = async (product: any, variant: any) => {
      if (checkLoggedIn()) {
        const loading = await loadingController.create({});
        await loading.present();
        store.dispatch('addProductToCart', { product, addQty: cartItemQty.value, variant });
        loading.dismiss();
        presentToast( t('successAddedToCart'), 1000, 'top' );
      }
    }
    const removeProductFromCart = async (product: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('removeProductsFromCart', { products: [product] });
      loading.dismiss();
      presentToast( t('successRemovedFromCart'), 1000, 'top' );
    }
    const updateUserLikedItem = async (product: any, action = 'add') => {
      if (checkLoggedIn()) {
        const loading = await loadingController.create({});
        await loading.present();
        if (action == 'add') {
          ProductService.addUserLikedItem(product.id);
          product.numOfLikes++;
        } else {
          ProductService.removeUserLikedItem(product.id);
          product.numOfLikes--;
        }
        product.likedByUser = (action == 'add');
        store.commit("updateUserLikedItem", { product, action });
        loading.dismiss();
        presentToast( t('successUpdateLikedItems'), 1000, 'bottom' );
      }
    }

    // Product Reviews
    const getProductUserReview = () => {
      return product.value.reviews?.find(r => r.userId == user.value.id);
    }
    const openProductReviewModal = async (prefilledRating = null) => {
      const modal = await modalController.create({
        component: ProductReviewModal,
        componentProps: { product: product.value, prefilledRating, userReview: getProductUserReview() },
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data && data.newProductReviewCreated) {
          store.dispatch('fetchProductDetails', { id: product.value.id });
        }
      })
      return modal.present();
    };

    if (userLoggedIn.value && currProductId) {
      store.dispatch('addUserBrowsedProduct', currProductId);
    }

    //const channel = supabase.channel('any');
    let channel;
    const userStatus = { uid: uuidv4(), name: "Guest User", online_at: new Date().toISOString() };

    const subscribeSupabaseTables = () => {
      if (channel) return; // already subscribed

      setTimeout(() => {
        const { id: productId, auction } = product.value;
        channel = supabase.channel(productId);

        if (userLoggedIn.value) {
          const { id, name, row } = user.value;
          userStatus.uid = id;
          userStatus.name = name || `User #${row}`;
        }
        
        // On new bid
        const handleNewBid = (payload) => {
          console.log('New bid received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          const bid = convertKeysToCamelCase(payload.new);
          if (bid.status == 'success') {
            store.commit('insertAuctionBid', { productId, bid });
          }
        }
        // On auction updated
        const handleUpdatedAuction = (payload) => {
          console.log('Updated auction received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          store.commit('updateAuction', { productId, updatedAuction: convertKeysToCamelCase(payload.new) });
        }
        // On product updated
        const handleUpdatedProduct = (payload) => {
          console.log('Updated product received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          store.commit('upsertProducts', [convertKeysToCamelCase(payload.new)]);
        }
        // On new / updated product comment
        const handleUpdatedComment = (payload) => {
          console.log('Updated comment received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          const comment = convertKeysToCamelCase(payload.new);
          if (comment) store.commit('upsertProductComment', { productId, comment });
        }
        if (auction) {
          // Subscribe to auctions & bids as well
          channel
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'auction_bids', filter: `auction_id=eq.${auction.id}` }, handleNewBid)
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'auctions', filter: `id=eq.${auction.id}` }, handleUpdatedAuction)
            .on('postgres_changes', { event: '*', schema: 'public', table: 'comments', filter: `product_id=eq.${productId}` }, handleUpdatedComment)
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'products', filter: `id=eq.${productId}` }, handleUpdatedProduct)
            .on('presence', { event: 'sync' }, () => {
              // Track online viewers & update count
              const newState = channel.presenceState();
              //console.log('sync', newState);
              onlineViewerCount.value = Object.keys(newState).length;
              onlineViewers.value = Object.values(newState).map((s: any) => (s[0] || {}));
            })
            .subscribe(async (status) => {
              if (status !== 'SUBSCRIBED') { return }
              const presenceTrackStatus = await channel.track(userStatus) // send state (join)
              //console.log(presenceTrackStatus)
            })
        } else {
          // Subscribe only to product change
          channel
            .on('postgres_changes', { event: '*', schema: 'public', table: 'comments', filter: `product_id=eq.${productId}` }, handleUpdatedComment)
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'products', filter: `id=eq.${productId}` }, handleUpdatedProduct)
            .on('presence', { event: 'sync' }, () => {
              // Track online viewers & update count
              const newState = channel.presenceState();
              //console.log('sync', newState);
              onlineViewerCount.value = Object.keys(newState).length;
              onlineViewers.value = Object.values(newState).map((s: any) => (s[0] || {}));
            })
            .subscribe(async (status) => {
              if (status !== 'SUBSCRIBED') { return }
              const presenceTrackStatus = await channel.track(userStatus) // send state (join)
              //console.log(presenceTrackStatus)
            })
        }
      }, 1000);
    }
    const getAuctionTimeStatus = () => {
      if (product.value && product.value.auction) {
        const { startTime, endTime } = product.value.auction;
        if (currentTime.value < new Date(startTime)) return 'starting-soon';
        if (currentTime.value >= new Date(startTime) && currentTime.value <= new Date(endTime)) return 'started';
        return 'ended';
      }
    }

    const fetchProductDetails = (e: any = null) => {
      //store.dispatch('fetchProductDetails', { id: product.value.id, e });
      store.dispatch('fetchProductDetails', { id: currProductId, e });
    }
    const startAuctionCountdownTimer = () => {
      if (getAuctionTimeStatus() != "ended") {
        if (countdownInterval == null) {
          countdownInterval = setInterval(() => {
            currentTime.value = new Date();
            
            if (getAuctionTimeStatus() == "ended") {
              if (placeBidModal) placeBidModal.dismiss();
              presentAlert(t('auctionTimesupAlert'), false, null, t('timesup'));
              clearInterval(countdownInterval);
              countdownInterval = null;
            }
          }, 1000);
        }
      }
    }

    onIonViewDidEnter(() => {
      if (product.value.id) {
        subscribeSupabaseTables();
        if (!product.value.fetchedDetails) fetchProductDetails(); // fetch product details
        startAuctionCountdownTimer();
      }
    });

    onIonViewWillLeave(() => {
      if (countdownInterval) clearInterval(countdownInterval);
      if (channel) channel.unsubscribe(); // leave the channel
    });

    watch(product, (currProduct: any) => {
      subscribeSupabaseTables(); // When directly access the page
      if (!product.value.fetchedDetails) fetchProductDetails(); // fetch product details
      startAuctionCountdownTimer();
    });

    window.addEventListener('beforeunload', (event) => {
      // Logic to handle tab closing
      if (channel) channel.unsubscribe(); // leave the channel
      return;
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      star, starHalfOutline, starOutline, pencil, heart, heartOutline,
      cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline, shareSocialOutline, shareSocial,
      removeCircleOutline, trash, closeCircleOutline, eyeOutline, eye,
      thumbsUp, thumbsUpOutline, notificationsOff, notificationsOutline, person,

      // variables
      locale, loading, settings,
      user, userLoggedIn, parentPath,
      product, cartItemQty,
      selectedProductVariant,
      relatedProducts,
      relatedCartItem,
      onlineViewerCount,
      hidePrice, // for presenting game prize

      maxDescriptionLen, showClippedText,
      clipHtmlText, isHtmlOverTextLenLimit,

      // methods
      isMobileWebApp, addResizeUrlParams,
      t, getLocalizedStr,
      formatDate, formatDateTime,
      addProductToCart, removeProductFromCart, updateUserLikedItem,

      // Product Reviews
      openProductReviewModal, getProductUserReview,
      openProductReviewListModal: async () => {
        return await openModal(ProductReviewListModal, { product: product.value }, false, '', true);
      },

      // Comments / Live Chat
      openCommentListModal: async () => {
        if (checkLoggedIn()) {
          return await openModal(CommentListModal, { product, onlineViewerCount, onlineViewers }, true, 'comment-list-modal', true, 1, true);
        }
      },

      // Action buttons
      shareProduct: async (product: any) => {
        //const sharingMsg = `${getLocalizedStr(product, 'title', 'titleEn')} | ${window.location.href}`;
        const sharingUrl = `https://mlol.pet${window.location.pathname}`;
        const sharingMsg = `${getLocalizedStr(product, 'title', 'titleEn')} | ${sharingUrl}`;
        openSocialShare(sharingMsg, sharingUrl);
      },
      enquireProduct: async (product: any) => {
        //const sharingMsg = `您好，想查詢這個產品：${getLocalizedStr(product, 'title', 'titleEn')} | ${window.location.href}`;
        const sharingMsg = `您好，想查詢這個產品：${getLocalizedStr(product, 'title', 'titleEn')} | https://mlol.pet${window.location.pathname}`;
        const url = `https://wa.me/${settings.value.enquiryPhoneNumber}?text=${encodeURIComponent(sharingMsg)}`
        window.open(url, '', 'width=600,height=400');
      },
      openCheckoutModal: async (products: any) => {
        if (checkLoggedIn()) {
          await openModal(CheckoutModal, { products }, true, '', true);
        }
      },
      openImageModal,

      PRODUCT_STATUSES,
      isProductAvailable: () => {
        const { status, reservedBy } = product.value;
        return status == PRODUCT_STATUSES.available || (status == PRODUCT_STATUSES.hold && reservedBy == user.value.id);
      },
      fetchProductDetails,

      // Auctions
      currentTime,
      getTimeDiffText,
      getAuctionTimeStatus, getRelativeDate,
      userIsBidder: (auction: any) => {
        const bidderIds = auction.bids.map(b => b.bidderId);
        return bidderIds.includes(user.value.id);
      },
      getNumBidders: (auction: any) => {
        const bidderIds = (auction.bids || []).map(b => b.bidderId);
        return [...new Set(bidderIds)].length;
      },
      openPlaceBidModal: async () => {
        if (checkLoggedIn()) {
          placeBidModal = await openModal(PlaceBidModal, {
            product: product.value, userId: user.value.id, onlineViewerCount,
          }, false, '', true);
          /*
          if (isNativeApp()) {
            placeBidModal = await openModal(PlaceBidModal, { product: product.value, userId: user.value.id }, false, '', true);
          } else {
            presentAlert(getLocalizedStr(settings.value, 'viewInAppText', 'viewInAppTextEn'));
          }
          */
        }
      },
      openAuctionTCModal: async () => await openModal(TermsAndConditionsModal, { type: 'auction' }),

      updateUserSubscribedAuction: async (auctionId: any, action: any) => {
        const loading = await loadingController.create({});
        await loading.present();
        AuctionService.updateUserSubscribedAuction(auctionId, action);
        store.commit("updateUserSubscribedAuction", { auctionId, action });
        loading.dismiss();
      },

      // Comments
      isShowCommentSection: () => {
        const { showCommentSection, showCommentOnAuctionOnly } = settings.value;
        return showCommentOnAuctionOnly == 1 ? product.value.auction && showCommentSection == 1
                                              : showCommentSection == 1;
      },
    };
  },
};
