import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78a65c5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-text-center ion-margin-bottom" }
const _hoisted_2 = {
  class: "ion-padding",
  style: {"max-width":"300px","margin":"0 auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_star_rating = _resolveComponent("star-rating")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('coralReview')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_product_card, {
          product: _ctx.product,
          hideDetails: "true"
        }, null, 8, ["product"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.t('yourRating')), 1),
          _createVNode(_component_star_rating, {
            "star-size": 32,
            padding: 5,
            inline: true,
            rating: _ctx.rating,
            "onUpdate:rating": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rating) = $event))
          }, null, 8, ["rating"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_textarea, {
            fill: "outline",
            label: _ctx.t('ProductPage.reviewContent'),
            rows: 5,
            "label-placement": "stacked",
            modelValue: _ctx.content,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.content) = $event))
          }, null, 8, ["label", "modelValue"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              expand: "block",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.upsertProductReview(_ctx.product.id, _ctx.rating, _ctx.content))),
              disabled: _ctx.userReview ? (_ctx.rating == _ctx.userReview.rating && _ctx.content == _ctx.userReview.content) : !_ctx.rating
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.userReview ? _ctx.t('ProductPage.updateReview') : _ctx.t('ProductPage.publishReview')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}