import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  // User Browsed Infos History
  async addUserBrowsedInfo(infoId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('info-addUserBrowsedInfo')({ infoId });
  },
  async getUserBrowsedInfos() {
    return (await firebase.app().functions('asia-east2').httpsCallable('info-getUserBrowsedInfos')()).data;
  },

  // User Liked Infos
  async addUserLikedInfo(infoId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('info-addUserLikedInfo')({ infoId });
  },
  async removeUserLikedInfo(infoId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('info-removeUserLikedInfo')({ infoId });
  },
}