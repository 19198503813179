//const placeHolderLogo = require('@/assets/no_image.jpeg');
const placeHolderLogo = require('@/assets/place_logo_footprint.png');

export const parsePlace = (place, user, skipLikedByUser = false) => {
  place.permClosed = place['已結業?'] == 'Y';
  place.mainImgLink = place['Image Link 1'] || placeHolderLogo;
  place.yearFounded = (place['成立年份'] || "").replace(/\D/g, '');
  place.photoLinks = place.photoLinks ? place.photoLinks.split(" , ").filter(link => !!link) : [];

  // statistics
  place.avgSMPRating = "";
  if (place.fbRating || place.gmRating) {
    const { fbRating, gmRating, fbRatingUserTotal, gmUserRatingsTotal } = place;
    place.avgSMPRatingUserTotal = +fbRatingUserTotal + +gmUserRatingsTotal;
    place.avgSMPRating = parseFloat(((fbRating*fbRatingUserTotal + gmRating*gmUserRatingsTotal) / place.avgSMPRatingUserTotal).toFixed(1));

    if (fbRating) place.fbRating = parseFloat(fbRating);
  }
  place.totalSMPLikes = +place.fbLikes + +place.igFollowers;

  // Latitude & longitude & address object
  place.latitude = "";
  place.longitude = "";
  if (place.latLong) {
    const [lat, lng] = place.latLong.split(", ");
    place.latitude = lat;
    place.longitude = lng;
  }
  else if (place.addressObject) {
    const addrObj = JSON.parse(place.addressObject);
    place.latitude = addrObj.location.latitude;
    place.longitude = addrObj.location.longitude;
  }

  // liked by user?
  if (!skipLikedByUser && user && user.id) { // "3VN5wQxfEsZfLjv42pamXH0CpZp2: 1340df25 , 3VN5wQxfEsZfLjv42pamXH0CpZp2: 5482ac78"
    place.likedByUser = (user.relatedUserLikedPlaces || "").replace(user.id, "").includes(place.id);
  }

  // Google Maps reviews
  try {
    place.gmReviews = JSON.parse(place.gmReviews);
    place.gmReviews.sort((a, b) => (a.time > b.time ? -1 : (a.time < b.time ? 1 : 0)));
  } catch (e) {
    place.gmReviews = [];
  }

  // Google Maps rating per score
  try {
    place.gmReviewsPerScore = JSON.parse(place.gmReviewsPerScore);
  } catch (e) {
    place.gmReviewsPerScore = null;
  }
  return place;
}

export default {
  // 地點
  receivePlace(state, data) {
    data.fetchedDetails = true; // place details fetched
    const idx = state.places.findIndex(p => p.id == data.id);
    if (idx !== -1) {
      state.places.splice(idx, 1, { ...state.places[idx], ...parsePlace(data, state.user, true) });
    } else {
      state.places.push(parsePlace(data, state.user));
    }
  },
  updatePlace(state, { id, updateObj }) {
    const idx = state.places.findIndex(p => p.id == id);
    if (idx !== -1) {
      state.places.splice(idx, 1, { ...state.places[idx], ...updateObj });
    }
  },

  // Compare places
  setSelectingComparingPlaces(state, isSelecting) {
    state.isSelectingComparingPlaces = isSelecting;
  },

  // Add user recently browsed places
  addUserBrowsedPlace(state, objId) {
    let currUsrObjIds = state.user.relatedUserBrowsedPlaces || "";
    const newUsrObjId = `${state.user.id}: ${objId}`;
    if (currUsrObjIds == "") currUsrObjIds = [newUsrObjId]; // first product
    else {
      currUsrObjIds = currUsrObjIds.split(" , ").filter(usrObjId => usrObjId.split(": ")[1] != objId);
      currUsrObjIds.unshift(newUsrObjId);
    }
    state.user.relatedUserBrowsedPlaces = currUsrObjIds.join(" , ");
  },
}