
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';

// icons
import { chevronBack, arrowBack, arrowForward, close, camera, handRightOutline, layersOutline, checkmark,
          arrowUndoOutline, arrowRedoOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
        IonSpinner, IonList, IonItem, IonLabel, IonInput, IonTextarea,
        IonFabButton, IonCard, IonCardContent, IonSegment, IonSegmentButton, IonProgressBar,
        modalController, loadingController, } from '@ionic/vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { utils } from '@/composables/utils';
import { utilsDesign } from '@/composables/utilsDesign';
import { useRouter } from 'vue-router';

// services
import StickerService from '@/services/StickerService';

// libraries
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import Konva from "konva";
        
export default defineComponent({
  name: 'StickerPreQuestionModal',
  props: ["stickerId", "petPhoto", "mimeType"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                IonSpinner, IonList, IonItem, IonLabel, IonInput, IonTextarea,
                IonFabButton, IonCard, IonCardContent, IonSegment, IonSegmentButton, IonProgressBar, },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.state.user);
    
    const { presentToast, loadHTMLImg, uniqueId, sleep, } = utils();
    const { getTrimmedCanvasDataURL } = utilsDesign();
    const { photos, takePhoto, deletePhoto, setPhotos, } = usePhotoGallery();

    // state variables
    const rembgMode = ref("original");
    const stageRef = ref(null);
    const sceneHeight = Math.min(window.innerHeight, 400);
    const canvas = reactive({
      loading: true,
      sceneWidth: window.innerWidth,
      sceneHeight,
      maxImgHeight: sceneHeight-100,

      // Images
      petImg: null,
      croppedImgDataURL: "",
      rembgImgDataURL: "",
      loadingRembgImg: false,
      rembgLoadingPercent: 0,

      // Drawing & Erasing
      isDrawing: false,
      lines: [],
      erasedPoints: [],

      // Anchor Point
      isRelocatingAnchorPoint: true,
      anchorPointRadius: 5,
    });
    
    let anchorPointNode, anchorGroupNode, outlinedImgGroupNode;
    let lastSavedLines = [];
    let lastSavedErasedPoints = [];
    const autoSavePointCount = 150; // auto save during drag

    /**
     * UNDO / REDO (app history)
     */
    const appHistory = reactive({
      step: -1,
      states: [],
    });
    const saveStateToHistory = () => {
      const state = {
        action: 'addErasedPoints',
        prevErasedPoints: [...lastSavedErasedPoints],
        nextErasedPoints: [...canvas.erasedPoints],
        prevLines: [...lastSavedLines],
        nextLines: [...canvas.lines],
      };
      lastSavedErasedPoints = [...canvas.erasedPoints];
      lastSavedLines = [...canvas.lines];
      appHistory.states = appHistory.states.slice(0, appHistory.step + 1);
      appHistory.states.push(state);
      appHistory.step += 1;
    }
    const undo = () => {
      if (appHistory.step === -1) return;
      const currState = appHistory.states[appHistory.step--];

      if (currState.action == 'addErasedPoints') {
        const { prevLines, prevErasedPoints } = currState;
        canvas.erasedPoints = [...prevErasedPoints];
        canvas.lines = [...prevLines];
        lastSavedErasedPoints = [...canvas.erasedPoints];
        lastSavedLines = [...canvas.lines];
        const [x, y] = canvas.erasedPoints.slice(-2);
        if (x != null && y != null) anchorGroupNode.absolutePosition({ x, y });
      }
    }
    const redo = () => {
      if (appHistory.step === appHistory.states.length-1) return;
      const currState = appHistory.states[++appHistory.step];

      if (currState.action == 'addErasedPoints') {
        const { nextLines, nextErasedPoints } = currState;
        canvas.erasedPoints = [...nextErasedPoints];
        canvas.lines = [...nextLines];
        lastSavedErasedPoints = [...canvas.erasedPoints];
        lastSavedLines = [...canvas.lines];
        const [x, y] = canvas.erasedPoints.slice(-2);
        if (x != null && y != null) anchorGroupNode.absolutePosition({ x, y });
      }
    }
    const resetEraseImage = () => {
      canvas.isRelocatingAnchorPoint = true;
      canvas.erasedPoints = [];
      canvas.lines = [];
      appHistory.step = -1;
      appHistory.states = [];
      lastSavedLines = [];
      lastSavedErasedPoints = [];
      anchorGroupNode.absolutePosition({ x: 30, y: 30 });
    }

    // Cropper
    let cropper;
    
    /**
     * Load Canvas
     */
    let loadCanvasTimeout: any = null;
    const LOAD_TIMEOUT = 3000;
    const setCanvasLoaded = () => {
      if (loadCanvasTimeout) clearTimeout(loadCanvasTimeout);
      canvas.loading = true; // bug fix prevent empty canvas
      loadCanvasTimeout = setTimeout(() => {
        canvas.loading = false;
        setTimeout(() => {
          anchorPointNode = stageRef.value.getStage().findOne('#anchorPoint');
          anchorGroupNode = stageRef.value.getStage().findOne('.anchorGroup');
          outlinedImgGroupNode = stageRef.value.getStage().findOne('.outlinedImgGroup');
        }, 150);
      }, LOAD_TIMEOUT);
    }
    
    const loadImg = (imgLink: any, targetVarField: any, noLoading = false) => {
      loadHTMLImg(imgLink, (img: any) => {
        canvas[targetVarField] = img;
        if (!noLoading) setCanvasLoaded();
      });
    }
    /**
     * Routing
     */
    const closeModal = async (data: any = null) => (await modalController.dismiss(data));
    const goPrev = async (stickerId: any = null) => {
      if (photos.value.length == 0) { // STEP 1
        closeModal();
      }
      else if (!canvas.croppedImgDataURL) { // STEP 2
        if (stickerId) closeModal(); // editing photo
        else deletePhoto(photos.value[0]);
      }
      else { // STEP 3
        resetEraseImage();
        rembgMode.value = "original";
        canvas.croppedImgDataURL = "";
        canvas.rembgImgDataURL = "";
        canvas.loadingRembgImg = false;
        canvas.rembgLoadingPercent = 0;
      }
    }
    const goNext = async () => { // Photo cropped
      canvas.croppedImgDataURL = cropper.getCroppedCanvas({
        fillColor: photos.value[0].mimeType == 'image/jpeg' ? '#fff' : 'transparent',
        maxWidth: 2000,
      }).toDataURL(photos.value[0].mimeType || 'image/jpeg');
      loadImg(canvas.croppedImgDataURL, 'petImg');
    }
    const goToStickerDetailPage = async (stickerId: any = null) => {
      const loading = await loadingController.create({});
      await loading.present();

      const payload = {
        customPhoto: canvas.croppedImgDataURL,
      }
      if (rembgMode.value == 'manual') {
        const targetCanvas = outlinedImgGroupNode.toCanvas({
          pixelRatio: Math.min(2500, canvas.petImg.naturalWidth) / canvas.sceneWidth, // TODO: save 2 sets of images (full & min)
        });
        payload['rembgCustomPhoto'] = getTrimmedCanvasDataURL(targetCanvas, true);
      }
      else if (rembgMode.value == 'auto') {
        payload['rembgCustomPhoto'] = canvas.rembgImgDataURL;
      }
      if (stickerId == null)  {
        const newUserStickerId = uniqueId(), name = t('untitledSticker');
        StickerService.upsertUserSticker({ newUserStickerId, name, }); // Insert Sticker Record to DB
        stickerId = newUserStickerId;
        payload['name'] = name;
        store.commit('upsertUserSticker', { ...payload, id: stickerId });
        router.replace(`/stickers/${stickerId}`);
      }
      loading.dismiss();

      await closeModal(payload);
    }


    /**
     * Stage Events
     */
    const handleStageMouseDown = (e: any) => {
      if (canvas.isRelocatingAnchorPoint) return;

      canvas.isDrawing = true;
      const { x, y } = anchorGroupNode.getAbsolutePosition();
      canvas.lines = [...canvas.lines, { points: [x, y] }];
      canvas.erasedPoints = canvas.erasedPoints.concat([x, y]);
    }
    const handleStageMouseMove = (e: any) => {
      if (canvas.isRelocatingAnchorPoint || !canvas.isDrawing) return;
      let { x, y } = anchorGroupNode.getAbsolutePosition();

      // Check & prevent dragging the point outside canvas
      const stage = stageRef.value.getStage();
      if (x < 0 || x > stage.width() || y < 0 || y > stage.height()) {
        const newAbsPos = { x, y };
        if (x < 0) newAbsPos.x = 0;
        else if (x > stage.width()) newAbsPos.x = stage.width();

        if (y < 0) newAbsPos.y = 0;
        else if (y > stage.height()) newAbsPos.y = stage.height();

        x = newAbsPos.x;
        y = newAbsPos.y;

        anchorGroupNode.setAbsolutePosition(newAbsPos);
      }

      // Draw the lines
      const lastLine = canvas.lines[canvas.lines.length - 1];
      lastLine.points = lastLine.points.concat([x, y]);
      canvas.lines.splice(canvas.lines.length - 1, 1, lastLine);
      canvas.erasedPoints = canvas.erasedPoints.concat([x, y]);
      if (lastLine.points.length > autoSavePointCount) {
        saveStateToHistory();
        canvas.lines = [...canvas.lines, { points: [x,y] }];
      }
    }
    const handleStageMouseUp = (e: any) => {
      if (canvas.isDrawing) {
        canvas.isDrawing = false;
        saveStateToHistory();
      }
    }

    /**
     * Cropper
     */
    const initCropper = (cropperData: any = null) => {
      const image: any = document.querySelector('#sticker-pet-photo');
      cropper = new Cropper(image, {
        viewMode: 1,
        zoomable: true,
        dragMode: 'move',
        autoCropArea: 1,
        minCropBoxWidth: 25,
        minCropBoxHeight: 25,
        minContainerWidth: 350,
        minContainerHeight: 400,
        preview: '#cropped-pet-photo-container',
        cropend(event) {
          return;
        },
        zoom(event) {
          return;
        },
        ready() {
          if (cropperData) {
            const { cropBoxData, canvasData } = JSON.parse(cropperData);
            if (cropBoxData || canvasData) {
              setTimeout(() => {
                if (canvasData) cropper.setCanvasData(canvasData);
                if (cropBoxData) cropper.setCropBoxData(cropBoxData);
              }, 500);
            }
          }
        },
      });
    }

    /**
     * Photos uploaded / deleted
     */
    watch(photos, (currPhotos: any) => {
      const photoLink = (currPhotos.length > 0 ? currPhotos[0].base64Data : "");
      if (photoLink) {
        setTimeout(initCropper, 100);
      } else {
        canvas.loading = true;
        canvas.petImg = null;
        canvas.rembgImgDataURL = "";
        if (cropper) cropper.destroy();
      }
    })

    /**
     * INIT: Check & load existing stickers
     */
    onMounted(() => {
      const { stickerId, petPhoto, mimeType } = props;
      if (stickerId && petPhoto) {
        setPhotos([{ base64Data: petPhoto, mimeType: mimeType }]);
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      chevronBack, arrowBack, arrowForward, close, camera, handRightOutline, layersOutline, checkmark,
      arrowUndoOutline, arrowRedoOutline,

      // variables
      user,
      photos,
      rembgMode,
      canvas, stageRef, appHistory,
      
      // methods
      t, closeModal, goPrev, goNext, goToStickerDetailPage,
      handleStageMouseDown, handleStageMouseMove, handleStageMouseUp,
      takePhoto,
      undo, redo,

      onCompleteRelocateAnchorPoint: () => {
        canvas.isRelocatingAnchorPoint = false;
        const { x, y } = anchorPointNode.getAbsolutePosition();
        lastSavedErasedPoints = [x, y];
      },
      resetEraseImage: () => {
        canvas.isRelocatingAnchorPoint = true;
        canvas.erasedPoints = [];
        canvas.lines = [];
        appHistory.step = -1;
        appHistory.states = [];
        lastSavedLines = [];
        lastSavedErasedPoints = [];
        anchorGroupNode.absolutePosition({ x: 30, y: 30 });
      },

      getCanvasWidth: () => (Math.min(canvas.maxImgHeight * (canvas.petImg.naturalWidth / canvas.petImg.naturalHeight), canvas.sceneWidth)),
      getCanvasHeight: () => (Math.min(canvas.sceneWidth / (canvas.petImg.naturalWidth / canvas.petImg.naturalHeight), canvas.maxImgHeight)),
      getCanvasPetImgScale: () => (Math.min(canvas.sceneWidth / canvas.petImg.naturalWidth, canvas.maxImgHeight / canvas.petImg.naturalHeight)),

      onRembgModeChanged: async () => {
        if (rembgMode.value == 'auto') {
          if (canvas.loadingRembgImg == false && !canvas.rembgImgDataURL) {
            canvas.loadingRembgImg = true;
            const interval = setInterval(() => {
              if (canvas.rembgLoadingPercent < 0.8) {
                canvas.rembgLoadingPercent += 0.02;
              }
            }, 400);
            const dataURL = cropper.getCroppedCanvas({ maxWidth: 2500 }).toDataURL('image/jpeg');
            canvas.rembgImgDataURL = await StickerService.removeBgFromImg(dataURL);
            clearInterval(interval);
            while (canvas.rembgLoadingPercent <= 1) {
              canvas.rembgLoadingPercent += 0.05;
              await sleep(0.05);
            }
            canvas.loadingRembgImg = false;
            //setTimeout(initCropper, 300);
          }
        }
      },
    }
  }
});
