import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  async getPlaceById(id: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('place-getPlaceById')({ id });
    return res.data[0];
  },

  // User Browsed Places History
  async addUserBrowsedPlace(placeId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('place-addUserBrowsedPlace')({ placeId });
  },
  async getUserBrowsedPlaces() {
    return (await firebase.app().functions('asia-east2').httpsCallable('place-getUserBrowsedPlaces')()).data;
  },

  // User Liked Places
  async addUserLikedPlace(placeId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('place-addUserLikedPlace')({ placeId });
  },
  async removeUserLikedPlace(placeId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('place-removeUserLikedPlace')({ placeId });
  },
}