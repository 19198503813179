import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  // Comments
  async addNewComment(productId: any, content: any, username: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-addNewComment')({ productId, content, username })).data;
  },
  async deleteComment(commentId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-deleteComment')({ commentId })).data;
  },

  // Posts & Comments
  async banUser(bannedUserId: any, commentId: any = "") {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-banUser')({ bannedUserId, commentId })).data;
  },
  async unbanUser(bannedUserId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-unbanUser')({ bannedUserId })).data;
  },
  async reportComment(commentId: any, description: any, commentContent = "") {
    return (await firebase.app().functions('asia-east2').httpsCallable('post-reportPost')({ commentId, description, commentContent, })).data;
  },
}