import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/pages/auth/LoginPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        //redirect: '/home'
        component: () => import('@/pages/LandingPage.vue')
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'feedback',
        name: 'FeedbackPage',
        component: () => import('@/pages/FeedbackPage.vue')
      },

      /* Home */
      {
        path: 'home',
        name: 'HomePage',
        component: () => import('@/pages/pet/HomePage.vue'),
      },

      /* Order */
      {
        path: 'orders',
        name: 'OrderListPage',
        component: () => import('@/pages/order/OrderListPage.vue'),
      },
      {
        path: "orders/:id",
        name: "OrderDetailPage",
        component: () => import("@/pages/order/OrderDetailPage.vue"),
        props: true,
      },

      /* Sticker */
      {
        path: 'stickers',
        name: 'StickerListPage',
        component: () => import('@/pages/sticker/StickerListPage.vue'),
      },
      {
        path: 'stickers/:id',
        name: 'StickerDetailPage',
        component: () => import('@/pages/sticker/StickerDetailPage.vue'),
        alias: ['new-sticker', '/:id'],
      },

      /* Notification */
      {
        path: 'notifications',
        name: 'NotificationListPage',
        component: () => import('@/pages/notification/NotificationListPage.vue'),
      },
      {
        path: 'notifications/:id',
        name: 'NotificationDetailPage',
        component: () => import("@/pages/notification/NotificationDetailPage.vue"),
        props: true,
      },

      /* Tool Page */
      {
        path: 'tools',
        name: 'ToolListPage',
        component: () => import('@/pages/pet/ToolListPage.vue'),
      },
      {
        path: 'toy',
        name: 'ToyPage',
        component: () => import('@/pages/pet/ToyPage.vue'),
      },
      {
        path: 'sounds',
        name: 'SoundListPage',
        component: () => import('@/pages/pet/SoundListPage.vue'),
      },

      /* Information Pages */
      {
        path: 'info-overview',
        name: 'InfoOverviewPage',
        component: () => import('@/pages/pet/info/InfoOverviewPage.vue'),
      },
      {
        path: 'infos',
        name: 'InfoListPage',
        component: () => import('@/pages/pet/info/InfoListPage.vue'),
      },
      {
        path: 'liked-infos',
        component: () => import('@/pages/pet/info/InfoUserLikedPage.vue'),
      },
      {
        path: 'browsed-infos',
        component: () => import('@/pages/pet/info/InfoUserBrowsedPage.vue'),
      },

      /* Place Pages */
      {
        path: 'place-overview',
        name: 'PlaceOverviewPage',
        component: () => import('@/pages/pet/place/PlaceOverviewPage.vue'),
      },
      {
        path: 'places',
        name: 'PlaceListPage',
        component: () => import('@/pages/pet/place/PlaceListPage.vue')
      },
      {
        path: 'places/:id',
        name: 'PlaceDetailPage',
        component: () => import('@/pages/pet/place/PlaceDetailPage.vue'),
      },
      {
        path: 'liked-places',
        component: () => import('@/pages/pet/place/UserLikedPlaceListPage.vue'),
      },
      {
        path: 'browsed-places',
        component: () => import('@/pages/pet/place/UserBrowsedPlaceListPage.vue'),
      },
      {
        path: 'compare',
        component: () => import('@/pages/pet/ComparisonPage.vue'),
      },

      /* Shop / Products Pages */
      {
        path: 'products',
        name: 'ProductListPage',
        component: () => import('@/pages/coral/product/ProductListPage.vue'),
      },
      {
        path: 'products/:id',
        alias: ['p/:id'],
        name: 'ProductDetailPage',
        component: () => import("@/pages/coral/product/ProductDetailPage.vue"),
        props: true,
      },
      {
        path: 'liked-products',
        component: () => import('@/pages/coral/product/UserLikedItemListPage.vue'),
      },
      {
        path: 'cart',
        component: () => import('@/pages/coral/CartPage.vue')
      },
      {
        path: 'checkout/:orderId',
        alias: ['c/:orderId'],
        name: 'CheckoutPage',
        component: () => import('@/pages/order/CheckoutPage.vue'),
        props: true,
      },
      {
        path: 'thank-you/:orderId?',
        name: 'ThankYouOrderPage',
        component: () => import('@/pages/order/ThankYouOrderPage.vue'),
        props: true,
      },

      /**
       * MLP Corals
       */
      {
        path: 'band-posts',
        name: 'BandPostListPage',
        component: () => import("@/pages/coral/BandPostListPage.vue"),
      },
      {
        path: 'certificates',
        name: 'CertListPage',
        component: () => import("@/pages/coral/CertListPage.vue"),
      },
      {
        path: 'certificates/:certId',
        name: 'CertDetailPage',
        component: () => import("@/pages/coral/CertDetailPage.vue"),
      },
      {
        path: 'verify-certificate',
        component: () => import("@/pages/coral/CertDetailPage.vue"),
      },

      /**
       * Stripe
       */
      {
        path: 'payment-methods',
        name: 'PaymentMethodListPage',
        component: () => import("@/pages/order/PaymentMethodListPage.vue"),
      },

      /**
       * Other
       */
      {
        path: 'chatbot',
        name: 'ChatbotPage',
        component: () => import("@/pages/ChatbotPage.vue"),
      },
      {
        path: 'lucky-wheel/:productId?',
        name: 'LuckyWheelPage',
        component: () => import("@/pages/LuckyWheelPage.vue"),
      }
    ]
  }
]