export default {
  zh: {
    '港島': '港島',
    '九龍': '九龍',
    '新界': '新界',
    '離島': '離島',

    '待結帳': '待結帳',
    '待付款': '待付款',
    '已付款': '已付款',
    '已完成': '已完成',

    serverError: '不好意思，伺服器發生錯誤，請重試。',
    serverBusy: '不好意思，伺服器忙碌中，請重試。',
    noAvailablePrizes: '抱歉，所有獎品已售出。感謝支持！',

    // Membership Points
    birthdayYearMonth: '生日年份及月份',

    // Order
    usePoints: '使用積分',
    points: '積分',
    remaining: '尚餘',
    usablePoints: '可用積分',
    myPoints: '我的積分',
    pointDiscountTotal: '積分抵扣：',
    pointHistory: '積分紀錄',
    myPaymentMethods: '我的付款方式',
    saveCreditCard: '儲存你的信用卡',
    addNewCreditCard: '添加信用卡/扣帳卡',
    details: '詳情',
    game: '遊戲',
    free: '免費',
    giftForYou: '給您的獎賞',
    gift: '獎賞',
    expiryDate: '到期日',
    yourCoupons: '您的優惠券',
    apply: '套用',
    useCoupon: '使用優惠券',
    enterCouponCode: '輸入優惠碼',
    myOrders: '我的訂單',
    creditCard: '信用卡 (Visa/MasterCard)',
    offlinePayment: '其他付款方式 (PayMe / FPS等)',
    orderPendingPayment: '訂單待付款',
    orderPlaced: '完成下單了！',
    yourOrderCodeIs: '您的訂單編號是：',
    orderInfo: '訂單資料',
    deliveryInfo: '送貨資料',
    deliveryMethod: '送貨方式：',
    deliveryRegion: '區城：',
    deliveryDistrict: '送貨地區：',
    deliveryAddress: '地址 (只限樓下交收)：',
    customerName: '收貨人名字：',
    contactPhone: '聯絡電話：',
    customerEmail: '電郵地址：',
    failedCreditCardPayment: '信用卡付款失敗。',
    msgOrderPendingPayment: '請盡快完成付款以確認訂單。',
    msgOrderConfirmed: '稍候您將會收到訂單確認訊息。',
    invalidOrderId: '無效訂單編號！',
    doubledOrder: '訂單已完成，現導向至訂單詳情頁面。',
    incorrectCheckoutUser: '此付款連結不屬於您的帳戶。',
    loginYourAccount: '請先登入您的帳號',
    
    // Auction
    stopReceiveAuctionNotifications: "停止接收拍賣通知",
    receiveAuctionNotifications: "接收拍賣通知",
    auctionRules: "拍賣規則",
    auctionStartTime: "開始時間",
    auctionEndTime: "結束時間",
    startingPrice: "起標價",
    currentBid: "最新叫價",
    auctionStartSoon: "拍賣準備開始",
    inAuction: "拍賣中",
    auctionClosed: "拍賣已結束",
    placeBid: "出價",
    confirmPlaceBid: "確認出價",
    successBid: "出價成功！",
    invalidBid: "出價無效，金額必須至少 ",
    timesup: "時間到",
    auctionTimesupAlert: "拍賣已結束，感謝您的參與。",

    // Coral
    confirmComment: '確認留言?',
    addAComment: '新增留言...',
    liveChat: "實時聊天",
    coralReview: '珊瑚評論',
    yourRating: '您的評級',
    productRating: '用戶評級',
    congratsYouGot: '恭喜您獲得',
    scanningQRCode: '掃描 QR Code 中 ...',
    purchaseGetFirstCert: '購買珊瑚即可獲得證書！',
    invalidCert: '無效證書！',
    invalidQRCode: '無效二維碼！',
    certificateNumber: '證書編號',
    //coralCertDetails: '珊瑚證書詳情',
    coralCertDetails: '追溯珊瑚來源',
    verifiedCertInfo: '已驗證的證書資料',
    coralCertificates: '珊瑚證書',
    shareCertificate: '分享證書',
    downloadCertificate: '下載證書',
    coralOwner: "擁有人",
    coralId: "珊瑚編號",
    coralGenus: "珊瑚屬",
    coralGrade: "珊瑚等級",
    coralSize: "珊瑚大小",
    coralOrigin: "珊瑚來源地",
    importDate: "進口日期",
    citesPermitNo: "CITES 許可證號碼",
    importLicenseNo: "進口許可證號碼",
    acquisitionDate: "售出日期",
    coralSpecies: '珊瑚品種',
    category: '產品類別',
    price: "價錢範圍",

    belowProductsOutOfStock: "抱歉，無法提供以下產品。",
    sold: '已售出',
    hold: '已留',
    inCart: '已在購物車中',
    biddingInfo: '拍賣資料',
    bidHistory: "出價記錄",
    
    // Product
    readMore: '閱讀更多',
    likedProducts: '我喜愛的產品',
    buyNow: '立即購買',
    addToCart: '加入購物車',
    removeFromCart: '從購物車移除',
    addToLikedItems: '收藏',
    removeFromLikedItems: '從我的清單移除',
    enquiry: '聯繫客服',
    product: '產品',
    successUpdateLikedItems: '成功更新喜愛清單',
    successAddedToCart: '成功加到購物車',
    successRemovedFromCart: '已從購物車移除產品',

    shareProduct: '分享給朋友',
    earliestDeliveryDate: '最早可取貨日期',
    pickupPoints: '自取點',
    deliveryDistricts: '可送貨地區',
    shareWithFriends: '分享給朋友',
    productIntro: '產品介紹',
    orderNotice: '預訂須知',
    productReviews: '買家評論',
    orderForm: '訂購表格',
    selectOption: '請選擇',
    goToCart: '前往購物車',
    updateCart: '更新購物車',
    successUpdatedCart: '成功更新購物車。',
    required: '必填',

    // Mall Lol Coral
    shop: '商城',
    cart: '購物車',
    sale: '特價',
    soldOut: '售罊',

    backToMLP: '返回 Mall Lol Pet',
    search: '搜尋',
    searchOrAdd: '搜尋或新增',
    share: '分享',
    notifications: '通知',
    loginOrRegister: '登入 / 註冊',
    deleteAccount: '刪除帳戶',
    confirmDeleteAccountMsg: '注意：確定刪除此帳戶及所有相關資料?',
    accountDeleted: '帳戶已刪除',
    loginFirst: '請先登入 / 註冊帳號',
    saveSuccess: '儲存成功。',
    saveFail: '儲存失敗，請重試。',
    select: '選擇',
    welcome: '歡迎',
    cancel: '取消',
    loading: '載入中...',
    loadingMore: '載入中...',
    seeAll: '查看所有',
    untitledSticker: '未命名貼圖',
    done: '完成',
    new: '新',
    confirmChange: '確認完成更改?',

    // Camera Prompt
    photo: '相片',
    fromPhotos: '從相簿中選擇',
    takePicture: '拍照',

    // Tools
    tools: '小法寶',
    dogToy: '發聲玩具',
    confirmPlayToy: '確認開始玩玩具?',
    friendlyReminder: '溫馨提示',
    takePetPhotos: '幫狗狗拍照',
    confirmTakePetPhotos: "我哋會用玩具聲吸引你隻寵物望鏡頭，請注意手機音量。",

    // Sounds
    sounds: '聲音庫',
    recording: '錄音中...',
    startRecording: '開始錄音?',
    enterSoundName: '請輸入聲音名稱',
    saveSound: '儲存聲音',
    discard: '捨棄',
    save: '儲存',

    // Inforamtion
    infos: '最新資訊',
    likedInfos: '我收藏的新聞',
    browsedInfos: '我最近瀏覽的新聞',
    seeMoreInfos: '瀏覽更多新聞',
    loadingMoreInfos: '載入更多新聞中...',

    // Places
    listening: '聽取中...',
    permClosed: '已結業',
    place: '地點',
    places: '好去處',
    likedPlaces: '我收藏的地點',
    browsedPlaces: '我最近瀏覽的地點',
    loadingMorePlaces: '載入更多地點中...',
    seeMorePlaces: '查看所有地點',
    sendFeedback: '意見反映',
    openPlaceMap: '立即前往',
    yearFounded: '成立年份：',
    email: '電郵：',
    phone: '電話：',
    address: '地址：',
    openingHours: '營業時間：',
    statistics: '統計',
    likes: '讚好',
    followers: '在追蹤',
    pageFoundingDate: '專頁建立日期：',
    rating: '評分：',
    posts: '則帖子',
    photos: '相片',

    // Pets
    myPets: '我的寵物',
    addPet: '新增寵物',

    // DIY
    generatingProductPreviewPhotos: '預覽圖生成中，請稍候...',
    cartTotal: '總計：',
    lineTotal: '總計：',
    editDesign: '更改設計',
    gotFreeShippingDiscount: '您已成功獲得免費送貨優惠！',
    addMoreOrderAmount: '加購多',
    toGetFreeShippingDiscount: '即可享有免費送貨優惠！',
    resetDesign: '重設設計',
    confirmResetDesign: '確認重設成貼圖圖案的設計？',
    cannotBeUndone: '此操作不能被撤消',

    // Sticker pre-questions
    reachedMaxStickerCount: '貼圖數量已達上限。',
    rembg: '移除影像背景',
    useOriginal: '使用原圖',
    manualRembg: '人手退地',
    autoRembg: '自動退地',
    uploadYourPetPhoto: '請上傳 / 拍攝您的相片 (例如寵物 / 人像等)',
    cropYourPetPhoto: '請框出主體大約位置',
    relocateAnchorPoint: '請將紅色圓點拖拉至主體附近位置',
    startOutlineDraw: '請框畫出主體形狀',
    completeRelocateAnchorPoint: '下一步',
    rembgFriendlyReminder: '溫馨提示：相片中的物體與背景顏色對比愈大，自動退地的效果會愈好。',

    // Filters
    //pixelate: '馬賽克',
    pixelate: '變格仔',
    //posterize: '色調分離',
    posterize: '油畫化',
    cartoonize: '卡通化',
    less: '少',
    more: '多',

    // Shape Actions
    flip: '翻動',
    moveUp: '移上一層',
    moveDown: '移下一層',
    duplicate: '複製',

    // Brightness
    brightness: '相片亮度',
    brighter: '亮',
    dimmer: '暗',

    // Soft Edges
    softEdges: '平滑邊緣',

    // Eraser
    eraserSize: '橡皮擦大小',
    small: '小',
    big: '大',

    // Sticker Text
    addText: '加入文字',
    editText: '更改文字',
    enterTextHere: '請在此輸入文字',
    changeFontFamily: '按此更改字體',
    bold: '粗體',
    italic: '斜體',
    textStroke: '文字外框',
    textColor: '文字顏色',
    strokeColor: '外框顏色',
    thin: '幼',
    thick: '粗',

    // Stickers
    errorCloneSticker: '複製設計失敗，請重試。',
    addFilters: '濾鏡效果',
    confirmSaveStickerToWhatsApp: '確認將此貼圖設計傳送至您的WhatsApp?',
    updateStickerName: '更改貼圖名稱',
    selected: '已選',
    pet: '寵物',
    accessories: '裝飾品',
    text: '文字',
    loadingAssets: '素材載入中...',
    sticker: '貼圖',
    myStickers: '我的WhatsApp貼圖',
    addSticker: '新增貼圖',
    next: '下一步',
    stickerPreview: '預覽貼圖',
    saveStickerToWhatsApp: '輸出成 WhatsApp 貼圖',
    confirmLeave: '確認離開？',
    willNotSaveChanges: '作出的改動將不會被儲存。',
    processingImg: '移除圖像背景中...',
    createWhatsAppSticker: '製作貼圖',
    editPetPhoto: '編輯相片',
    enterStickerName: '請輸入貼圖名稱',
    stickerName: '貼圖名稱',
    makeACopy: '建立副本',
    confirmDeleteSticker: '確認刪除此貼圖?',
    shareSticker: '分享貼圖',
    downloadSticker: '下載貼圖',
    saveImage: '儲存圖片',
    mySavedStickers: '我已儲存的貼圖',
    deleteSticker: '刪除此貼圖',
    successAddStickerToWhatsApp: '已成功將貼圖傳送至WhatsApp。',
    failedAddStickerToWhatsApp: '貼圖傳送至WhatsApp失敗，請重試。',
    //createDIYProducts: '實體化',
    createDIYProducts: '變產品',
    transparentBackground: '無背景',

    imageShapes: '相片圖案',
    addImage: '上傳相片圖案',
    cropImage: '裁剪',
    eraseImage: '橡皮擦',
    selectCropArea: '請框出圖案位置 (例如寵物面部)',

    designing: '設計中',
    delete: '刪除',
    confirmDelete: '確認刪除',
    successDelete: '已成功刪除',

    lastUpdated: '最後更新：',
    enablePermissionInAppSettings: '此功能需要開啟相機，請在應用程式設定啟用相機權限。',

    registeringAccount: '設定帳戶中...',
    successUpdatePersonalInfo: '已成功更新個人資料。',
    clashEmailAddress: '此電子郵件地址已被使用。',
    wrongPassword: '登入資料錯誤！請重新輸入。',
    editMobileNumber: '更改手機號碼',

    aboutUs: '關於我們',
    orgChart: '公司架構',
    scopeOfServices: '服務範圍',
    relatedDocuments: '相關文件',
    contactUs: '聯絡我們',
    companyAddress: '地址：',
    companyEmail: '電郵：',
    companyPhone: '公司電話：',
    fax: '傳真：',

    Pet: {
      cropIDCardProfileImage: '請框出身份證上的頭像照片',
      petInformation: '寵物資料',
      petPhoto: '您的寵物照片',
      removeBgOrNot: '去除照片背景？',
      petType: '寵物類別',
      breed: '品種',
      name: '暱稱',
      nameEn: '英文姓名 (身份證)',
      color: '毛色',
      gender: '性別',
      dateOfBirth: '出生日期',
      origin: '出生地',
      isNeutered: '已做絕育手術？',
      notes: '備註',
      male: '男',
      female: '女',

      newUserPet: '請填寫您的寵物資料',
      userPet: '寵物資料',
      deleteUserPet: '刪除此寵物',
      confirmDeleteUserPet: '確認刪除此寵物的資料？',
      successDeleteUserPet: '已成功刪除寵物資料。',
      successUpdateUserPet: '已成功更新寵物資料。',
      confirmSaveIDCardInfoChanges: '要儲存身份證資料改動嗎？',
    },
    FeedbackPage: {
      contactInfo: '聯絡資料',
      nickname: '您的名字',
      enterNickname: '請輸入您的名字',
      email: '您的電郵',
      enterEmail: '請輸入您的電郵',
      feedback: '意見反饋',
      enterFeedback: '在此輸入您的意見',
      feedbackSubmitted: '意見提交成功！',
      phone: '您的手機號碼',
      enterPhone: '請輸入您的手機號碼',
    },
    LoginPage: {
      login: '下一步',
      loginPhone: '您的手機號碼',
      password: '密碼',
      loginWithVerificationCode: '手機驗證碼登入',
      scanLocationQRCode: '掃描地盤二維碼註冊',
      sendVerificationCode: '發送驗證碼',
      enterPhone: '請輸入您的手機號碼。',
      finish: '完成',
      completeRegistration: '完成您的註冊',
    },
    RegisterPage: {
      nickname: '您的暱稱',
      registerAccount: '註冊帳號',
      password: '密碼*',
      confirmPassword: '確認密碼*',
      register: '註冊',
      inconsistentPasswordMsg: '兩次輸入的密碼不一致，請重新輸入。',
      enterEmailAndPassword: '請輸入電郵及密碼。',
      phone: '手機號碼',
      email: '電郵地址',
      inviteCode: '邀請碼 (如有)',
      enterPhoneAndPassword: '請輸入手機號碼及密碼。',
      enterValidHKMobileNumber: '請輸入有效的香港手機號碼 (8位數字)',
      verifyingMobileNumber: '驗證閣下的手機號碼',
      verifyMobileNumberInstruction: '請輸入短訊驗證碼，驗證碼已發送至您的手機',
      verificationCode: '短訊驗證碼',
      invalidVerificationCode: '無效短訊驗證碼！',
      verify: '驗證',
      phoneRegistered: '此手機號碼已被註冊'
    },
    CheckoutPage: {
      yourOrder: '您的訂單',
      orderRemark: '訂單備註',
      specialIntructions: '給賣家的特別指示',
      deliveryInfo: '送貨資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入您的名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      contactInfo: '聯絡資料',
      confirmPayment: '確認付款',
      paymentProof: '付款證明',
      confirmOrder: '確認下單?',
      discountCode: '折扣碼',
      subTotal: '小計：',
      discountTotal: '折扣：',
      shippingTotal: '運費：',
      freeShipping: '免費送貨到自選地址',
      freightCollect: '貨到付運費',
      orderTotal: '總計：',
      invalidDiscountCode: '無效折扣碼。',
      delivery: '送貨',
      pickup: '自取',
      pickupPoint: '交收地點',
      selectPickupPoint: '選擇交收地點',
      estimatedDeliveryDate: '預計交貨日期：',
      agreeTC: '點擊「確認付款」即表示我已閱讀並接受',
      orderTC: '下單條款及細則',
      paymentMethod: '付款方式',
      paymentInfo: '付款資料',
    },

    UserProfilePage: {
      changeProfilePhoto: '更換頭像',
      successChangeProfilePhoto: '個人頭像更換成功！',
      editPersonalInfo: '修改個人資料',
      changePassword: '更改密碼',
      oldPassword: '現有密碼',
      newPassword: '新密碼',
      newPasswordConfirm: '確認新密碼',
      inconsistentNewPasswordMsg: '兩次輸入的新密碼不一致，請重新輸入。',
      wrongOldPassword: '現有密碼輸入錯誤，請重新輸入。',
      successUpdatePassword: '成功更改密碼！',
      enterAllFields: '請輸入所有欄位。',
      browseAllOrders: '查看所有訂單',
      myOrders: '訂單紀錄',
      myPosts: '發帖紀錄',
      likedItems: '喜愛產品',
      language: '語言',
      darkTheme: '深色主題',
      termsAndConditions: '責任及條款',
      feedback: '意見專區',
      logout: '登出',
      confirmLogout: '確定登出？',
      lastName: '姓氏',
      firstName: '名字',
      emailAddress: '電郵地址',
      phone: '手機號碼',
      save: '儲存',
      name: '姓名',
    },

    checkout: '結帳',
    profile: '帳戶',

    yes: '是',
    no: '否',
    back: '返回',
    confirm: '確定',
    all: '全部',
    remark: '備註',
    submit: '提交',

    errorHeader: '錯誤！',

    OrderPage: {
      order: '訂單',
      orderStatus: '訂單狀態',
      orderItems: '訂單產品',
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      deliveryInfo: '送貨資料',
      orderNumber: '訂單號碼：',
      total: '合共：',
    },

    ForgotPasswordPage: {
      loginEmail: '登入電郵',
      forgotPassword: '忘記密碼',
      sendResetPasswordEmail: '發送重設密碼電郵',
      resetPswEmailSentTo: '重設密碼電郵已發送至',
      checkEmailReset: '請檢查您的郵箱並透過電郵內的連結重設登入密碼。',
      backToLogin: '返回登入頁面',
      enterLoginEmail: '請輸入登入電郵。',
    },
    CartPage: {
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      checkout: '前往付款',
      shopNow: '即去購物',
      emptyCart: '購物車內沒有任何商品',
      removeCartItem: '刪除購物車商品',
      confirmRemoveCartItem: '確定移除此購物車商品？',
    },
    ProductPage: {
      relatedProducts: '相關產品',
      numOfCustomerReviews: '買家評論',
      newReview: '寫評論',
      successPublishProductReview: '感謝您的評級！',
      reviewRating: '評級',
      reviewContent: '原因',
      publishReview: '發表評論',
      updateReview: '更新',
    },
    HomePage: {
      seeMore: '查看更多',
      featuredProducts: '熱門推介',
      recommendedForYou: '推䔍給您',
      latestProducts: '最新上架',
      aboutUs: '關於我們',
      contactUs: '聯絡我們',
      companyAddress: '地址：',
      companyEmail: '電郵：',
      companyPhone: '公司電話：'
    },

    PostPage: {
      newPost: '新帖子',
      lastUpdated: '最後更新：',
      numOfComments: '則留言',
      newComment: '新增留言',
      commentBtnText: '留言',
      editPost: '編輯帖子',
      postTitle: '帖子主題',
      postContent: '帖子內容',
      postImage: '圖片',
      updatePost: '更新帖子',
      publishPost: '發佈帖子',
      successPublishPost: '已成功發佈帖子。',
      successUpdatePost: '已成功更新帖子。',
      confirmDelete: '確認刪除',
      confirmDeletePost: '確認刪除此帖子？',
      deletePost: '刪除帖子',
      postDeleted: '已刪除帖子。',
      report: '舉報留言',
      block: '封鎖用戶',
      unblock: '解除封鎖用戶',
      blockedContent: '已封鎖內容',
      confirmUnbanUser: '確認解除封鎖 user ？',
      unblockedUser: '已解除封鎖所有來自 user 的帖子',
      confirmBanUser: '確認封鎖所有來自 user 的帖子？',
      blockedUser: '已封鎖所有來自 user 的帖子',
      confirmReport: '我們會於閣下提交舉報後進行審閱，一經確認留言含有不當或令人反感之內容，我們會立刻將其移除並封鎖發表用戶。',
      reportDescription: '描述 (選填)',
      reportedPost: '已舉報帖子',
      deletedContent: '此訊息已被刪除。',
    },
  },
  en: {
    '港島': 'Hong Kong Island',
    '九龍': 'Kowloon',
    '新界': 'New Territories',
    '離島': 'Islands',

    '待結帳': 'Pending Checkout',
    '待付款': 'Pending Payment',
    '已付款': 'Paid',
    '已完成': 'Completed',

    serverError: 'Oops! Something went wrong. Please try again later.',
    serverBusy: 'Oops! Server busy, please try again later.',
    noAvailablePrizes: 'Sorry, all prizes sold out. Thank you for your support!',

    // Membership Points
    birthdayYearMonth: 'Birthday year & month',

    // Order
    usePoints: 'Use Points',
    points: 'Points',
    remaining: 'Remaining',
    usablePoints: 'Available points',
    myPoints: 'My Points',
    pointDiscountTotal: 'Point discount total: ',
    pointHistory: 'Point History',
    myPaymentMethods: 'My Payment Methods',
    saveCreditCard: 'Save your credit card',
    addNewCreditCard: 'Add a credit or debit card',
    details: 'Details',
    game: 'Game',
    free: 'FREE',
    giftForYou: 'Gift for you',
    gift: 'Gift',
    expiryDate: 'Expiry Date',
    yourCoupons: 'Your Coupons',
    apply: 'Apply',
    useCoupon: 'Use Coupon',
    enterCouponCode: 'Enter Coupon Code',
    myOrders: 'My Orders',
    creditCard: 'Credit Card',
    offlinePayment: 'Other Methods (PayMe, FPS, etc.)',
    orderPendingPayment: 'Pending Payment',
    orderPlaced: 'Order Placed Successfully!',
    yourOrderCodeIs: 'Your Order Code: ',
    orderInfo: 'Order Information',
    deliveryInfo: 'Delivery Information',
    deliveryMethod: 'Delivery Method: ',
    deliveryRegion: 'Region: ',
    deliveryDistrict: 'Delivery District: ',
    deliveryAddress: 'Delivery Address: ',
    customerName: 'Customer Name: ',
    contactPhone: 'Contact Phone: ',
    customerEmail: 'Email Address: ',
    failedCreditCardPayment: 'Failed Credit Card Payment',
    msgOrderPendingPayment: 'Please settle the payment to confirm your order.',
    msgOrderConfirmed: 'The order confirmation message will be sent to you soon.',
    invalidOrderId: 'Invalid Order ID!',
    doubledOrder: 'This order has been completed. You will now be redirected to the order details page.',
    incorrectCheckoutUser: "This checkout link isn't for your account",
    loginYourAccount: 'Please log into your account first.',

    // Auction
    stopReceiveAuctionNotifications: "Turn Off Notifications",
    receiveAuctionNotifications: "Turn On Notifications",
    auctionRules: "Auction Rules",
    auctionStartTime: "Start at",
    auctionEndTime: "End at",
    startingPrice: "Starting Price",
    currentBid: "Current Bid",
    auctionStartSoon: "Auction Starting Soon",
    inAuction: "In Auction",
    auctionClosed: "Auction Closed",
    placeBid: "Place Bid",
    confirmPlaceBid: "Confirm bid",
    successBid: "Bid placed successfully!",
    invalidBid: "Incorrect Bid Amount. Must be at least ",
    timesup: "Time's up",
    auctionTimesupAlert: "Auction has ended. Thank you for your participation.",

    // Coral
    confirmComment: 'Confirm?',
    addAComment: 'Add a comment...',
    liveChat: 'Live Chat',
    coralReview: 'Coral Review',
    productRating: 'User Rating',
    yourRating: 'Your Rating',
    congratsYouGot: 'Congrats! You got ',
    scanningQRCode: 'Scanning QR Code ...',
    purchaseGetFirstCert: 'Purchase to get your first coral certificate!',
    invalidCert: 'Invalid Certificate!',
    invalidQRCode: 'Invalid QR Code!',
    certificateNumber: 'Certificate No.',
    //coralCertDetails: 'Coral Certificate Details',
    coralCertDetails: 'Trace Coral Origin',
    verifiedCertInfo: 'Verified Certificate Information',
    coralCertificates: 'Coral Certificates',
    shareCertificate: 'Certificate',
    downloadCertificate: 'Certificate',
    coralOwner: "Owner",
    coralId: "Coral ID",
    coralGenus: "Genus",
    coralSpecies: 'Species',
    category: 'Category',
    price: 'Price',

    coralGrade: "Grade",
    coralSize: "Size",
    coralOrigin: "Origin",
    importDate: "Import Date",
    citesPermitNo: "CITES Permit No.",
    importLicenseNo: "Import License No.",
    acquisitionDate: "Acquisition Date",
    belowProductsOutOfStock: "Sorry, below products are unavailable.",
    sold: 'Sold',
    hold: 'Hold',
    inCart: 'Already In Cart',
    biddingInfo: 'Bidding Information',
    bidHistory: "Bid History",

    // Product
    readMore: 'Read More',
    likedProducts: 'Liked Products',
    buyNow: 'Buy Now',
    addToCart: 'Add to Cart',
    removeFromCart: 'Remove from Cart',
    addToLikedItems: 'Add to My List',
    removeFromLikedItems: 'Remove from My List',
    enquiry: 'Enquiry',
    product: 'Product',
    successUpdateLikedItems: 'List of liked items updated successfully!',
    successAddedToCart: 'Item added to cart successfully!',
    successRemovedFromCart: 'Item removed from cart.',

    shareProduct: 'Share',
    earliestDeliveryDate: 'Earlist Delivery Date',
    pickupPoints: 'Pick-up Points',
    deliveryDistricts: 'Delivering Districts',
    shareWithFriends: 'Share',
    productIntro: 'Introduction',
    orderNotice: 'Order Notice',
    productReviews: 'Product Reviews',
    orderForm: 'Order Form',
    selectOption: 'Please select',
    goToCart: 'Go to Cart',
    updateCart: 'Update Cart',
    successUpdatedCart: 'Cart updated successfully.',
    customizedProduct: 'Customized',
    required: 'Required',

    // Mall Lol Coral
    shop: 'Shop',
    cart: 'Cart',

    backToMLP: 'Back to Mall Lol Pet',
    search: 'Search',
    searchOrAdd: 'Search / Add New',
    share: 'Share',
    notifications: 'Notifications',
    loginOrRegister: 'Login / Register',
    deleteAccount: 'Delete Account',
    confirmDeleteAccountMsg: 'Confirm delete this account and all related personal information?',
    accountDeleted: 'Account deleted.',
    loginFirst: 'Please login / register first',
    saveSuccess: 'Saved successfully!',
    saveFail: 'Failed to save. Please try again.',
    select: 'Select ',
    welcome: 'Welcome ',
    cancel: 'Cancel',
    loading: 'Loading...',
    loadingMore: 'Loading...',
    seeAll: 'All',
    untitledSticker: 'Untitled Sticker',
    done: 'Done',
    new: 'New',
    confirmChange: 'Confirm the changes?',

    // Camera Prompt
    photo: 'Photo',
    fromPhotos: 'From Photos',
    takePicture: 'Take Picture',

    // Tools
    tools: 'Tools',
    dogToy: 'Dog Toy',
    confirmPlayToy: 'Start playing toy?',
    friendlyReminder: 'Friendly Reminder',
    takePetPhotos: 'Take Pet Photos',
    confirmTakePetPhotos: "Toy sound will be played to attract your pet's attention",

    // Sounds
    sounds: 'Sounds',
    recording: 'Recording...',
    startRecording: 'Start Recording Voice?',
    enterSoundName: 'Please input the name of the sound',
    saveSound: 'Save the sound',
    discard: 'Discard',
    save: 'Save',

    // Information
    infos: 'Pet News',
    likedInfos: 'Liked Information',
    browsedInfos: 'Browsed Information',
    loadingMoreInfos: 'Loading more information...',
    seeMoreInfos: 'Browse More Info',

    // Places
    listening: 'Listening...',
    permClosed: 'Permanently Closed',
    place: 'Place',
    places: 'Places',
    likedPlaces: 'Liked Places',
    browsedPlaces: 'Browsed Places',
    loadingMorePlaces: 'Loading more places...',
    seeMorePlaces: 'Browse All Places',
    yearFounded: 'Founded ',
    email: 'Email: ',
    phone: 'Phone: ',
    address: 'Address: ',
    openingHours: 'Opening Hours: ',
    statistics: 'Statistics',
    likes: 'Likes',
    followers: 'Followers',
    pageFoundingDate: 'Page created - ',
    rating: 'Rating: ',
    posts: 'Posts',
    photos: 'Photos',
    sendFeedback: 'Send Feedback',
    openPlaceMap: 'Open Maps',

    // Pets
    myPets: 'My Pets',
    addPet: 'Add New Pet',

    // DIY Products
    generatingProductPreviewPhotos: 'Generating Preview Photos ...',
    cartTotal: 'Total: ',
    lineTotal: 'Line Total: ',
    editDesign: 'Edit Design',
    gotFreeShippingDiscount: "You've got the free shipping discount!",
    addMoreOrderAmount: 'Add ',
    toGetFreeShippingDiscount: 'to get the free shipping discount!',
    resetDesign: 'Reset Design',
    confirmResetDesign: 'Confirm reset to the sticker design?',
    cannotBeUndone: 'This action cannot be undone!',

    // Sticker pre-questions
    reachedMaxStickerCount: 'Reached sticker limit. Please delete existing stickers to create new ones.',
    rembg: 'Remove Image Background?',
    useOriginal: 'Use Original',
    manualRembg: 'Manual',
    autoRembg: 'Auto',
    uploadYourPetPhoto: 'Please upload your photos (e.g. pet, person, etc.)',
    cropYourPetPhoto: 'Please crop the area of the subject',
    relocateAnchorPoint: 'Please drag the red circle to position near the subject.',
    startOutlineDraw: 'Please outline draw the object',
    completeRelocateAnchorPoint: 'Next',
    rembgFriendlyReminder: 'Friendly Reminder: this feature only works great for high-contrast images.',

    // Filters
    pixelate: 'Pixelate',
    posterize: 'Posterize',
    cartoonize: 'Cartoonize',
    less: 'Less',
    more: 'More',

    // Shape Actions
    flip: 'Flip',
    moveUp: 'Move up',
    moveDown: 'Move down',
    duplicate: 'Duplicate',

    // Brightness
    brightness: 'Brightness',
    brighter: 'Brighter',
    dimmer: 'Dimmer',

    // Soft Edges
    softEdges: 'Soft Edges',

    // Eraser
    eraserSize: 'Eraser Size',
    small: 'Small',
    big: 'Big',

    // Sticker Text
    addText: 'Add Text',
    editText: 'Edit Text',
    enterTextHere: 'Please enter text here',
    changeFontFamily: 'Select Font Family',
    bold: 'Bold',
    italic: 'Italic',
    textStroke: 'Stroke',
    textColor: 'Text Color',
    strokeColor: 'Stroke Color',
    thin: 'Thin',
    thick: 'Thick',

    // Stickers
    errorCloneSticker: 'Error occurred in cloning sticker. Please try again.',
    addFilters: 'Photo Filters',
    confirmSaveStickerToWhatsApp: 'Confirm export the sticker design to your WhatsApp?',
    updateStickerName: 'Change Sticker Name',
    selected: 'Selected',
    pet: 'Pet',
    accessories: 'Accessories',
    text: 'Text',
    loadingAssets: 'Loading...',
    sticker: 'Sticker',
    addSticker: 'Add New Sticker',
    myStickers: 'My WhatsApp Stickers',
    next: 'Next',
    stickerPreview: 'Preview',
    saveStickerToWhatsApp: 'Stickerize in WhatsApp',
    confirmLeave: 'Confirm leaving this page?',
    willNotSaveChanges: 'All the changes you made will be lost.',
    processingImg: 'Processing Image...',
    createWhatsAppSticker: 'Make Sticker',
    editPetPhoto: 'Edit Photo',
    enterStickerName: 'Please enter the sticker name',
    stickerName: 'Sticker Name',
    makeACopy: 'Make a copy',
    confirmDeleteSticker: 'Confirm delete this sticker?',
    shareSticker: 'Share Sticker',
    downloadSticker: 'Download Sticker',
    saveImage: 'Save',
    mySavedStickers: 'My Saved Stickers',
    deleteSticker: 'Delete Sticker',
    successAddStickerToWhatsApp: 'Sticker has been sent to WhatsApp successfully!',
    failedAddStickerToWhatsApp: 'Failed to send sticker to WhatsApp. Please try again.',
    createDIYProducts: 'Productize',
    transparentBackground: 'None',

    imageShapes: 'Images',
    addImage: 'Upload Image',
    cropImage: 'Crop',
    eraseImage: 'Erase',
    selectCropArea: 'Please crop the object area (e.g. pet face)',

    designing: 'Designing',
    delete: 'Delete',
    confirmDelete: 'Confirm Delete',
    successDelete: 'Deleted.',

    lastUpdated: 'Last Updated: ',
    enablePermissionInAppSettings: 'If you want to grant permission for using your camera, enable it in the app settings.',

    loginInformation: 'Login Account Information',
    apartmentInformation: 'Apartment Information',
    registeringAccount: 'Setting up account...',
    successUpdatePersonalInfo: 'Personal information updated successfully.',
    clashEmailAddress: 'The email address is already in use by another account.',
    wrongPassword: 'Login information is incorrect.',
    editMobileNumber: 'Edit Mobile Number',

    aboutUs: 'About Us',
    orgChart: 'Organization Chart',
    scopeOfServices: 'Scope of Services',
    relatedDocuments: 'Related Documents',
    contactUs: 'Contact Us',
    companyAddress: 'Address: ',
    companyEmail: 'Email: ',
    companyPhone: 'Telephone: ',
    fax: 'Fax: ',

    Pet: {
      cropIDCardProfileImage: 'Please crop the profile photo for Pet ID card',
      petInformation: 'Pet Information',
      petPhoto: 'Pet Photo',
      removeBgOrNot: 'Remove photo background?',
      petType: 'Pet Type',
      breed: 'Breed',
      name: 'Pet Nickname',
      nameEn: 'English Name (ID Card)',
      color: 'Color',
      gender: 'Gender',
      dateOfBirth: 'Date of Birth',
      origin: 'Place of Birth',
      isNeutered: 'Neutered / Spayed?',
      notes: 'Notes',
      male: 'Male',
      female: 'Female',
      
      newUserPet: 'New Pet',
      userPet: 'Your Pet',
      deleteUserPet: 'Delete This Pet',
      confirmDeleteUserPet: 'Confirm delete this pet?',
      successDeleteUserPet: 'Pet deleted.',
      successUpdateUserPet: 'Pet information updated successfully!',
      confirmSaveIDCardInfoChanges: 'Do you want to save the changes before leaving?',
    },
    FeedbackPage: {
      contactInfo: 'Contact Information',
      nickname: 'Your name',
      enterNickname: 'Enter your name',
      email: 'Your email',
      enterEmail: 'Enter your email',
      feedback: 'Feedback',
      enterFeedback: 'Enter your feedback here',
      feedbackSubmitted: 'Feedback submitted successfully!',
      phone: 'Your phone',
      enterPhone: 'Enter your phone',
    },
    LoginPage: {
      login: 'Next',
      password: 'Password',
      loginPhone: 'Your Mobile Number',
      loginEmail: 'Mobile Number / Email',
      loginWithVerificationCode: 'Log in with SMS verification code',
      scanLocationQRCode: 'Register with location QR code',
      sendVerificationCode: 'Send Verification Code',
      enterPhone: 'Please enter your mobile number.',
      finish: 'Done',
      completeRegistration: 'Complete your registration',
    },
    RegisterPage: {
      nickname: 'Your Nickname',
      registerAccount: 'Register',
      password: 'Password*',
      confirmPassword: 'Confirm Password*',
      register: 'Register',
      enterEmailAndPassword: 'Please enter both the email address and password.',
      inconsistentPasswordMsg: 'Passwords do not match. Please enter again.',
      phone: 'Mobile Number',
      email: 'Email',
      inviteCode: 'Invite Code (if any)',
      enterPhoneAndPassword: 'Please enter both the mobile number and password.',
      enterValidHKMobileNumber: 'Please enter a valid Hong Kong mobile number (8 digits)',
      verifyingMobileNumber: 'Verify your mobile number',
      verifyMobileNumberInstruction: 'Please enter the verification code that was sent to your mobile device',
      verificationCode: 'Verification Code',
      invalidVerificationCode: 'Invalid verification code!',
      verify: 'Verify',
      phoneRegistered: 'This phone has already been registered',
      unregisteredPhone: 'The phone is not registered, please register first.',
    },
    CheckoutPage: {
      yourOrder: 'Your Order',
      orderRemark: 'Order Remark',
      specialIntructions: 'Special Intructions to the Seller',
      deliveryInfo: 'Delivery Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      contactInfo: 'Contact Information',
      paymentProof: 'Payment Proof',
      pickup: 'Self-pickup',
      pickupPoint: 'Pick-up Point',
      selectPickupPoint: 'Select pick-up point',
      confirmPayment: 'Submit',
      confirmOrder: 'Confirm Placing Order?',
      discountCode: 'Discount Code',
      subTotal: 'Subtotal: ',
      discountTotal: 'Discount: ',
      shippingTotal: 'Shipping Total: ',
      freeShipping: 'FREE Shipping',
      freightCollect: 'Freight Collect',
      orderTotal: 'Grand Total: ',
      invalidDiscountCode: 'Invalid Discount Code!',
      delivery: 'Delivery',
      estimatedDeliveryDate: 'Estimated Delivery Date: ',
      agreeTC: 'By clicking "Submit" I agree',
      orderTC: 'the Order Terms & Conditions',
      paymentMethod: 'Payment Method',
      paymentInfo: 'Payment Info',
    },

    checkout: 'Checkout',
    profile: 'Profile',

    sale: 'SALE',
    soldOut: 'SOLD OUT',

    yes: 'Yes',
    no: 'No',
    back: 'Back',
    confirm: 'Confirm',
    all: 'All',

    remark: 'Remark',
    submit: 'Submit',

    errorHeader: 'ERROR!',

    OrderPage: {
      order: 'Order',
      orderStatus: 'Order Status',
      orderItems: 'Order Items',
      lineTotal: 'Line Total: ',
      orderTotal: 'Order Total: ',
      deliveryInfo: 'Delivery Information',
      orderNumber: 'Order ID: ',
      total: 'Total: ',
    },

    ForgotPasswordPage: {
      loginEmail: 'Login Email',
      forgotPassword: 'Forgot Password',
      sendResetPasswordEmail: 'Send Reset Password Email',
      resetPswEmailSentTo: 'Email has been sent to',
      checkEmailReset: 'Please reset the password by clicking the link in the email.',
      backToLogin: 'Back to Login',
      enterLoginEmail: 'Please enter the login email',
    },

    UserProfilePage: {
      changeProfilePhoto: 'Change Profile Photo',
      successChangeProfilePhoto: 'Profile photo changed successfully!',
      editPersonalInfo: 'Edit Personal Information',
      changePassword: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      inconsistentNewPasswordMsg: 'New passwords do not match. Please enter again.',
      wrongOldPassword: 'The old password is wrong. Please enter again.',
      successUpdatePassword: 'Password updated successfully!',
      enterAllFields: 'Please enter all fields.',
      myOrders: 'My Orders',
      browseAllOrders: 'Browse All Orders',
      myPosts: 'My Posts',
      likedItems: 'Liked Items',
      language: 'Language',
      darkTheme: 'Dark Theme',
      termsAndConditions: 'Terms and Conditions',
      feedback: 'Feedback',
      logout: 'Log Out',
      confirmLogout: 'Confirm log out?',
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email Address',
      phone: 'Phone',
      save: 'Save',
      name: 'Name',
    },
    CartPage: {
      lineTotal: 'Total: ',
      orderTotal: 'Total: ',
      checkout: 'Checkout',
      shopNow: 'Shop Now',
      emptyCart: 'Your shopping cart is empty',
      removeCartItem: 'Remove Cart Item',
      confirmRemoveCartItem: 'Confirm removing this item from cart?',
    },
    ProductPage: {
      relatedProducts: 'Related Products',
      numOfCustomerReviews: ' Customer Reviews',
      newReview: 'Write a review',
      successPublishProductReview: 'Thank you for your review!',
      reviewRating: 'Grade',
      reviewContent: 'Reason',
      publishReview: 'Publish Review',
      updateReview: 'Update Review',
    },
    HomePage: {
      seeMore: 'See More',
      featuredProducts: 'Featured Products',
      recommendedForYou: 'Recommended For You',
      latestProducts: 'Latest Products',
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      companyAddress: 'Address: ',
      companyEmail: 'Email: ',
      companyPhone: 'Telephone: ',
    },

    PostPage: {
      newPost: 'New Post',
      lastUpdated: 'Last Updated: ',
      numOfComments: ' Comments',
      newComment: 'New Comment',
      commentBtnText: 'COMMENT',
      editPost: 'Edit Post',
      postTitle: 'Post Title',
      postContent: 'Post Content',
      postImage: 'Image',
      updatePost: 'Update Post',
      publishPost: 'Publish Post',
      successPublishPost: 'Post published successfully!',
      successUpdatePost: 'Post updated successfully!',
      confirmDelete: 'Confirm Delete',
      confirmDeletePost: 'Confirm delete this post?',
      deletePost: 'Delete Post',
      postDeleted: 'Post deleted.',
      report: 'Report Post',
      block: 'Block User',
      unblock: 'Unblock User',
      blockedContent: 'Blocked Content',
      confirmUnbanUser: 'Confirm to unblock user?',
      unblockedUser: 'Unblocked all posts from user',
      confirmBanUser: 'Confirm to block all posts from user?',
      blockedUser: 'Blocked all posts from user',
      confirmReport: 'We will review this post within 24 hours after you submit the report. \
                      If it is confirmed that this post contains inappropriate or objectionable content, we will immediately remove the post and eject the user who provided the offending content.',
      reportDescription: 'Description (optional)',
      reportedPost: 'Reported Post',
      deletedContent: 'Message deleted.',
    },
  }
}