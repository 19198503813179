export const parseSticker = (sticker) => {
  sticker.canvas = sticker.canvasJson ? JSON.parse(sticker.canvasJson) : {};
  return sticker;
}

export default {
  // user_sticker_product_design (DIY products)
  upsertProductDesign(state, productDesign) {
    productDesign.updatedAt = new Date();
    const targetSticker = state.userStickers.find(ud => ud.id == productDesign.stickerId);
    if (targetSticker) {
      targetSticker.designs = targetSticker.designs || [];
      const idx = targetSticker.designs.findIndex(d => d.productId == productDesign.productId);
      if (idx !== -1) {
        targetSticker.designs.splice(idx, 1, productDesign);
      } else {
        targetSticker.designs.push(productDesign);
      }
    }
  },

  // user_sticker
  setLoadingUserStickers(state, isLoading) {
    state.loadingUserStickers = isLoading;
  },
  updateSticker(state, { stickerId, updatedObj }) {
    if (stickerId) {
      const sticker = state.userStickers.find(d => d.id == stickerId);
      if (sticker) {
        for (const key in updatedObj) sticker[key] = updatedObj[key];
      }
    } else {
      for (const key in updatedObj) state.newSticker[key] = updatedObj[key];
    }
  },
  resetNewSticker(state) {
    state.newSticker = {
      outputPhoto: null,
      cropperData: null,
    };
  },
  receiveNewUserSticker(state, data) {
    state.newSticker = data;
  },

  addNewUserStickers(state, data) {
    data.forEach(sticker => {
      const existingRecord = state.userStickers.find(s => s.id == sticker.id);
      if (existingRecord == null) state.userStickers.push(parseSticker(sticker));
    });
    state.loadingUserStickers = false;
  },
  upsertUserSticker(state, userSticker) {
    userSticker.updatedAt = new Date();
    const idx = state.userStickers.findIndex(ul => ul.id == userSticker.id);
    if (idx !== -1) {
      state.userStickers.splice(idx, 1, userSticker);
    } else {
      state.userStickers.push(userSticker);
    }
  },
  updateUserSticker(state, { userStickerId, updatedObj }) {
    const userSticker = state.userStickers.find(p => p.id == userStickerId);
    if (userSticker) {
      for (const key in updatedObj) userSticker[key] = updatedObj[key];
    }
  },
  deleteUserSticker(state, targetUserStickerId) {
    const idx = state.userStickers.findIndex(ul => ul.id == targetUserStickerId);
    if (idx !== -1) state.userStickers.splice(idx, 1);
  },
  
}