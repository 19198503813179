import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2545d5e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "valign section-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.listHeader)
      ? (_openBlock(), _createBlock(_component_ion_text, {
          key: 0,
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_1, [
              _createVNode(_component_ion_icon, {
                class: "icon-28",
                icon: $setup.image
              }, null, 8, ["icon"]),
              _createTextVNode("  " + _toDisplayString($props.listHeader), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        ($props.imageLinks)
          ? (_openBlock(), _createBlock(_component_swiper, {
              key: 0,
              modules: $setup.modules,
              "slides-per-view": $setup.ops.slidesPerView,
              breakpoints: $setup.ops.breakpoints,
              spaceBetween: 10
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.imageLinks, (link) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: link,
                    onClick: ($event: any) => ($setup.openImageModal(link, $props.defaultCaption))
                  }, {
                    default: _withCtx(() => [
                      (!$props.hideEyeIcon)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            class: "eye-icon",
                            icon: $setup.eye
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true),
                      ($props.size == 'small')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            class: "slide-img-small",
                            src: $setup.addResizeUrlParams(link, 200)
                          }, null, 8, _hoisted_2))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "slide-img",
                            src: $setup.addResizeUrlParams(link, 300)
                          }, null, 8, _hoisted_3))
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modules", "slides-per-view", "breakpoints"]))
          : ($props.photos)
            ? (_openBlock(), _createBlock(_component_swiper, {
                key: 1,
                modules: $setup.modules,
                "slides-per-view": $setup.ops.slidesPerView,
                breakpoints: $setup.ops.breakpoints,
                spaceBetween: 10
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.photos, (photo) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, {
                      key: photo,
                      onClick: ($event: any) => ($setup.openImageModal(photo.link, photo.description))
                    }, {
                      default: _withCtx(() => [
                        (!$props.hideEyeIcon)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              class: "eye-icon",
                              icon: $setup.eye
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        ($props.size == 'small')
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              class: "slide-img-small",
                              src: _ctx.link
                            }, null, 8, _hoisted_4))
                          : (_openBlock(), _createElementBlock("img", {
                              key: 2,
                              class: "slide-img",
                              src: photo.link
                            }, null, 8, _hoisted_5))
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules", "slides-per-view", "breakpoints"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}