
// Vue reactivity
import { defineComponent, onMounted, ref } from 'vue';

// icons
import { close, arrowBack, checkmark, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonButtons, IonButton, IonIcon,
        IonSpinner,
         modalController,
         loadingController, } from '@ionic/vue';

import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { useI18n } from 'vue-i18n';
import StickerService from '@/services/StickerService';

export default defineComponent({
  name: 'ImageCropperModal',
  props: [
    "imageLink",
    "caption",
    "aspectRatio",
    "cropperData",
    "previewHeight",
    "previewWidth",
    "croppedCanvasOpts",
    "rembg",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonButtons, IonButton, IonIcon, IonSpinner, },
  setup(props) {
    const { t } = useI18n();
    const loadingCropper = ref(true);

    let cropper;

    const closeModal = async (returnCropperData = false) => {
      let data = null;

      if (returnCropperData) {
        const { croppedCanvasOpts, rembg } = props;
        const originalImgDataURL = cropper.getCroppedCanvas(croppedCanvasOpts || {
          minWidth: 600,
          minHeight: 1000,
          maxWidth: 600,
          maxHeight: 1000,
        }).toDataURL('image/jpeg');

        data = {
          customPhoto: originalImgDataURL,
          rembgCustomPhoto: "",
          cropperData: JSON.stringify({
            cropBoxData: cropper.getCropBoxData(),
            canvasData: cropper.getCanvasData(),
          }),
          croppedCanvasDataURL: originalImgDataURL,
        }
        if (rembg == true) {
          const loading = await loadingController.create({ message: t('processingImg') });
          await loading.present();
          data.rembgCustomPhoto = await StickerService.removeBgFromImg(originalImgDataURL);
          loading.dismiss();
        }
      }
      await modalController.dismiss(data);
    };

    const initCropper = async (cropperData: any = null) => {
      const image: any = document.querySelector('#main-photo');
      cropper = new Cropper(image, {
        viewMode: 0,
        aspectRatio: props.aspectRatio || null, // default freemode
        dragMode: 'move',
        minCropBoxWidth: 45,
        minCropBoxHeight: 55,
        minContainerWidth: 320,
        minContainerHeight: 400,
        preview: '#cropped-photo-container',
        cropend(event) {
          return;
        },
        zoom(event) {
          return;
        },
        ready() {
          loadingCropper.value = false;
          if (cropperData) {
            const { cropBoxData, canvasData } = JSON.parse(cropperData);
            if (cropBoxData || canvasData) {
              setTimeout(() => {
                if (canvasData) cropper.setCanvasData(canvasData);
                if (cropBoxData) cropper.setCropBoxData(cropBoxData);
              }, 250);
            }
          }
        },
      });
    }

    onMounted(() => {
      initCropper(props.cropperData);
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, close, checkmark,

      // variables
      loadingCropper,
      
      // methods
      t, closeModal,
    }
  }
});
