export default {
  // CART ITEMS
  upsertCartItem(state, updatedCartItem) {
    const idx = state.cartItems.findIndex(item => item.productId == updatedCartItem.productId);
    if (idx !== -1) {
      state.cartItems.splice(idx, 1, updatedCartItem);
    } else {
      state.cartItems.push(updatedCartItem);
    }
  },
  deleteCartItem(state, productId) {
    const idx = state.cartItems.findIndex(item => item.productId == productId);
    if (idx !== -1) state.cartItems.splice(idx, 1);
  },
  deleteCartItems(state, products) {
    products.forEach(product => {
      const idx = state.cartItems.findIndex(item => item.productId == product.id);
      if (idx !== -1) state.cartItems.splice(idx, 1);
    })
  }
}