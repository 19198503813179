// customized actions

import CommonService from '@/services/CommonService';
import InfoService from '@/services/InfoService';
import PlaceService from '@/services/PlaceService';
import UserService from '@/services/UserService';
import CartService from '@/services/CartService';
import ProductService from '@/services/ProductService';
import { Capacitor } from '@capacitor/core';

// PRODUCT ACTIONS
export const fetchProductDetails = ({ commit }, { id, e }) => {
  ProductService.getProductById(id).then(res => {
    if (res) {
      res.fetchedDetails = true;
      commit('upsertProducts', [res]);
    }
    if (e) e.target.complete();
  });
}
export const addUserBrowsedProduct = ({ state, getters, commit }, productId) => {
  if (!state.user.browsedProductIds.includes(productId)) {
    commit('addUserBrowsedProduct', productId);
    ProductService.addUserBrowsedProduct(productId); // add to browse history if users log in
  }
}

// CART ACTIONS
export const addProductToCart = ({ state, getters, commit }, { product, addQty = 1, variant = {} }) => {
  const existingCartItem = getters.getCartItem(product.id, variant.id);
  if (existingCartItem) { // update existing cart item
    const newQty = +existingCartItem.quantity + addQty;
    commit('upsertCartItem', {
      ...existingCartItem,
      quantity: newQty,
    });
    if (state.loggedIn) {
      CartService.updateUserCartItem(product.id, newQty, variant.id);
    }
  } else { // add new cart item
    commit('upsertCartItem', {
      productId: product.id,
      quantity: addQty,
      //unitPrice: variant.price || product.sellingPrice, // if selected product variant use its price instead
      variantId: variant.id,
      variantTitle: variant.title,
    });
    if (state.loggedIn) {
      CartService.addNewCartItem(product.id, addQty, variant.id);
    }
  }
}
export const removeProductsFromCart = ({ state, commit }, { products }) => {
  commit('deleteCartItems', products);
  if (state.loggedIn) {
    CartService.removeUserCartItems(products.map(p => p.id));
  }
};

/**
 * INFO ACTIONS
 */
export const fetchLatestNews = ({ commit }, refresherEv) => {
  CommonService.getPublicData().then(res => {
    const { infos, infoTypes, infoSources, } = res;
    commit('receiveInfos', infos);
    commit('receiveInfoTypes', infoTypes);
    commit('receiveInfoSources', infoSources);

    if (refresherEv) refresherEv.target.complete(); // dismiss ion-refresher
  });
}
export const addUserBrowsedInfo = ({ commit }, { id }) => {
  InfoService.addUserBrowsedInfo(id);
  commit('addUserBrowsedInfo', id);
}
export const updateUserLikedInfo = ({ commit }, { info, id, action }) => {
  if (action == 'add') InfoService.addUserLikedInfo(id);
  else InfoService.removeUserLikedInfo(id);
  info.likedByUser = (action == 'add');
  const updateObj = { likedByUser: (action == 'add') };
  commit('updateInfo', { id, updateObj });
}

/**
 * PLACE ACTIONS
 */
export const fetchPlaceDetails = ({ commit }, data) => {
  PlaceService.getPlaceById(data.id).then(res => {
    commit('receivePlace', res);
  });
}
export const updateUserLikedPlace = ({ commit }, { place, id, action }) => {
  if (action == 'add') PlaceService.addUserLikedPlace(id);
  else PlaceService.removeUserLikedPlace(id);
  place.likedByUser = (action == 'add');
  const updateObj = { likedByUser: (action == 'add') };
  commit('updatePlace', { id, updateObj });
}

/**
 * USER ACTIONS
 */
export const updateUserStickerImgVer = ({ state }, newImgVer) => {
  state.user.stickerImageDataVer = newImgVer;
  UserService.updateLoggedInUser({ stickerImageDataVer: newImgVer });
}
export const updateUserLastCheckNotificationTime = ({ state, commit }) => {
  state.user.lastCheckNotificationsAt = new Date();
  UserService.updateLastCheckNotificationTime();
}

export const getUserData = ({ commit }, { firebaseUser, i18n }) => {
  commit('setLoadingUserData', true);
  UserService.getUserData().then(res => {
    res.user = res.user[0]; // first row
    const { user } = res;
    if (user) {
      // update language & theme settings on login APP
      if (i18n && user.preferredLang) i18n.global.locale = user.preferredLang.toLowerCase();
      if (!user.becomeAppUserAt && Capacitor.isNativePlatform()) {
        UserService.updateLoggedInUser({ becomeAppUserAt: new Date() }, user);
      }
    } else {
      // user record not exist in DB yet right after registration
      res.user = {
        id: firebaseUser.uid,
        email: firebaseUser.email,
        profilePic: "https://ionicframework.com/docs/demos/api/avatar/avatar.svg",
        preferredLang: i18n ? i18n.global.locale : "zh",
        darkTheme: "N",
        maxStickerCount: 30,
      };
    }
    commit('receiveAppUserData', res);
  });
}

export const createNewUser = ({ commit }, newUser) => {
  UserService.createNewUser(newUser).then(newUserRow => {
    commit('receiveUser', newUserRow); // this updates projects related to user as well
  });
}