
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';

// icons
import { arrowBack, close, shareSocialOutline, downloadOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
        IonSpinner, IonList, IonItem, IonLabel, IonInput, IonTextarea,
        modalController, loadingController, 
        isPlatform } from '@ionic/vue';
import ImageModal from '@/components/modals/ImageModal.vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import PetService from '@/services/PetService';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';
import { Filesystem, Directory } from '@capacitor/filesystem';
        
export default defineComponent({
  name: 'PetPosterModal',
  props: ["pet", "posterType", "title"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                IonSpinner, IonList, IonItem, IonLabel, IonInput, IonTextarea, },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const user = computed(() => store.state.user);

    const { getPetAge, presentToast, presentPrompt, loadHTMLImg, getProxyImgLink, } = utils();

    let imgModalOpened = false;
    
    const userPet = reactive({
      lostPetPosterName: "",
      lostPetPosterBreed: "",
      contactPersonPhone: "",
      contactPersonName: "",
      lostLocation: "",
      remarkFeatures: "",
    });

    const stageRef = ref(null);
    const sceneWidth = 300;
    const sceneHeight = 424.2; // A4 size
    const posterContentWidth = sceneWidth - 50;
    const loadingCanvas = ref(true);
    const canvas = reactive({
      fontSize: 13,
      bgImg: null,
      petImg: null,
    });
    let loadCanvasTimeout: any = null;
    const LOAD_TIMEOUT = 3000;

    const getCanvasDataURL = () => {
      return stageRef.value ? stageRef.value.getStage().toDataURL({
        mimeType: "image/jpeg",
        quality: 0.6,
        pixelRatio: 2.5,
        x: 0,
      }) : "";
    }
    const setCanvasLoaded = () => {
      if (loadCanvasTimeout) clearTimeout(loadCanvasTimeout);
      loadingCanvas.value = true; // bug fix prevent empty canvas
      loadCanvasTimeout = setTimeout(() => {
        loadingCanvas.value = false;
      }, LOAD_TIMEOUT);
    }
    const loadImg = (imgLink: any, targetVarField: any) => {
      loadHTMLImg(imgLink, (img: any) => {
        canvas[targetVarField] = img;
        setCanvasLoaded();
        
        if (targetVarField == 'petImg') { // sync poster image to cloud DB
          setTimeout(() => {
            PetService.updateUserPet({
              userPetId: props.pet.id,
              updatedObj: {},
              petLostPosterDataURL: getCanvasDataURL(),
            });
          }, LOAD_TIMEOUT * 2);
        }
      });
    }

    const syncPosterInfoToUserPet = async (checkPromptSave = false) => {
      // sync poster info to DB & store
      const checkKeys = ["remarkFeatures", "lostLocation", "contactPersonName", "contactPersonPhone", "lostPetPosterBreed", "lostPetPosterName"];
      const updated = checkKeys.some(key => userPet[key] != props.pet[key]);
      if (updated) {
        if (checkPromptSave) {
          const res = confirm('要儲存 尋寵啟事 資料改動嗎？');
          if (!res) return false;
        }
        const loading = await loadingController.create({});
        await loading.present();

        // update only if values changed
        const { contactPersonName, contactPersonPhone, remarkFeatures, lostLocation, lostPetPosterName, lostPetPosterBreed } = userPet;
        PetService.updateUserPet({
          userPetId: props.pet.id,
          updatedObj: {
            "contact_person_name": contactPersonName,
            "contact_person_phone": contactPersonPhone, 
            "remark_features": remarkFeatures,
            "lost_location": lostLocation,
            "lost_pet_poster_name": lostPetPosterName,
            "lost_pet_poster_breed": lostPetPosterBreed,
          },
          petLostPosterDataURL: getCanvasDataURL(),
        });
        store.commit('updateUserPet', { userPetId: props.pet.id, updatedObj: {
          remarkFeatures, lostLocation, contactPersonName, contactPersonPhone, lostPetPosterName, lostPetPosterBreed,
        } });

        loading.dismiss();
        presentToast( t('saveSuccess'), 3000, 'bottom' );
      }
    }

    const closeModal = async () => {
      // check sync the data to DB
      await syncPosterInfoToUserPet(true);

      // close the modal
      await modalController.dismiss();
    };
    
    const shareCanvasImg = async () => {
      const options = {
        subject: '尋寵啟事', // fi. for email
        files: [getCanvasDataURL()], // an array of filenames either locally or remotely
        //url: 'https://mlol.pet/go',
      };
      if (!isPlatform('ios')) {
        options['message'] = '我的寵物走失了，懇請大家多多幫忙留意一下，如有發現請通知我，感謝！🙏🙏 (https://mlol.pet/go)';
      }
      await SocialSharing.shareWithOptions(options);
    }
    const saveCanvasImg = async () => {
      const dataUrl = getCanvasDataURL();
      if (isPlatform('hybrid')) {
        const loading = await loadingController.create({});
        await loading.present();
        if (isPlatform('ios')) {
          await SocialSharing.saveToPhotoAlbum([dataUrl]);
        } else {
          const fileName = `${new Date().getTime()}.jpeg`;
          await Filesystem.writeFile({
            path: fileName,
            data: dataUrl,
            directory: Directory.Documents
          });
        }
        presentToast(t('saveSuccess'));
        loading.dismiss();
      }
      else {
        const a = document.createElement("a"); //Create <a>
        a.href = dataUrl;
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
      }
    }

    const handleStageMouseDown = async (e: any) => {
      if (!imgModalOpened) {
        imgModalOpened = true;
        const modal = await modalController.create({
          component: ImageModal,
          componentProps: { imageLink: getCanvasDataURL() },
        });
        modal.onDidDismiss().then(() => {
          imgModalOpened = false;
        });
        return modal.present();
      }
      //return;
    }

    onMounted(() => {
      const { pet, posterType } = props;
      if (posterType == 'lostPet') {
        loadImg(require('@/assets/template_lost_pet_poster.jpg'), 'bgImg');
      }
      loadImg(pet.photoLink, 'petImg');

      const { name: userName, phone } = user.value;
      const { name: petName, breed, remarkFeatures, lostLocation, contactPersonName, contactPersonPhone,
              lostPetPosterBreed, lostPetPosterName } = pet;
      
      userPet.contactPersonName = contactPersonName || userName;
      userPet.contactPersonPhone = contactPersonPhone || phone;
      userPet.lostLocation = lostLocation;
      userPet.remarkFeatures = remarkFeatures;
      userPet.lostPetPosterBreed = lostPetPosterBreed || breed.replace(/[A-Z]/gi, "").trim();
      userPet.lostPetPosterName = lostPetPosterName || petName;
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, close, shareSocialOutline, downloadOutline,

      // variables
      user, userPet,
      stageRef,
      sceneWidth, sceneHeight, posterContentWidth,
      canvas, loadingCanvas,
      
      // methods
      t, closeModal,
      shareCanvasImg, saveCanvasImg,
      handleStageMouseDown,
      getPetAge, syncPosterInfoToUserPet,
    }
  }
});
