const placeHolderLogo = require('@/assets/no_image.jpeg');

export default {
  // 新聞來源
  receiveInfoSources(state, data) {
    state.infoSources = data;
  },

  // AI資訊類別
  receiveInfoTypes(state, data) {
    state.infoTypes = [...new Set(data.map(r => r.type))];
    state.infoKeywords = data;
  },
  
  // AI資訊
  receiveInfos(state, data) {
    const user = state.user;
    state.infos = data.map(info => {
      if (user && user.id) info.likedByUser = (user.relatedUserLikedInfos || "").replace(user.id, "").includes(info.id)
      return info;
    });
  },

  // Update info
  updateInfo(state, { id, updateObj }) {
    const idx = state.infos.findIndex(info => info.id == id);
    if (idx !== -1) {
      state.infos.splice(idx, 1, { ...state.infos[idx], ...updateObj });
    }
  },

  // Add user recently browsed infos
  addUserBrowsedInfo(state, objId) {
    let currUsrObjIds = state.user.relatedUserBrowsedInfos || "";
    const newUsrObjId = `${state.user.id}: ${objId}`;
    if (currUsrObjIds == "") currUsrObjIds = [newUsrObjId]; // first product
    else {
      currUsrObjIds = currUsrObjIds.split(" , ").filter(usrObjId => usrObjId.split(": ")[1] != objId);
      currUsrObjIds.unshift(newUsrObjId);
    }
    state.user.relatedUserBrowsedInfos = currUsrObjIds.join(" , ");
  },
}