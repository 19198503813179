export default {
  // Bid
  insertAuctionBid(state, { productId, bid }) {
    const product = state.products.find(p => p.id == productId);
    if (product && product.auction) {
      product.auction.bids.unshift(bid);
    }
  },

  // Auction
  updateAuction(state, { productId, updatedAuction }) {
    const product = state.products.find(p => p.id == productId);
    if (product && product.auction) {
      for (const key in updatedAuction) {
        product.auction[key] = updatedAuction[key];
      }
    }
  },

  // Notifications
  updateUserSubscribedAuction(state, { auctionId, action }) {
    const { subscribedAuctionIds } = state.user;
    if (action == 'subscribe') state.user.subscribedAuctionIds.push(auctionId);
    else state.user.subscribedAuctionIds = subscribedAuctionIds.filter(id => id != auctionId);
  },
}