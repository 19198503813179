export default {
  // customized state variables

  // BAND
  bandPosts: [],

  // Loading status
  loadingAppPublicData: true,
  loadingAppUserData: true,

  // Bottom tabs
  tabBarType: "pet",
  forceHideBottomTabs: false,

  // Sticker
  stickerTemplates: [],
  userStickers: [],
  loadingUserStickers: true,
  newSticker: {
    outputPhoto: null,
    cropperData: null,
  },

  // DIY
  materials: [],
  materialCategories: [],

  // Checkout
  pickupPoints: [],
  districts: [],

  // Products
  products: [],
  productCategories: [],
  loadingProducts: true,

  // User Certificates
  userCertificates: [],

  // User Discounts
  userDiscounts: [],

  // User Orders
  userOrders: [],

  // Pets
  pets: [],
  userPets: [],

  // Places
  placeTypes: [],
  places: [],
  isSelectingComparingPlaces: false,

  // Infos
  infoTypes: [],
  infoKeywords: [],
  infoSources: [],
  infos: [],

  // User
  user: {},
  loggedIn: false,
  isMobileApp: null,
  devicePlatforms: [],
  userLatLong: null,

  // App info
  banners: [],
  settings: {
    homeFlowerBtnText: "參加寵物保鮮花比賽",
    homeIdCardBtnText: "立即領取您的寵物身份證",
  },
  homeOpenPetFormModal: false,
  homeOpenStickerPreQuestionModal: false,

  // Notifications
  allNotifications: [],
  loadingNotifications: true,

  // Cart
  cartItems: []
}