import { createApp } from 'vue'
import AppComponent from '@/App.vue'
import { routes } from '@/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import './registerServiceWorker';

import { IonicVue, getPlatforms, isPlatform, alertController, toastController, } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
/*import '@ionic/vue/css/float-elements.css';*/
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/global.css';
import '@/theme/custom.css';

import '@vueform/multiselect/themes/default.css';

/* Firebase */
import { auth } from '@/auth';

/* Supabase (mainly for Realtime) */
import { createClient } from '@supabase/supabase-js'

/* vue-i18n */
import { createI18n, useI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import store from '@/store'

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SplashScreen } from '@capacitor/splash-screen';
import { Geolocation } from '@capacitor/geolocation';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';

import CommonService from '@/services/CommonService';
import ProductService from './services/ProductService';

// Konva
import VueKonva from 'vue-konva';

// Lucky Canvas
import VueLuckyCanvas from '@lucky-canvas/vue'

let lastFetchedData = null;
const MIN_FETCH_INTERVAL = 300;

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages,
  silentTranslationWarn: true
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(AppComponent)
  .use(store)
  .use(i18n)
  .use(IonicVue, { mode: 'md', innerHTMLTemplatesEnabled: true })
  .use(router)
  .use(VueKonva)
  .use(VueLuckyCanvas)

// Global Component (for nested import)
import PageHeader from '@/components/headers/PageHeader.vue';
import AppDownloadToolbar from '@/components/headers/AppDownloadToolbar.vue';
import ProductCard from '@/components/product/ProductCard.vue';
app.component('PageHeader', PageHeader);
app.component('AppDownloadToolbar', AppDownloadToolbar);
app.component('ProductCard', ProductCard);

const supabase = createClient(
  "https://nsgqjupwkaibdxetfeat.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5zZ3FqdXB3a2FpYmR4ZXRmZWF0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTY1ODQ0NzIsImV4cCI6MTk3MjE2MDQ3Mn0.7ddFfCxvTzi1igtIfHiKv_-SmmB40yrMP6SlyhM_hUA"
);
app.provide('supabase', supabase)

auth.languageCode = 'zh-HK';

router.isReady().then(async () => {
  // In Coral Page
  if (["/products", "/verify-certificate", "/certificates"].some(p => router.currentRoute.value.path.includes(p))) {
    store.commit('setTabBarType', "coral");
  }

  // lock the screen orientation (always portrait)
  ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  
  // default locale / translation
  i18n.global.locale = navigator.language.split('-')[0].toLowerCase() == "en" ? "en" : "zh";
  const tl = (zhText: any, enText: any) => (i18n.global.locale == 'en' ? enText : zhText);

  // check network status
  let status = await Network.getStatus();
  if (!status.connected) SplashScreen.hide();
  while (!status.connected) {
    const alert = await alertController.create({
      backdropDismiss: false,
      message: tl('手機尚未連接至網絡，請重試。', 'Seems like there is a network connection problem. Please try again'),
      buttons: [
        {
          text: tl('重試', 'Retry'),
          handler: async () => {
            status = await Network.getStatus();
          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  }
  
  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getHomePageData().then(res => {
  //CommonService.getPublicData().then(res => {
    store.commit('receiveAppPublicData', res);
    
    setTimeout(() => {
      SplashScreen.hide();
    }, 100);

    // PRODUCT DATA
    ProductService.getAllProducts().then(res => {
      store.commit('receiveAllProducts', res);
    })

    // NON HOME PAGE DATA
    CommonService.getPublicData(true).then(res => {
      store.commit('receiveAppPublicData', res);
    });
    // compare app version with latest store versions (prompt update)
    if (!isPlatform('mobileweb') && (isPlatform('ios') || isPlatform('android'))) {
      const { storeAppVerAndroid, storeAppVerIos, appStoreLink, playStoreLink, } = res.settings;
      App.getInfo().then(async (res) => {
        const { version: appVersion } = res;
        const storeAppVer = isPlatform('ios') ? storeAppVerIos : storeAppVerAndroid;
        if (storeAppVer && appVersion < storeAppVer) {
          const alert = await alertController.create({
            header: tl("更新應用程式", "App Updates"),
            message: tl("檢測到新的應用程式版本，要現在更新?", "A new version of this app is available. Do you want to update now?"),
            buttons: [
              {
                text: tl("稍候", "Later"),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: tl("現在更新", "Update Now"),
                handler: () => {
                  window.open( isPlatform('ios') ? appStoreLink : playStoreLink );
                  return true;
                },
              }
            ]
          });
          return alert.present();
        }
      });
    }

    // request user location permission
    Geolocation.getCurrentPosition().then(coordinates => {
      console.log(coordinates);
      const { latitude, longitude } = coordinates.coords;
      store.commit('setUserLatLong', { latitude, longitude });
    });
    
  });
  store.commit('receiveDevicePlatforms', getPlatforms());

  auth.onAuthStateChanged((user: any) => {
    if (user) { // logged in
      store.dispatch('getUserData', { firebaseUser: user, i18n });

      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const redirectPath: any = router.currentRoute.value.query.redirectPath;
      if (redirectPath) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (config.authPages.includes(router.currentRoute.value.path)) {
        router.replace('/'); // go to home page if the user in auth pages
      }
      CommonService.getAllNotifications().then(res => {
        store.commit('receiveNotifications', res);
      })
    } else { // logged out
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId("");
      }
      store.commit('resetUserData');
      const currentPath = router.currentRoute.value.path;
      if (config.memberPages.includes(currentPath)) {
        router.replace({ path: '/login', query: { redirectPath: currentPath } });
      }
    }
  });
  app.mount('#app');
  
  /* Check App state & events (e.g. enter App foreground & refresh data) */
  App.addListener('appStateChange', ({ isActive }) => {
    if (isActive) {
      const targetPages = store.state.settings.noRefreshDataPages?.split(" , ") || [];
      const currPageName: any = router.currentRoute.value.name;
      if (!targetPages.includes(currPageName)) {
        if (lastFetchedData == null || ((new Date()).getTime() - lastFetchedData.getTime()) / 1000 > MIN_FETCH_INTERVAL) {
          lastFetchedData = new Date();
          ProductService.getAllProducts().then(res => {
            store.commit('receiveAllProducts', res);
          });
          CommonService.getPublicData().then(res => {
            store.commit('receiveAppPublicData', res);
            
            Geolocation.getCurrentPosition().then(coordinates => {
              const { latitude, longitude } = coordinates.coords;
              store.commit('setUserLatLong', { latitude, longitude });
            });
          });
          if (auth.currentUser) { // logged in users
            store.dispatch('getUserData', { firebaseUser: auth.currentUser, i18n });
          }
        }
      }
    }
  });

  /* Check App Deeplink & do redirect */
  App.addListener('appUrlOpen', (data) => {
    const slug = data.url.split(/\.app|\.pet/).pop();
    if (slug) {
      router.replace(slug);
    }
  });

  /**
   * OneSignal
   **/
  window["plugins"].OneSignal.setAppId(config.OneSignal.appId);
  window["plugins"].OneSignal.setNotificationOpenedHandler((jsonData) => {
    // triggerred on tap notifications from devices
    const { additionalData } = (jsonData.notification.payload || {});
    if (additionalData) {
      const { app_place_id: placeId, app_external_link: externalLink, app_notification_id: notificationId,
              app_product_id: productId, checkout_order_id: checkoutOrderId } = additionalData;
      if (productId) {
        router.replace(`/products/${productId}`);
      }
      else if (checkoutOrderId) {
        router.replace(`/checkout/${checkoutOrderId}`);
      }
      else if (placeId) {
        router.replace(`/places/${placeId}`);
      }
      else if (externalLink) {
        router.replace({ name: 'InfoOverviewPage', state: { targetInfoLink: externalLink } }); // go to information list page and open specific info
      }
      else if (notificationId) {
        CommonService.getAllNotifications().then(res => {
          store.commit('receiveNotifications', res); // refresh list of notifications
        })
        router.replace(`/notifications/${notificationId}`);
      }
    }
  });

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse((accepted: any) => {
    console.log("User accepted notifications: " + accepted);
  });
});

router.beforeEach((to, from) => {
  if (auth.currentUser == null) { // guests (not logged in)
    if (config.memberPages.includes(to.path) && !config.authPages.includes(to.path)) {
    //if (!config.publicPages.includes(to.path) && !config.authPages.includes(to.path)) { // force go to login page
      const redirectPath = to.path;
      return { path: '/login', query: { redirectPath } };
      //return { path: '/login' };
    }
  } else { // logged in users
    if (to.path == '/notifications') {
      // update user last click notification tab time
      store.dispatch('updateUserLastCheckNotificationTime');
    }
  }
})