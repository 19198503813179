// STICKERS

export const getStickerById = (state) => (id) => {
  return state.userStickers.find(d => d.id == id) || {};
}
export const orderedUserStickers = (state) => {
  return state.userStickers.sort((a, b) => {
    const timeA = new Date(a.updatedAt), timeB = new Date(b.updatedAt);
    return timeA > timeB ? -1 : (timeA < timeB ? 1 : 0);
  })
}