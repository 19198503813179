// customized getters

// CORAL
export const getCertificateById = (state) => (id) => {
  return state.userCertificates.find(c => c.id == id) || {};
}

// ORDERS
export const getOrderById = (state) => (id) => {
  return state.userOrders.find(o => o.id == id) || {};
}

// LANDING PAGE BANNERS
export const landingTopBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'landing-top').sort((a, b) => a.order - b.order);
}
export const landingMiddleBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'landing-middle').sort((a, b) => a.order - b.order);
}
export const landingBottomBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'landing-bottom').sort((a, b) => a.order - b.order);
}

// MLP HOME BANNERS
export const homeBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'home').sort((a, b) => a.order - b.order);
}
export const topBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'top').sort((a, b) => a.order - b.order);
}

// NOTIFICATIONS
export const getNotificationById = (state) => (id) => {
  return state.allNotifications.find(n => n.id == id) || {};
}

export const numOfNewNotifications = (state, getters) => {
  return getters.notificationsRelatedToUser.filter(notification => {
    return state.user.lastCheckNotificationsAt ? 
          new Date(notification.scheduledToSendAt) > new Date(state.user.lastCheckNotificationsAt) : true;
  }).length;
}