
// Vue reactivity
import { ref, defineComponent, watchEffect, onMounted, onBeforeUnmount } from 'vue';

// icons
import { person, add, remove, close } from 'ionicons/icons';

// components
import { IonContent, IonFooter, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonList,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
        IonRow, IonCol, IonChip,
        modalController, loadingController, } from '@ionic/vue';
import AuctionBidList from "@/components/product/AuctionBidList.vue";

// API services
import AuctionService from '@/services/AuctionService';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import moment from 'moment';

export default defineComponent({
  name: 'PlaceBidModal',
  props: ["product", "userId", "onlineViewerCount"],
  components: { IonContent, IonFooter, IonItem, IonLabel, IonIcon,
                IonButtons, IonButton, IonInput, IonTextarea, IonRange, IonList,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
                IonRow, IonCol, IonChip,
                AuctionBidList, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const bidAmount = ref();
    const editingBidAmount = ref(false);
    const currentTime = ref(moment());
    let countdownInterval = null;

    // methods or filters
    const store = useStore();
    const { t, locale } = useI18n();
    const { presentToast, presentAlert, presentPrompt, getLocalizedStr, getTimeDiffText, openImageModal, closeModal, } = utils();

    const getMinBidAmount = () => {
      const { currentBid, bidIncrementAmount, startingPrice } = props.product.auction;
      return (currentBid || startingPrice) + bidIncrementAmount;
    };

    // Countdown timer
    onMounted(() => {
      countdownInterval = setInterval(() => {
        currentTime.value = moment();
      }, 1000);

      bidAmount.value = getMinBidAmount();
    });

    onBeforeUnmount(() => {
      if (countdownInterval) clearInterval(countdownInterval);
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      person, add, remove, close,

      // variables
      locale,
      currentTime,
      bidAmount, editingBidAmount,

      // methods
      t, getLocalizedStr,
      openImageModal, closeModal,
      getTimeDiffText,
      getMinBidAmount,
      submitBid: () => {
        const bidAmountStr = bidAmount.value.toLocaleString();
        const msg = locale.value == 'zh' ? `請確認您的出價為<b>HK$${bidAmountStr}</b>，<b>一經確認將不能取消</b>。出價即表示您同意我們的條款及細則。`
              : `Please confirm your bid of <b>HK$${bidAmountStr}</b>. The bid cannot be cancelled once it is confirmed. By placing a bid, you agree to our terms and conditions.`;
        presentPrompt(`${t('confirmPlaceBid')} HK$${bidAmountStr}`, msg, async () => {
          console.log(bidAmount.value);
          const { id, auction } = props.product;
          const loading = await loadingController.create({ duration: 60000 });
          await loading.present();
          const res = await AuctionService.placeBid(id, auction.id, bidAmount.value);
          console.log(res);
          if (res.success) {
            presentToast(t('successBid'));
          } else {
            if (res.errType == 'auctionEnded') {
              presentAlert(t('auctionClosed')); // Auction is over
            }
            else if (res.errType == 'bid') {
              const { currentBid, bidIncrementAmount } = res;
              presentAlert(`${t('invalidBid')} HK$${currentBid+bidIncrementAmount}`); // Incorrect bid amount
              store.commit('updateAuction', { id, updatedAuction: { currentBid, bidIncrementAmount } });
            }
            else if (res.errType == 'serverError') {
              presentAlert(t('serverError')); // Server Error
            }
            else {
              presentAlert(t('serverBusy')); // Server Busy
            }
          }
          loading.dismiss();
        }, "red-prompt-header");
      },
      decrementBidAmount: () => {
        const { bidIncrementAmount } = props.product.auction;
        if ((bidAmount.value - bidIncrementAmount) >= getMinBidAmount()) {
          bidAmount.value -= bidIncrementAmount;
        }
      },
      incrementBidAmount: () => {
        const { bidIncrementAmount } = props.product.auction;
        bidAmount.value = Math.max(getMinBidAmount(), bidAmount.value+bidIncrementAmount);
      },
      onFinishInputBidAmount: (e) => {
        editingBidAmount.value = false;
        const amount = Number(e.target.value);
        console.log(amount);
        bidAmount.value = Math.max(getMinBidAmount(), amount);
      },
      getCurrentBidAmountDiff: () => {
        const { currentBid, bidIncrementAmount } = props.product.auction;
        const diff = bidAmount.value-currentBid;
        if (diff < bidIncrementAmount && !editingBidAmount.value) {
          bidAmount.value = getMinBidAmount();
        }
        return bidAmount.value-currentBid;
      }
    }
  }
});
