import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 3,
  style: {"color":"var(--ion-color-dark)","white-space":"pre-line"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!

  return (['bid', 'result', 'notification'].includes($props.comment.type))
    ? (_openBlock(), _createBlock(_component_ion_badge, {
        key: 0,
        color: $props.comment.type == 'result' ? 'success' : 'primary'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.comment.content), 1)
        ]),
        _: 1
      }, 8, ["color"]))
    : ($props.comment.status == 'deleted')
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createElementVNode("i", null, [
            _createElementVNode("small", null, _toDisplayString($setup.t('PostPage.deletedContent')), 1)
          ])
        ]))
      : ($setup.isPostAuthorBanned($props.comment, $props.user))
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _createElementVNode("i", null, [
              _createElementVNode("small", null, _toDisplayString($setup.t('PostPage.blockedContent')), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.comment.content), 1))
}