
// Vue core
import { computed, ref, watch } from 'vue';

// icons
import { home, people, calendar, newspaper, compass, personCircle, informationCircle, notifications, scan,
        location, musicalNotes, buildOutline, storefrontOutline, cartOutline, qrCodeOutline, } from 'ionicons/icons';

// components
import { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge, IonImg, IonRouterOutlet, useBackButton, useIonRouter, onIonViewDidEnter } from '@ionic/vue';

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';

import { AppMinimize } from '@ionic-native/app-minimize';

// Lottie
import lottie from 'lottie-web';

export default {
  name: 'Tabs',
  components: { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge, IonImg, IonRouterOutlet, },
  setup() {
    const ionRouter = useIonRouter();
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const { imgIcons, authPages, hideBottomTabsPage, hideBottomTabsPageNames } = config;

    const hideTabs = ref(false);
    const forceHideBottomTabs = computed(() => store.state.forceHideBottomTabs);
    const numOfNewNotifications = computed(() => store.getters.numOfNewNotifications);
    const numOfCartItems = computed(() => store.getters.numOfCartItems);
    const settings = computed(() => store.state.settings);
    const currPath = computed(() => route.path);

    // For switching between Mall Lol Pet & Mall Lol Coral
    const tabBarType = computed(() => store.state.tabBarType);

    const checkHideTabs = (routePath: any) => {
      hideTabs.value = (
        //routePath.split("/").length > 2 || config.authPages.includes(routePath)
        authPages.includes(routePath) || hideBottomTabsPage.includes(routePath)
        || hideBottomTabsPageNames.includes((route.name || "").toString())
      );
    }
    checkHideTabs(route.path); // triggered on first enter APP

    // Hardware back button
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        AppMinimize.minimize();
      }
    });

    let animation;
    const checkLoadBackgroundAnimation = (path) => {
      const { showBackgroundAnimationPages } = settings.value;
      const targets = showBackgroundAnimationPages ? showBackgroundAnimationPages.split(" , ") : ['/', '/lucky-wheel', '/products'];
      if (targets.some(p => path.toString().split("/").slice(0, 2).join("/") == p)) {
        if (!animation) {
          animation = lottie.loadAnimation({
            container: document.querySelector('#cover-background'), // the DOM element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('@/assets/lottie/bubble_bg.json'), // the path to your animation file
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
              progressiveLoad: true, // Loads the animation progressively which can help with performance
            },
          });
        }
        //animation.setSpeed(0.5); // half speed
      } else {
        if (animation) {
          animation.destroy();
          animation = null;
        }
      }
    }
    onIonViewDidEnter(() => {
      checkLoadBackgroundAnimation(route.path);
    })

    watch(() => route.path, (newPath: any) => {
      checkHideTabs(newPath);
      checkLoadBackgroundAnimation(newPath);
    })
    
    return {
      // icons
      home, people, calendar, newspaper, compass, personCircle, informationCircle, notifications, scan,
      location, musicalNotes, buildOutline, storefrontOutline, cartOutline, qrCodeOutline,

      // variables
      tabBarType, // Pets / Corals
      hideTabs, forceHideBottomTabs,
      numOfNewNotifications, imgIcons,
      currPath, settings,
      numOfCartItems,

      // methods
      t,
      checkScrollToTop: () => {
        if (currPath.value == '/home') {
          document.querySelector(`#top-anchor`).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }
      }
    }
  }
}
