
// Vue reactivity
import { defineComponent } from 'vue';

// Swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Zoom } from 'swiper';

// icons
import { close, arrowBack } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, } from '@ionic/vue';

export default defineComponent({
  name: 'ImageModal',
  props: ["imageLink", "caption", "isPetCardImg"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                Swiper, SwiperSlide, },
  setup() {
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      
      // variables
      modules: [Zoom],
    }
  }
});
