<template>
  <ion-text color="warning">
    <ion-icon v-for="i in Math.floor(rating)" :key="i" :icon="star"></ion-icon>
    <ion-icon v-if="rating - Math.floor(rating) > 0" :icon="starHalfOutline"></ion-icon>
    <ion-icon v-for="i in (maxRating || 5)-Math.ceil(rating)" :key="i" :icon="starOutline"></ion-icon>
  </ion-text>
</template>

<script>

// icons
import { star, starHalfOutline, starOutline, } from "ionicons/icons";

// components
import { IonText, IonIcon, } from '@ionic/vue';

// composables
import config from '@/config';
import { utils } from '@/composables/utils';

export default {
  props: [
    "rating",
    "maxRating"
  ],
  components: { IonText, IonIcon, },
  setup() {

    return {
      // icons
      star, starHalfOutline, starOutline,

      // variables

      // methods
    }
  }
}
</script>

<style scoped>
</style>