import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dce90f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"calc(100vh - 110px)","overflow-y":"scroll"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_comment_user_chip = _resolveComponent("comment-user-chip")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_comment_content = _resolveComponent("comment-content")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('liveChat')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_chip, {
          class: "no-border",
          color: "success",
          slot: "end",
          id: "online-user-popover-trigger"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.person }, null, 8, ["icon"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createElementVNode("b", null, _toDisplayString(_ctx.onlineViewerCount.value), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { fixed: "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.value.comments, (comment, index) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  color: ['bid', 'result', 'notification'].includes(comment.type) ? 'warning' : undefined,
                  key: comment.id,
                  style: {"margin":"5px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_header, { style: {"padding":"8px 8px 0 8px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_subtitle, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" #" + _toDisplayString(index+1) + " ", 1),
                            _createVNode(_component_comment_user_chip, { comment: comment }, null, 8, ["comment"])
                          ]),
                          _: 2
                        }, 1024),
                        (!comment.type)
                          ? (_openBlock(), _createBlock(_component_ion_buttons, {
                              key: 0,
                              class: "top-right-badge"
                            }, {
                              default: _withCtx(() => [
                                (comment.status != 'deleted')
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      slot: "icon-only",
                                      onClick: _withModifiers(($event: any) => (_ctx.openPopover(comment, $event)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, { icon: _ctx.ellipsisVertical }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_card_content, { style: {"padding":"0 8px 8px 8px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_comment_content, {
                          comment: comment,
                          user: _ctx.user
                        }, null, 8, ["comment", "user"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["color"]))
              }), 128))
            ]),
            _createVNode(_component_ion_toolbar, {
              class: "fixed-bottom",
              style: _normalizeStyle({ 'bottom': `${_ctx.keyboardHeight}px` })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_textarea, {
                  "auto-grow": true,
                  shape: "round",
                  rows: 1,
                  fill: "outline",
                  placeholder: _ctx.t('addAComment'),
                  modelValue: _ctx.newCommentContent,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCommentContent) = $event))
                }, null, 8, ["placeholder", "modelValue"]),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      color: "success",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewComment())),
                      disabled: !_ctx.newCommentContent
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.send
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["style"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_popover, {
          trigger: "online-user-popover-trigger",
          size: "auto",
          "dismiss-on-select": false,
          "show-backdrop": false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onlineViewers.value, (v, i) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    lines: "full",
                    key: i
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        color: v.uid == _ctx.user.id ? 'primary' : 'success',
                        slot: "start",
                        icon: _ctx.personCircle
                      }, null, 8, ["color", "icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(v.name || 'User'), 1)
                        ]),
                        _: 2
                      }, 1024),
                      (v.uid == _ctx.user.id)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            size: "small",
                            icon: _ctx.arrowBack
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}