import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getPlatforms } from '@ionic/vue';
import { uniqueId, uploadImage, } from '@/services/utils';

export default {
  // Point
  async checkUserAvailablePoints(requestedPoints, orderAmount) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-checkUserAvailablePoints')({ requestedPoints, orderAmount })).data;
  },

  // Discount
  async getDiscount(discountCode: any, productIds, productCategoryIds) {
    const devicePlatforms = getPlatforms();
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getDiscount')({ discountCode, devicePlatforms, productIds, productCategoryIds })).data;
  },

  // Checkout / Payment
  async getOrderById(orderId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getOrderById')({ orderId })).data;
  },
  async createNewOrder(order: any, orderItems: any, gameSessionId = null) {
    /*
    const orderPhotoLinks = [];
    if (orderPhotos) {
      for (const photo of orderPhotos) {
        const link = await uploadImage(photo.base64Data, 'OrderPhoto', 'order');
        orderPhotoLinks.push(link);
      }
    }
    */
    order.id = `o_${uniqueId()}`;
    for (const product of orderItems) {
      const { designPhotoLink, previewPhotoLink } = product;
      if (designPhotoLink) product.designPhotoLink = await uploadImage(designPhotoLink, `${order.id}_${product.id}_design`, 'order');
      if (previewPhotoLink) product.previewPhotoLink = await uploadImage(previewPhotoLink, `${order.id}_${product.id}_preview`, 'order');
    }
    order.sourceName = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('order-createNewOrderV2')({ order, orderItems, gameSessionId, });
    return res.data;
  },
  async updateOrder(order: any, orderItems: any, prevOrder: any) {
    order.sourceName = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('order-updateOrder')({ order, orderItems, prevOrder });
    return res.data;
  },

  // Stripe
  async createPaymentIntent(currency: any, amount: any, paymentMethodType: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-createPaymentIntent')({ currency, amount, paymentMethodType })).data;
  },
  async retrievePaymentMethods(stripeCustomerId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-retrievePaymentMethods')({ stripeCustomerId, })).data;
  },
  async detachPaymentMethod(paymentMethodId: any, immediateDetach = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-detachPaymentMethod')({ paymentMethodId, immediateDetach })).data;
  },
  async verifyPaymentMethod(user, paymentMethodId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-verifyPaymentMethod')({ user, paymentMethodId })).data;
  },
}