
// Vue reactivity
import { defineComponent, ref } from 'vue';

// icons
import { arrowBack, close } from 'ionicons/icons';

// components
import { IonToolbar, IonContent, IonButtons, IonButton, IonIcon,
        IonSearchbar, IonList, IonItem, IonThumbnail, IonLabel,
        modalController } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
        
export default defineComponent({
  name: 'PetBreedSelectModal',
  props: ["petCategory", "pets"],
  components: { IonToolbar, IonContent, IonButtons, IonButton, IonIcon, 
                IonSearchbar, IonList, IonItem, IonThumbnail, IonLabel,
                },
  setup() {
    const { t } = useI18n();

    const searchKeyword = ref("");
    const isSearching = ref(false);

    const closeModal = async (selectedBreed: any = null) => {
      await modalController.dismiss({ selectedBreed });
    }

    const filterPets = (pets: any) => {
      return pets.filter((pet: any) => `${pet.alias} ${pet.breed} ${pet.breedEn.toLowerCase()}`.includes(searchKeyword.value.toLowerCase()))
                  .sort((a: any, b: any) => {
                    const textA = a.breedEn.toUpperCase(), textB = b.breedEn.toUpperCase();
                    if (textA == "") return 1;
                    if (textB == "") return -1;
                    return textA < textB ? -1 : (textA > textB ? 1 : 0); // put to back if empty
                  });
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, close,

      // variables
      searchKeyword, isSearching,

      // methods
      t, closeModal, filterPets,
    }
  }
});
