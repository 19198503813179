
// Vue reactivity
import { ref, defineComponent, watchEffect, onMounted } from 'vue';

// icons
import { add, close } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonRange,
        modalController, loadingController, } from '@ionic/vue';
import StarRating from 'vue-star-rating';

// API services
import ProductService from '@/services/ProductService';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'ProductReviewModal',
  props: ["product", "prefilledRating", "userReview"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonTextarea, IonRange,
                StarRating, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const rating = ref(0);
    const content = ref("");

    // methods or filters
    const { t } = useI18n();
    const { presentToast, closeModal } = utils();

    const upsertProductReview = async (productId: any, rating: any, content: string) => {
      const loading = await loadingController.create({});
      await loading.present();
      await ProductService.upsertProductReview(productId, rating, content, props.userReview);
      loading.dismiss();
      presentToast( t('ProductPage.successPublishProductReview'), 2000 );
      closeModal({ newProductReviewCreated: true });
    };

    onMounted(() => {
      const { prefilledRating, userReview } = props;
      if (userReview) {
        rating.value = userReview.rating;
        content.value = userReview.content;
      }
      if (prefilledRating) rating.value = prefilledRating;
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, upsertProductReview, closeModal,

      // icons
      add, close,

      // variables
      rating, content,
    }
  }
});
