
import { computed, ref } from 'vue';

// icons
import { chevronBackOutline, arrowBack, searchOutline, notificationsOutline, close, } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton,
        IonBackButton, IonIcon, IonTitle, IonLabel, modalController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  props: [
    "logoOnly",
    "titleOnly",
    "title",
    "parentPath",
    "hideIcons",
    "hideLogo",
    "showSearchIcon",
    "showCloseModal",
    "useTopRightCloseBtn",
    "showAppDownloadToolbar",
    "slotType",
  ],
  components: { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton,
                IonBackButton, IonIcon, IonTitle, IonLabel,
                LogoImg, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const userLoggedIn = computed(() => store.state.loggedIn);

    const onClickSearchBtn = async () => {
      router.push({ name: 'ProductListPage', state: { parentPath: '/home', from: "searchIcon" } }); // go to product page
    }

    return {
      // icons
      searchOutline, notificationsOutline, arrowBack, close,

      // variables
      userLoggedIn,

      // methods
      t, onClickSearchBtn,
      closeModal: async () => (await modalController.dismiss()),
    }
  }
}
