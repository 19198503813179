import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4a33f92"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('UserProfilePage.termsAndConditions')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { fixed: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, { style: {"padding":"0 16px 0 0"} }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  style: {"white-space":"pre-line"},
                  innerHTML: _ctx.type == 'auction' ? _ctx.getLocalizedStr(_ctx.settings, 'auctionTc', 'auctionTcEn')
                                        : _ctx.getLocalizedStr(_ctx.settings, 'orderTermsAndConditions', 'orderTermsAndConditionsEn')
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}