// PETS
export const petCategories = (state) => {
  const groupedPets = {};
  for (const pet of state.pets) {
    (groupedPets[pet.category] = groupedPets[pet.category] || []).push(pet);
  }
  return Object.keys(groupedPets); // group pets by categories (貓 / 狗 / ...)
}
export const groupedPets = (state) => {
  const groupedPets = {};
  for (const pet of state.pets) {
    const { breed, category } = pet;
    groupedPets[category] = groupedPets[category] || {};
    (groupedPets[category][breed] = groupedPets[category][breed] || []).push(pet);
  }
  return groupedPets;
}
export const orderedUserPets = (state) => {
  return state.userPets.sort((a, b) => {
    const timeA = new Date(a.updatedAt), timeB = new Date(b.updatedAt);
    return timeA > timeB ? -1 : (timeA < timeB ? 1 : 0);
  })
}