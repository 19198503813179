
// Vue reactivity
import { computed, } from 'vue';

// icons
import { arrowBackOutline, close, logIn, checkmarkCircle, chevronForward, alertCircle, } from 'ionicons/icons';

// components
import { IonContent, IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon, IonText,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
        IonList, IonListHeader, IonItem, IonLabel, IonButtons,
        modalController, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';
import PaymentInfo from '@/components/order/PaymentInfo.vue';
import OrderInfo from '@/components/order/OrderInfo.vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default {
  props: [
    "orderId"
  ],
  components: { IonContent, IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon, IonText,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
                IonList, IonListHeader, IonItem, IonLabel, IonButtons,
                LogoImg, PaymentInfo, OrderInfo, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const { t } = useI18n();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const settings = computed(() => store.state.settings);
    const loading = computed(() => store.state.loadingAppUserData);
    const order = computed(() => store.getters.getOrderById(props.orderId));

    // 2. methods or filters
    const { getLocalizedStr, ORDER_STATUSES, closeModal } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline, close, logIn, checkmarkCircle, chevronForward, alertCircle,

      // variables
      ORDER_STATUSES, settings,
      loading, order,

      // methods
      t, closeModal, getLocalizedStr,
    }
  },
}
