
// icons
import { } from 'ionicons/icons';

// components
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonThumbnail, IonSkeletonText, IonLabel, } from '@ionic/vue';

export default {
  props: [
    "useGrid",
    "numOfItems",
    "imgSlot"
  ],
  components: { IonGrid, IonRow, IonCol, IonList, IonItem, IonThumbnail, IonSkeletonText, IonLabel, },
  setup() {
    return {
      // icons

      // methods
    }
  }
}
