import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload

export const uniqueId = () => Math.random().toString(36).slice(-8);

export const compressImage = async (imageBase64Data: any, maxWidth = 2000, quality = 0.6) => {
  const response = await fetch(imageBase64Data);
  const blob = await response.blob();
  return await new Promise((resolve, reject) => {
    new Compressor(blob, {
      maxWidth,
      quality,
      success: resolve,
      error: reject,
    });
  });
}

export const uploadImage = async (imageBase64Data: any, imageTitle: string, targetTable = 'pet', noTimestamp = false) => {
  const imageFile: any = await compressImage(imageBase64Data);
  // upload the image file to Firebase Storage
  const extension = imageBase64Data.split(';')[0].split('/')[1];
  //const fileName = `${moment().format('YYYYMMDDHHmmss')}-${imageTitle.substring(0, 10)}.${extension}`; // WILL CAUSE COLLISION
  let fileName = `${imageTitle.substring(0, 20)}.${extension}`;
  if (!noTimestamp) fileName = `${new Date().valueOf()}-${fileName}`;
  const snapshot = await firebase.storage().ref(`${targetTable}-${fileName}`).put(imageFile);
  return await snapshot.ref.getDownloadURL();
}