import { alertController, toastController, loadingController, modalController, isPlatform } from '@ionic/vue';
import moment from 'moment';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { Share } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';

import ImageModal from '@/components/modals/ImageModal.vue';
import LoginModal from '@/components/auth/LoginModal.vue';

export function utils() {
  const store = useStore();
  const { t, locale } = useI18n();

  const PRODUCT_STATUSES = {
    available: 'available',
    hold: 'hold',
    pendingPayment: 'payment_pending',
    sold: 'sold',
    auctionClosed: 'auction_closed',
  }
  const INFO_TYPES = {
    clinic: '獸醫',
    emergency: '24小時獸醫',
    restaurant: '餐廳',
    garden: '寵物公園',
    grooming: '美容',
    shop: '寵物零售店',
    adopt: '領養',
    paradise: '善終',
  }
  const ORDER_STATUSES = {
    pendingCheckout: '待結帳',
    pendingPayment: '待付款',
    paid: '已付款',
    processing: '製作中',
    complete: '已完成',
    cancelled: '已取消',
  }
  const getOrderStatusColor = (status: string) => {
    if ([ORDER_STATUSES.cancelled].includes(status)) return 'medium';
    if ([ORDER_STATUSES.pendingCheckout, ORDER_STATUSES.pendingPayment].includes(status)) return 'danger';
    if ([ORDER_STATUSES.paid, ORDER_STATUSES.processing].includes(status)) return 'tertiary';
    if (status == ORDER_STATUSES.complete) return 'success';
    return 'light';
  }
  const syncFilterBtnPosition = (segmentBtns: any, filterVal: any) => {
    if (segmentBtns) {
      for (let i = 0; i < segmentBtns.length; i++) {
        const el: any = segmentBtns[i];
        if (el.value == filterVal) {
          setTimeout(() => {
            el.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            });
          }, 50);
          break;
        }
      }
    }
  }
  const sleep = (s: any) => {
    return new Promise((resolve) => {
      setTimeout(resolve, s * 1000);
    });
  };
  const scrollToRefElement = (refEl: any, behavior = 'smooth') => {
    refEl.scrollIntoView({
      behavior,
      block: 'start',
      inline: 'nearest'
    });
  }
  const openInAppBrowser = (url: any) => {
    if (url) {
      const options: any = isPlatform('ios') ? {
        location: 'no',
        toolbarposition: 'top',
        hidenavigationbuttons: 'yes',
        closebuttoncaption: t('backToMLP')
        //presentationstyle: 'formsheet',
      } : {
        //hardwareback: 'no',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarcolor: config.primaryColor,
        closebuttoncaption: t('backToMLP'),
        lefttoright: 'yes',
      }
      InAppBrowser.create(url, '_blank', options);
    }
  }
  const presentToast = async (msg: string, duration = 3000, position: any = 'bottom', buttons = []) => {
    const toast = await toastController.create({
      message: msg,
      duration,
      position,
      buttons,
    });
    toast.present();
  }
  const blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  const arraymove = (arr: any, fromIndex: any, toIndex: any) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  const getProxyImgLink = (imgLink: any) => (imgLink && imgLink.startsWith("http") ? `https://cms.signals.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink);
  const addResizeUrlParams = (photoLink: any, imageWidth = 300, returnProxyImgLink = true) => {
    if (photoLink && photoLink.includes("http")) {
      try {
        const url = new URL(photoLink);
        url.searchParams.append('resizeImage', 'true');
        url.searchParams.append('imageWidth', imageWidth.toString());
        return returnProxyImgLink ? getProxyImgLink(url.toString()) : url.toString();
      } catch (e) {
        console.error(e);
        return photoLink;
      }
    }
    return photoLink;
  }
  const loadHTMLImg = (imgLink: any, callback: any) => {
    const IMG_RETRY_MS = 3000; // retry downloading images every X ms on failure
    const img = new window.Image();
    img.onload = callback(img);
    img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(imgLink), IMG_RETRY_MS); }
    img.crossOrigin = 'Anonymous';
    img.src = getProxyImgLink(imgLink);
  }
  const getHTMLImg = async (imgLink: any, resizeImgWidth = null) => {
    imgLink = resizeImgWidth ? addResizeUrlParams(imgLink, resizeImgWidth) : getProxyImgLink(imgLink);
    return new Promise((resolve: any) => {
      const IMG_RETRY_MS = 3000; // retry downloading images every X ms on failure
      const img = new window.Image();
      img.onload = resolve(img);
      img.onerror = () => { setTimeout(() => img.src = imgLink, IMG_RETRY_MS); }
      img.crossOrigin = 'Anonymous';
      img.src = imgLink;
    })
  }
  const getCorner = (pivotX, pivotY, diffX, diffY, angle) => {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    /// find angle from pivot to corner
    angle += Math.atan2(diffY, diffX);

    /// get new x and y and round it off to integer
    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return { x: x, y: y };
  }
  const getClientRect = (rotatedBox) => {
    const { x, y, width, height } = rotatedBox;
    const rad = rotatedBox.rotation;

    const p1 = getCorner(x, y, 0, 0, rad);
    const p2 = getCorner(x, y, width, 0, rad);
    const p3 = getCorner(x, y, width, height, rad);
    const p4 = getCorner(x, y, 0, height, rad);

    const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
    const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
    const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
    const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  const openModal = async (component: any, componentProps: any, backdropDismiss = true, cssClass = '', sheetModal = false,
                            initialBreakpoint = 1, showHandle = false, onDidDismissCallback = null) => {
    const modal = await modalController.create({
      ...(sheetModal ? {
        breakpoints: [...new Set([0, initialBreakpoint, 1])],
        initialBreakpoint,
        handle: showHandle,
      } : {}),
      cssClass,
      backdropDismiss,
      component,
      componentProps,
    });
    if (onDidDismissCallback) modal.onDidDismiss().then(onDidDismissCallback);
    await modal.present();
    return modal;
  }

  const toCamel = (str) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };
  const convertKeysToCamelCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = convertKeysToCamelCase(value)
          }
          return value
      })
    } else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          if (origKey === "_RowNumber") { // skip AppSheet row number property
            newO[origKey] = o[origKey];
          } else {
            newKey = toCamel(origKey);
            value = o[origKey]
            if (value instanceof Array || (value && value.constructor === Object)) {
              value = convertKeysToCamelCase(value)
            }
            newO[newKey] = value
          }
        }
      }
    }
    return newO
  };

  return {
    addResizeUrlParams,

    openLoginModal: async () => (await openModal(LoginModal, {}, true, 'login-modal')),

    infiniteScrollLoadData: (ev: any, numOfVisibleItems: any, items: any) => {
      if (numOfVisibleItems.value > items.length) {
        ev.target.complete();
      } else {
        setTimeout(() => {
          numOfVisibleItems.value += 20;
          ev.target.complete();
        }, 500);
      }
    },

    /**
     * Order
     */
    PRODUCT_STATUSES,
    ORDER_STATUSES,
    getOrderStatusColor,

    /**
     * Auctions
     */
    convertKeysToCamelCase,
    getTimeDiffText: (endTime: any, currentTime: any) => {
      const timeLeft = moment.duration(moment(endTime).diff(currentTime));
      const days = Math.floor(timeLeft.asDays());
      const hours = Math.floor(timeLeft.asHours() % 24);
      const minutes = Math.floor(timeLeft.asMinutes() % 60);
      const seconds = Math.floor(timeLeft.asSeconds() % 60);

      if (days == 0) return `${hours}h ${minutes}m ${seconds}s`;
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    },

    /**
     * Canvas Helpers
     */
    isNumber: (n: any) => (!isNaN(+n)),
    getCorner,
    getClientRect,
    arraymove,
    getProxyImgLink,
    loadHTMLImg, getHTMLImg,
    blobToBase64,
    getBase64FromUrl: async (url, noCache  = false) => {
      const imgUrl = noCache ? `${url}&ts=${new Date().valueOf()}` : url;
      //const options: any = noCache ? { cache: "no-store" } : {};
      const data = await fetch(getProxyImgLink(imgUrl));
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
          const base64data = reader.result;   
          resolve(base64data);
        }
      });
    },
    getStageDataURL: (stageRef: any, mimeType = "image/png", pixelRatio = 1, extraOptions = {}) => {
      return stageRef ? stageRef.getStage().toDataURL({
        mimeType,
        quality: 0.6,
        pixelRatio,
        x: 0,
        ...extraOptions,
      }) : "";
    },
    shareImage: async (imgLink: any) => {
      const options = {
        subject: '',
        files: [imgLink], // an array of filenames either locally or remotely
        //url: 'https://mlol.pet/go',
      };
      await SocialSharing.shareWithOptions(options);
    },
    saveImageToDevice: async (imgLink: any, fileName: any = "") => {
      if (isPlatform('hybrid')) {
        const loading = await loadingController.create({});
        await loading.present();
        if (isPlatform('ios')) {
          await SocialSharing.saveToPhotoAlbum([imgLink]);
        } else {
          fileName = fileName || `${new Date().getTime()}.jpeg`;
          const res = await Filesystem.writeFile({
            path: fileName,
            data: imgLink,
            directory: Directory.Documents
          });
          console.log(res.uri);
        }
        presentToast(t('saveSuccess'));
        loading.dismiss();
      }
      else {
        const a = document.createElement("a"); // Create <a>
        a.href = imgLink;
        a.download = "Image.png"; // File name Here
        a.click(); // Downloaded file
      }
    },
    readFileFromDevice: async (path: any, directory = Directory.Data) => {
      return await Filesystem.readFile({ path, directory });
    },
    writeFileToDevice: async (path: any, data: any, directory = Directory.External) => {
      return await Filesystem.writeFile({ path, data, directory });
    },

    /**
     * Other Helpers
     */
    findReplaceVariables: (str: any, data: any) => {
      const variables: any = [...new Set(str.match(/\{\{([\S\s]*?)\}\}/g))];
      for (const key of variables) {
        const varName = key.replace(/[{}]/g, "");
        str = str.replace(new RegExp(key, "g"), data[varName] || "");
      }
      return str;
    },
    shuffleArray: (array: any) => {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    getPetAge: (dateOfBirth: any) => {
      const d1 = moment(dateOfBirth);
      const currDate = moment();
      const years = currDate.diff(d1, 'years');
      const months = currDate.diff(d1, 'months');
      return `${years}歲${months % 12}個月大`;
    },

    sleep,
    INFO_TYPES,
    syncFilterBtnPosition,
    scrollToRefElement,

    getLocalizedStr: (dataObj: any, keyChi: any, keyEn: any) => {
      return dataObj ? (locale.value == 'zh' ? dataObj[keyChi] : (dataObj[keyEn] || dataObj[keyChi])) : "";
    },
    getMediaFileName: (name: any) => (isPlatform('ios') ? `${name}.m4a` : `${name}.mp3`),
    uniqueId: () => Math.random().toString(36).slice(-8),
    getTypeColor: (type: string) => {
      if ([INFO_TYPES.clinic].includes(type)) return 'tertiary';
      if ([INFO_TYPES.emergency].includes(type)) return 'danger';
      if ([INFO_TYPES.garden, INFO_TYPES.adopt, INFO_TYPES.paradise].includes(type)) return 'success';
      if ([INFO_TYPES.restaurant].includes(type)) return 'secondary';
      if ([INFO_TYPES.grooming].includes(type)) return 'primary';
      if ([INFO_TYPES.shop].includes(type)) return 'warning';
      return 'light';
    },
    isNativeApp: () => (Capacitor.isNativePlatform()),
    isAndroid: () => (isPlatform('android')),
    isMobileWebApp: () => (isPlatform('mobileweb') && (isPlatform('ios') || isPlatform('android'))),
    iPhoneNativeApp: () => (isPlatform('ios') && !isPlatform('mobileweb')),
    openModal,
    openImageModal: async (imageLink: any, caption: any) => (await openModal(ImageModal, { imageLink, caption })),
    closeModal: async (data: any = {}) => {
      await modalController.dismiss(data);
    },
    openSocialShare: async (sharingMsg: any, sharingUrl: any = null) => {
      try {
        await Share.share({
          title: sharingMsg,
          url: sharingUrl || `https://mlol.pet${window.location.pathname}`,
          dialogTitle: t('share'),
        });
      } catch (e) {
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharingMsg)}`
        //const url = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
        window.open(url, '', 'width=600,height=400');
      }
    },
    openInAppBrowser: async (url: any) => {
      openInAppBrowser(url);
    },
    openBrowser: async (url: any, infoId: any = null) => {
      if (infoId) store.dispatch('addUserBrowsedInfo', { id: infoId });
      if (isPlatform('ios')) {
        await Browser.open({ url, toolbarColor: config.primaryColor, presentationStyle: 'popover' });
      } else {
        openInAppBrowser(url);
      }
    },
    focusInputField: (refEl: any) => {
      refEl.$el.setFocus();
    },
    numberWithCommas: (x: any) => {
      return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    },
    getRelativeDate: (date: any) => {
      moment.locale(locale.value == 'en' ? 'en' : 'zh-HK');
      return moment(new Date(date)).fromNow();
    },
    formatDateTime: (date: any) => moment(new Date(date)).format('YYYY/M/D HH:mm:ss'),
    formatDate: (date: any) => moment(new Date(date)).format('YYYY/MM/DD HH:mm'),
    formatDateString: (d: any, format = 'YYYY/MM/DD') => (d ? moment(new Date(d)).format(format) : ""),
    formatDuration: (duration: any, format = 'm:ss') => {
      return moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format(format);
    },
    reloadApp: () => {
      SplashScreen.show();
      window.location.reload();
    },
    presentConfirm: async (message = "Confirm?") => {
      return new Promise((resolve) => {
        alertController.create({
          message,
          buttons: [
            {
              text: t('discard'),
              cssClass: 'secondary',
              handler: () => {
                resolve(false);
              }
            },
            {
              text: t('save'),
              handler: () => {
                resolve(true);
              },
            }
          ]
        }).then(alert => {
          alert.present();
        });
      });
    },
    presentPrompt: async (header: any = "", message: any = "", callback: any, cssClass = "") => {
      const alert = await alertController.create({
        header,
        message,
        cssClass,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    },
    distanceBetweenCoordinates: (lat1: any, lon1: any, lat2: any, lon2: any) => {
      const p = 0.017453292519943295;    // Math.PI / 180
      const c = Math.cos;
      const a = 0.5 - c((lat2 - lat1) * p)/2 + 
              c(lat1 * p) * c(lat2 * p) * 
              (1 - c((lon2 - lon1) * p))/2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },
    presentAlert: async (msg: string, hideHeader = false, callback = null, header = '') => {
      const obj: any = {
        header: header || t('errorHeader'),
        message: msg,
        buttons: [{
          text: 'OK',
          handler: () => {
            if (callback) callback();
            else {
              return true;
            }
          },
        }],
      }
      if (hideHeader) delete obj.header;
      const alert = await alertController.create(obj);
      return await alert.present();
    },
    presentToast,
    presentVerifyCodeInputAlert: async (phone: any, callback: any) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
              maxlength: '6',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                callback(value.verificationCode);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
  }
}