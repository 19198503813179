
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';

// icons
import { image, eye } from 'ionicons/icons';

// components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { IonicSlides, IonList, IonListHeader, IonImg, IonText, IonIcon, modalController, } from '@ionic/vue';

import ImageModal from '@/components/modals/ImageModal.vue';
import { utils } from '@/composables/utils';

export default {
  props: [
    "defaultCaption",
    "listHeader",
    "imageLinks",
    "photos",
    "minSlidesPerView",
    "size",
    "hideEyeIcon",
  ],
  components: { Swiper, SwiperSlide, IonList, IonListHeader, IonImg, IonText, IonIcon, },
  setup(props: any) {
    const { addResizeUrlParams, openImageModal, } = utils();
    const minSlidesPerView = props.minSlidesPerView || 1;
    const ops = {
      slidesPerView: Math.max(minSlidesPerView, 1),
      breakpoints: {
        768: { slidesPerView: Math.max(minSlidesPerView, 3), },
        1024: { slidesPerView: Math.max(minSlidesPerView, 4), }
      },
    };
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      image, eye,

      // variables
      ops,

      // methods
      addResizeUrlParams, openImageModal,

      // swiper modules
      modules: [IonicSlides],
    }
  }
}
