
// icons
import { notifications, pin, star, heart, heartOutline, shareSocialOutline,
        notificationsOutline, ribbonOutline, hammerOutline, } from 'ionicons/icons';

// components
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonRow, IonCol,
        IonItem, IonLabel, IonButtons, IonButton, IonIcon, IonBadge, IonImg,
        IonChip, IonAvatar,
        loadingController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: [
    "comment",
  ],
  components: { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonRow, IonCol,
                IonItem, IonLabel, IonButtons, IonButton, IonIcon, IonBadge, IonImg,
                IonChip, IonAvatar, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { getRelativeDate } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, pin, star, heart, heartOutline, shareSocialOutline,
      notificationsOutline, ribbonOutline, hammerOutline,

      // methods
      t, getRelativeDate,
    }
  }
}
